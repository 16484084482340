import { Component, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CheckExistsDocumentNameResponse } from '@ptg-employer/models/employer-document.model';
import { OverviewHeaderComponent } from '@ptg-member/components/overview-header/overview-header.component';
import { MenuItemSubTitle } from '@ptg-member/constants';
import {
  PreviewPdfFileDialogComponent,
  PreviewPdfFileDialogData,
} from '@ptg-member/features/calculation/components/preview-pdf-file-dialog/preview-pdf-file-dialog.component';
import { RetirementBenefitDialogComponent } from '@ptg-member/features/calculation/components/retirement-benefit-dialog/retirement-benefit-dialog.component';
import { RetirementBenefitDataInput } from '@ptg-member/features/calculation/services/models/retirement-benefit.model';
import * as fromReducer from '@ptg-reducers';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { ACTION, BUTTON_LABEL_CLOSE, BUTTON_LABEL_NO, DEFAULT_PAGE_SIZE, SortType, STATE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { DOCUMENT_LOCATION, USED_FOR_MENU } from '@ptg-shared/constance/document-location.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ButtonLabelType, IconFontType } from '@ptg-shared/controls/card-description/types/enums';
import {
  Action,
  BodyContent,
  CardDescriptionData,
  HyperlinkParams,
} from '@ptg-shared/controls/card-description/types/models';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { Column } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { Option } from '@ptg-shared/controls/select/select.component';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { DisplayedTabName, STRING_QUERY_PARAM } from '@ptg-shared/layout/constance/layout.const';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { FundType } from '@ptg-shared/types/enums';
import { Breadcrumb, StepperState } from '@ptg-shared/types/models/breadcrumb.model';
import { deepClone, downloadFile, showBanner, toTimeZoneLocal } from '@ptg-shared/utils/common.util';
import { capitalizeFirstLetter, getDateString, isEmpty } from '@ptg-shared/utils/string.util';
import { DateTime } from 'luxon';
import { combineLatest, Observable, of, Subject, timer } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { EditDocumentComponent } from 'src/app/admin/features/file/components/edit-document/edit-document.component';
import { DocumentsState } from 'src/app/admin/features/file/store/reducers';
import { EntityType } from 'src/app/admin/features/file/types/enums/entity-type.enum';
import {
  GRID_COLUMN_CALCULATION_AUDIT_TRAILS,
  GRID_COLUMN_DETAIL_BENEFIT_DOCUMENT,
  GRID_COLUMN_EXCEPTION_LIST,
} from '../../benefit-detail.constants';
import { EditCalculationParameterComponent } from '../../components';
import { RetirementBenefitDialogComponentService } from '../../components/retirement-benefit-dialog/retirement-benefit-dialog.component.service';

import { BenefitDetailComponentService } from '../../services';
import {
  CalculationAuditTrail,
  CalculationBenefitDetails,
  CalculationBenefitWarnings,
  CalculationBenefitWarningsParams,
  CheckExceptionRequest,
  CreateGenerateCalculationWorksheetResponse,
  CreateRetirementBenefitDetailUploadDocumentRequest,
  DetailItemInfo,
  ExceptionListItem,
  GetCalculationAuditTrailRequest,
  GetCalculationBenefitDetailsRequest,
  GetCalculationBenefitDetailsResponse,
  GetExceptionRequest,
  GetRetirementBenefitDetailDocumentsRequest,
  RetirementBenefitDetailDocument,
  RetirementBenefitDocument,
} from '../../services/models';

import { CalculationBenefitDetailType } from '../../services/models/retirement-benefit-detail.model';
import { RetirementBenefitDialogService } from '../../services/retirement-benefit-dialog.service';
import {
  approveCalculationBenefitAction,
  approveCalculationBenefitSelector,
  CalculationState,
  checkExceptionConfigurationAction,
  checkExceptionSelector,
  checkExistLoddDocumentCanEditSelector,
  checkExistLoddDocumentCanRemoveSelector,
  checkLoddDocumentCanEditAction,
  checkLoddDocumentCanRemoveAction,
  clearApproveCalculationBenefitStateAction,
  clearCheckExceptionConfigurationStateAction,
  clearCheckLoddDocumentCanEditStateAction,
  clearCheckLoddDocumentCanRemoveStateAction,
  clearCompleteCalculationBenefitStateAction,
  clearComputeCalculationBenefitStateAction,
  clearCreateGenerateCalculationWorksheetStateAction,
  clearCreateRetirementBenefitDetailUploadDocumentStateAction,
  clearEditRetirementBenefitDocumentStateAction,
  clearGetCalculationAuditTrailStateAction,
  clearGetCalculationBenefitDetailsStateAction,
  clearGetExceptionListStateAction,
  clearGetRetirementBenefitDetailDocumentsStateAction,
  clearGetRetirementBenefitDownloadDocumentStateAction,
  clearRemoveRemoveCalculationDocumentDetailStateAction,
  clearReopenCalculationBenefitStateAction,
  clearSetBenefitDetailStateAction,
  clearSetCalculationParameterStateAction,
  clearValidateBeforeCalculationBenefitStateAction,
  clearValidateCalculationBenefitAction,
  closeBenefitWarningMessageAction,
  completeCalculationBenefitAction,
  completeCalculationBenefitSelector,
  computeCalculationBenefitAction,
  computeCalculationBenefitSelector,
  createGenerateCalculationWorksheetAction,
  createGenerateCalculationWorksheetSelector,
  createRetirementBenefitDetailUploadDocumentAction,
  createRetirementBenefitDetailUploadDocumentSelector,
  editRetirementBenefitDocumentAction,
  getCalculationAuditTrailAction,
  getCalculationAuditTrailSelector,
  getCalculationBenefitDetailsAction,
  getCalculationBenefitDetailsSelector,
  getCalculationQDROSelector,
  getExceptionListAction,
  getExceptionListSelector,
  getRetirementBenefitDetailDocumentsAction,
  getRetirementBenefitDetailDocumentsSelector,
  getRetirementBenefitDocumentsAction,
  getRetirementBenefitDocumentSelector,
  getRetirementBenefitDownloadDocumentSelector,
  getSetBenefitDetailSelector,
  removeRemoveCalculationDocumentDetailAction,
  removeRemoveCalculationDocumentDetailSelector,
  reopenCalculationBenefitAction,
  reopenCalculationBenefitSelector,
  setCalculationParameterSelector,
  validateBeforeCalculationBenefitAction,
  validateBeforeComputeJoinSurvivorSelector,
  validateCalculationBenefitAction,
  validateCalculationBenefitSelector,
} from '../../store';
import {
  ActionButtonOnCalculationDetail,
  CalculationBenefitHistoryStatus,
  CalculationType,
  CheckComputeDataChangeErrorType,
  DisplayDocumentTypeName,
  RetirementBenefitDetailGridDataType,
} from '../../types/enums';
import * as fromMember from '../../../../store/reducers';
import {
  clearGetDocumentDownloadStateAction,
  getDocumentDownloadAction,
} from 'src/app/admin/features/file/store/actions';
import { editRetirementBenefitDocumentsSelector } from '../../store/selectors/retirement-benefit-upload-document.selector';
import { BenefitType, UPLOAD_DOCUMENT_RADIO_LIST } from '../../constants';
import { RetirementBenefitDetailComponentService } from './retirement-benefit-detail.component.service';
import {
  clearGetParticipantDocumentsBenefitsStateAction,
  getParticipantDocumentsBenefitsAction,
} from '@ptg-member/store/actions/participant-documents.actions';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';
const PAGE_SIZE_CONST = '-ptg-benefit-detail-pageSize';
@Component({
  selector: 'ptg-retirement-benefit-detail',
  templateUrl: './retirement-benefit-detail.component.html',
  styleUrls: ['./retirement-benefit-detail.component.scss'],
  providers: [RetirementBenefitDetailComponentService],
})
export class RetirementBenefitDetailComponent extends BaseListComponent {
  readonly CalculationBenefitHistoryStatus = CalculationBenefitHistoryStatus;
  readonly CalculationBenefitDetailType = CalculationBenefitDetailType;
  @ViewChild(OverviewHeaderComponent) overViewHeader!: OverviewHeaderComponent;

  isLoading = true;
  memberId = '';
  private title = '';
  settings: Breadcrumb[] | undefined = [];
  listBreadcrumbs: Breadcrumb[] = [];

  dataEdit: any;
  documentId: string = ';';
  private downloadFileName = '';

  stepperState!: StepperState;
  completedStep = 0;

  benefitDetails: CardDescriptionData[] = [];

  message = '';
  bannerType: BannerType = BannerType.Hidden;
  bannerTypeCP: BannerType = BannerType.Warning;
  warningList: string[] = [];
  titleBanner = 'Warning';
  isShowWarning: boolean = false;

  currentStatus?: CalculationBenefitHistoryStatus;
  calculationBenefitId = '';
  private calculationBenefitDetails: DetailItemInfo[] = [];
  private benefitEntityId = '';
  private calculationBenefitDocumentDetailId: string = '';

  private calculationTypeBreadcrumb: CalculationType = CalculationType.RetirementBenefit;
  calculationType: CalculationType = CalculationType.RetirementBenefit;

  isRetirement = false;
  isLODD = false;
  isLODDSurvivor = false;
  isLODDDeath = false;
  isQDRO = false;

  defaultPageSize: number = DEFAULT_PAGE_SIZE;
  currentFund: any = {};

  isAddDocumentOpen = false;
  isShowDocumentList = false;
  totalBenefitDocuments = 0;
  isDocumentLoading = true;
  pageDocumentIndex = FIRST_PAGE;
  pageDocumentSize = DEFAULT_PAGE_SIZE;
  retirementBenefitDocuments: RetirementBenefitDetailDocument[] = [];
  columnsBenefitDocuments: Column[] = GRID_COLUMN_DETAIL_BENEFIT_DOCUMENT;
  private sortInfoDocument: Sort = { active: '', direction: 'asc' };

  totalExceptionRecords = 0;
  isExceptionLoading = true;
  pageExceptionIndex = FIRST_PAGE;
  pageExceptionSize = 3;
  pageExceptionSizeOptions = [1, 3, 5, 10, 15, 20];
  exceptionList: ExceptionListItem[] = [];
  columnException: Column[] = GRID_COLUMN_EXCEPTION_LIST;
  private sortInfoException: Sort = { active: 'exceptionName', direction: 'asc' };

  totalCalculationAuditTrailRecords = 0;
  isCalculationAuditTrailLoading = true;
  pageCalculationAuditTrailIndex = FIRST_PAGE;
  pageCalculationAuditTrailSize = DEFAULT_PAGE_SIZE;
  calculationAuditTrails: CalculationAuditTrail[] = [];
  columnCalculationAuditTrail: Column[] = GRID_COLUMN_CALCULATION_AUDIT_TRAILS;
  private sortInfoCalculationAuditTrail: Sort = { active: 'createdDate', direction: 'desc' };

  isShowQDROBenefitInfoSection = true;

  private passedExceptionTrigger = new Subject<boolean>();

  maxWidth: string = this.getMaxWidth(2);

  documentTypeOfLODD: Option[] = [
    {
      value: DisplayDocumentTypeName['Death Certificate'],
      displayValue: 'Death Certificate',
    },
    {
      value: DisplayDocumentTypeName['Other'],
      displayValue: 'Other',
    },
  ];

  menuItemTitle: string = '';
  readonly menuItemSubTitle: string = MenuItemSubTitle.BenefitsProcessing;
  getCalculationBenefitDetailsResponse?: GetCalculationBenefitDetailsResponse;

  readonly existDocumentOptionList: RadioOption[] = UPLOAD_DOCUMENT_RADIO_LIST;

  benefitDocuments: RetirementBenefitDocument[] = [];

  reloadQDROBenefitInfoTrigger = new Subject<boolean>();

  isSurvivorOrJointSurvivorRecalculate: boolean | null = null;

  get isChicagoParks(): boolean {
    return this.layoutService.currentFund$.value.fundType === FundType.ChicagoParks;
  }
  content?: Partial<RetirementBenefitDataInput>;

  // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
  qdroLabelName: string = '';

  constructor(
    private readonly dialog: MatDialog,
    public readonly route: ActivatedRoute,
    public readonly layoutService: LayoutService,
    private readonly calculationStore: Store<CalculationState>,
    private documentStore: Store<DocumentsState>,
    private readonly retirementBenefitDetailService: BenefitDetailComponentService,
    private retirementBenefitDialogService: RetirementBenefitDialogService,
    private retirementBenefitDialogComponentService: RetirementBenefitDialogComponentService,
    private retirementBenefitDetailComponentService: RetirementBenefitDetailComponentService,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    this.clearStates();
    this.initDataByRouteParams();

    // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
    this.selectCalculationQDRO();

    this.getCurrentFundAndRouteData();

    this.selectExceptionListState();
    this.selectDocumentListState();

    this.selectCalculationAuditTrailState();
    this.getCalculationAuditTrailData();

    this.getCalculationBenefitDetailData();
    this.selectCalculationBenefitDetailState();

    this.selectCheckExceptionState();
    this.selectValidateCalculationBenefitState();

    this.selectClickReOpenState();
    this.selectClickComputeState();
    this.selectClickCompleteState();
    this.selectClickApproveState();

    this.selectParticipantDocumentsBenefitsState();

    this.selectorCheckLoddDocumentCanRemove();
    this.selectorCheckLoddDocumentCanEdit();

    this.selectDownloadDocumentState();
    this.selectRemoveDocumentState();

    // Listener for Edit status of Benefit Options section
    this.selectSetBenefitDetailState();
    // Listener for Edit status of Calculation Parameters section
    this.selectSetCalculationParameterState();

    this.uploadDocumentSelector();
    this.registerEditDocumentSelector();

    this.getRetirementBenefitDocumentData();
    this.selectBenefitDocument();

    this.selectCreateGenerateCalculationWorksheetState();

    this.selectValidateBeforeComputeJoinSurvivorState();
  }

  getCurrentFundAndRouteData() {
    combineLatest([this.route.params, this.store.select(fromReducer.selectCurrentFundState)])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([params, currentFund]) => {
        this.defaultPageSize = currentFund?.defaultPageSize ?? DEFAULT_PAGE_SIZE;
        this.currentFund = currentFund;
        this.setPageSize();
      });
  }

  setPageSize() {
    const auditTrailPageSizeInSession = Number(
      sessionStorage.getItem(
        this.currentFund.key + this.calculationType + BenefitType.BenefitAuditTrail + PAGE_SIZE_CONST,
      ),
    );

    this.pageCalculationAuditTrailSize =
      auditTrailPageSizeInSession === 0 ? this.defaultPageSize : auditTrailPageSizeInSession;
  }

  private selectSetBenefitDetailState(): void {
    this.calculationStore
      .select(getSetBenefitDetailSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((setBenefitDetailState) => {
        if (setBenefitDetailState?.success) {
          showBanner.call(this, STATE.SUCCESS, setBenefitDetailState.payload?.editItem ?? '', ACTION.EDIT);
          this.calculationStore.dispatch(clearSetBenefitDetailStateAction());
        }
      });
  }

  private selectSetCalculationParameterState(): void {
    this.calculationStore
      .select(setCalculationParameterSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearSetCalculationParameterStateAction());
        const sectionLabel = this.benefitDetails.find(
          (item) => item.sectionType === CalculationBenefitDetailType.CalculationParameter,
        )?.title;
        showBanner.call(this, response?.success ? STATE.SUCCESS : STATE.FAIL, sectionLabel ?? '', ACTION.EDIT);
      });
  }

  private initStepperState(dataDetail: any) {
    if (!dataDetail.length) return;
    const optional: string[] = [];
    let cloneDataDetail = deepClone(dataDetail);
    cloneDataDetail = cloneDataDetail.sort((a: any, b: any) => a.step - b.step);
    cloneDataDetail.forEach((item: any) => {
      const completeTimeToLocal = item.submittedDate ? toTimeZoneLocal(new Date(item.submittedDate)).toISOString() : '';
      const completeTime = DateTime.fromISO(completeTimeToLocal).toFormat('MM/dd/yyyy hh:mm a');
      const label = item.submittedBy ? `${item.submittedBy} - ${completeTime}` : '';
      optional.push(label);
    });

    let countStep = 0;
    dataDetail.forEach((itemAudit: any) => {
      if (itemAudit.submittedDate !== null) {
        countStep++;
      }
    });
    this.stepperState = {
      selectedIndex: dataDetail.length - 1 || 0,
      currentStep: countStep || 0,
      labels: ['Calculate', 'Approve'],
      optionalLabels: optional,
    };
    this.completedStep = dataDetail.length;
  }

  private initDataByRouteParams(): void {
    const params = this.route.snapshot.params;

    if (params?.memberId && params?.retirementBenefitId && params?.calculationType) {
      const { memberId, retirementBenefitId, calculationType } = params;
      this.memberId = memberId;
      this.calculationBenefitId = retirementBenefitId;

      this.calculationType = +calculationType as CalculationType;

      this.isRetirement = this.calculationType === CalculationType.RetirementBenefit;
      this.isLODD = [CalculationType.LODDBenefit, CalculationType.LODDDeath, CalculationType.LODDSurvivor].includes(
        this.calculationType,
      );
      this.isLODDSurvivor = this.calculationType === CalculationType.LODDSurvivor;
      this.isLODDDeath = this.calculationType === CalculationType.LODDDeath;
      this.isQDRO = this.calculationType === CalculationType.QDRO;

      const { calculationTypeForBreadcrumb, menuItemTitle } =
        this.retirementBenefitDetailComponentService.getBreadcrumbAndMenuTitleByCalculationType(this.calculationType);
      this.calculationTypeBreadcrumb = calculationTypeForBreadcrumb;
      this.menuItemTitle = menuItemTitle;

      this.title = this.retirementBenefitDetailComponentService.getTitleByCalculationType(this.calculationType);
      this.isShowDocumentList = this.retirementBenefitDetailComponentService.getDocumentListDisplayStatus(
        this.calculationType,
      );

      this.listBreadcrumbs = this.getBreadcrumbs;
      this.settings = this.retirementBenefitDetailComponentService.getGearMenuSettings(
        this.memberId,
        this.calculationType,
      );
    }
  }

  private get getBreadcrumbs(): Breadcrumb[] {
    return [
      {
        name: 'Overview',
        url: `/member/benefit-overview/${this.calculationTypeBreadcrumb}/${this.memberId}`,
      },
      {
        name: `${this.title}`,
      },
    ];
  }

  onClickReopen(): void {
    this.calculationStore.dispatch(
      reopenCalculationBenefitAction({
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
        calculationType: this.calculationType,
      }),
    );
  }

  private selectClickReOpenState(): void {
    this.calculationStore
      .select(reopenCalculationBenefitSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        const isSuccess = res?.success;
        const customMessage = isSuccess
          ? 'Calculation successfully re-opened.'
          : 'Error occurred re-opening Calculation. Please try again.';
        showBanner.call(this, isSuccess ? STATE.SUCCESS : STATE.FAIL, 'Calculation', ACTION.REOPEN, { customMessage });

        if (isSuccess) {
          this.getCalculationBenefitDetailData();
          this.getCalculationAuditTrailData();
        }

        this.calculationStore.dispatch(clearReopenCalculationBenefitStateAction());
      });
  }

  private getCheckExceptionData(buttonAction: ActionButtonOnCalculationDetail): void {
    const request: CheckExceptionRequest = {
      memberId: this.memberId,
      benefitEntityId: this.benefitEntityId ?? '',
      targetId: this.calculationBenefitId,
      targetType: this.calculationType,
    };
    this.calculationStore.dispatch(checkExceptionConfigurationAction({ request, buttonAction }));
  }

  private selectCheckExceptionState(): void {
    this.calculationStore
      .pipe(
        select(checkExceptionSelector),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        const isSuccess = response?.success;
        this.calculationStore.dispatch(clearCheckExceptionConfigurationStateAction());
        if (!isSuccess) {
          showBanner.call(this, STATE.FAIL, '', '', {
            customMessage: 'Error occurred checking Exceptions. Please try again.',
          });
        }
        // No exception OR processing Computation
        if (
          isSuccess &&
          (response?.payload?.buttonAction ===
            (ActionButtonOnCalculationDetail[0] as unknown as ActionButtonOnCalculationDetail) ||
            response?.payload?.exceptionsOccur === false)
        ) {
          this.passedExceptionTrigger.next(response?.payload?.exceptionsOccur);
        }
        // Exceptions occur
        else if (isSuccess && response?.payload?.exceptionsOccur) {
          const confirmResult = this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              title: BannerType.Warning,
              text: 'Please resolve exception to proceed.',
              type: ConfirmType.Warning,
              cancelButtonTitle: BUTTON_LABEL_CLOSE,
              hideConfirmButton: true,
            },
          });
          confirmResult
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
              this.getCalculationBenefitDetailData();
              this.getCalculationAuditTrailData();
            });
        }
      });
  }

  onClickCompute(): void {
    this.validateBeforeCalculationBenefit(() => this.onComputeProcessing());
  }

  private onComputeProcessing() {
    this.calculationStore.dispatch(
      computeCalculationBenefitAction({
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
        calculationType: this.calculationType,
      }),
    );

    this.passedExceptionTrigger
      .pipe(
        filter((exceptionsOccur) => typeof exceptionsOccur === 'boolean'),
        take(1),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.getCalculationBenefitDetailData();
        this.getCalculationAuditTrailData();
      });
  }

  private selectValidateBeforeComputeJoinSurvivorState(): void {
    this.calculationStore
      .select(validateBeforeComputeJoinSurvivorSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearValidateBeforeCalculationBenefitStateAction());

        if (!res?.payload) {
          return;
        }

        // Invalid to process computation of Joint Survivor benefit record
        if (res.payload.isValid === false) {
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            disableClose: true,
            autoFocus: false,
            data: {
              text: 'Retirement benefit is a lump-sum settlement. Cannot be associated with the Joint Survivor Benefit.',
              type: ConfirmType.Warning,
              title: 'Warning',
              cancelButtonTitle: BUTTON_LABEL_CLOSE,
              hideConfirmButton: true,
            },
            restoreFocus: false,
          });
          return;
        }

        // Process Compute action
        if (!this.content) {
          this.onComputeProcessing();
          return;
        }

        // Process Edit Detail sections
        switch (res.payload.sectionType) {
          case CalculationBenefitDetailType.RetirementOption:
            this.handleEditBenefitOptionSection(this.content);
            break;
          case CalculationBenefitDetailType.CalculationParameter:
            this.handleEditCalculationParametersSection(this.content);
            break;
          default:
            break;
        }
      });
  }

  private selectClickComputeState(): void {
    this.calculationStore
      .select(computeCalculationBenefitSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        const isSuccess = res?.success;
        const isSuccessComputed = res?.payload?.isSuccess;
        let customMessage =
          isSuccess && isSuccessComputed
            ? 'Calculation computed.'
            : 'Error occurred computing Benefit. Please try again.';
        this.calculationStore.dispatch(clearComputeCalculationBenefitStateAction());
        showBanner.call(this, isSuccess && isSuccessComputed ? STATE.SUCCESS : STATE.FAIL, '', '', { customMessage });

        if (isSuccess && isSuccessComputed) {
          this.getCheckExceptionData(
            ActionButtonOnCalculationDetail[
              ActionButtonOnCalculationDetail.Compute
            ] as unknown as ActionButtonOnCalculationDetail,
          );
        }
      });
  }

  private getCalculationBenefitDetailData(): void {
    const request: GetCalculationBenefitDetailsRequest = {
      memberId: this.memberId,
      calculationBenefitId: this.calculationBenefitId,
    };
    this.calculationStore.dispatch(getCalculationBenefitDetailsAction({ request }));
  }

  private selectCalculationBenefitDetailState(): void {
    this.calculationStore
      .select(getCalculationBenefitDetailsSelector)
      .pipe(
        tap((res) => (this.isLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearGetCalculationBenefitDetailsStateAction());

        if (res?.success) {
          this.getCalculationBenefitDetailsResponse = deepClone(res?.payload);
          this.calculationBenefitDetails = this.getCalculationBenefitDetailsResponse?.details ?? [];
          this.isSurvivorOrJointSurvivorRecalculate = this.getCalculationBenefitDetailsResponse
            ?.isSurvivorOrJointSurvivorRecalculate as boolean | null;
          this.benefitEntityId = this.calculationBenefitDetails[0]?.benefitEntityId ?? '';

          if (this.calculationType !== CalculationType.QDRO) {
            this.getDocumentListData();
          }

          this.reloadQDROBenefitInfoTrigger.next(true);
          this.getExceptionListData();
          this.handleDataDisplay(this.getCalculationBenefitDetailsResponse);
        }
      });
  }

  onClickComplete(): void {
    this.getValidateCalculationBenefitData(CalculationBenefitHistoryStatus['Pending Approval']);
  }

  private selectClickCompleteState(): void {
    this.calculationStore
      .select(completeCalculationBenefitSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        const isSuccess = res?.success;
        this.calculationStore.dispatch(clearCompleteCalculationBenefitStateAction());
        showBanner.call(this, isSuccess ? STATE.SUCCESS : STATE.FAIL, 'Benefit', ACTION.COMPLETE);

        if (isSuccess) {
          this.getCalculationBenefitDetailData();
          this.getCalculationAuditTrailData();
        }
      });
  }

  onClickApprove(): void {
    this.getValidateCalculationBenefitData(CalculationBenefitHistoryStatus.Approved);
  }

  private selectClickApproveState(): void {
    this.calculationStore
      .select(approveCalculationBenefitSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        const isSuccess = res?.success;
        this.calculationStore.dispatch(clearApproveCalculationBenefitStateAction());
        showBanner.call(this, isSuccess ? STATE.SUCCESS : STATE.FAIL, 'Benefit', ACTION.APPROVE);

        if (isSuccess) {
          this.getCalculationBenefitDetailData();
          this.getCalculationAuditTrailData();
        }
      });
  }

  private getPathCaseNumber(sectionType: CalculationBenefitDetailType, hyperlink?: HyperlinkParams) {
    if (
      sectionType === CalculationBenefitDetailType.QDROInformation &&
      hyperlink &&
      hyperlink.menuId &&
      hyperlink.viewId &&
      hyperlink.targetId
    ) {
      return `member/detail-view/true/${hyperlink?.menuId}/${hyperlink?.viewId}/${hyperlink?.targetId}`;
    }
    return null;
  }

  private handleDataDisplay(data?: GetCalculationBenefitDetailsResponse): void {
    this.currentStatus = data?.currentStatus;
    this.warningList = [];

    this.isShowWarning =
      this.layoutService.currentFund$.value.fundType === FundType.ChicagoParks &&
      !!data?.calculationBenefitWarnings &&
      data?.calculationBenefitWarnings?.length > 0;

    this.initStepperState(data?.auditTrailLines);
    this.getWarningData(data?.calculationBenefitWarnings);
    const dataDetails = deepClone(data?.details ?? []);
    this.benefitDetails = dataDetails.map((item) => {
      let isHasOverridden = false;
      let contentOverriddenData: BodyContent[] = [];

      let properties = item?.properties?.filter((property) => property?.label !== null);
      if (item.sectionType === CalculationBenefitDetailType.PayeeInformation) properties = item?.properties;
      if (item?.sectionType === CalculationBenefitDetailType.RetirementOption) {
        const retirementOptionPush = {
          type: EntityPropertyType.Text,
          label: this.isChicagoParks || this.isQDRO ? 'Benefit' : 'Benefit Option',
          value: item?.benefitEntityName ?? '',
          order: 0,
        } as CalculationBenefitDetails;

        properties = [retirementOptionPush, ...properties].sort((a, b) => a?.order - b?.order);
      }

      const isUsingOverridenValue =
        this.isChicagoParks && item?.sectionType === CalculationBenefitDetailType.CalculationParameter;
      const contentData = properties?.map((prop) => {
        return {
          benefitDetailKey: prop.benefitDetailKey,
          label: prop.label,
          option: prop.option,
          options: prop.options,
          order: prop.order,
          userOverridenValue: prop.userOverridenValue,
          config: prop.config,
          name: prop.label,
          type: this.getPropertyType(prop),
          value: isUsingOverridenValue
            ? this.getPropertyValue(prop, 'userOverridenValue')
            : this.getPropertyValue(prop),
          path: this.getPathCaseNumber(item.sectionType, prop.hyperlinkParams),
          hyperlinkParams: this.getHyperlinkParams(prop),
        };
      }) as BodyContent[];

      if (item?.sectionType === CalculationBenefitDetailType.CalculationParameter) {
        isHasOverridden =
          this.isChicagoParks || this.isQDRO
            ? false
            : item?.properties?.some((item) => item?.userOverridenValue !== '' && !isEmpty(item?.userOverridenValue));
        if (isHasOverridden) {
          contentOverriddenData = item?.properties?.map((prop) => {
            return {
              // ...prop,
              benefitDetailKey: prop.benefitDetailKey,
              label: prop.label,
              option: prop.option,
              options: prop.options,
              order: prop.order,
              userOverridenValue: prop.userOverridenValue,
              config: prop.config,
              name: prop.label,
              type: this.getPropertyType(prop),
              value: this.getPropertyValue(prop, 'userOverridenValue'),
            };
          });
        }
      }

      return {
        sectionType: item?.sectionType,
        title: item?.sectionLabel,
        body: {
          content: contentData,
          contentOverridden: contentOverriddenData,
          hasOverridden: isHasOverridden,
        },
        columnNumber: isHasOverridden ? 1 : 2,
        action: this.getAction(item),
        cardId: item?.cardId,
      };
    });
  }

  getWarningData(inputData?: CalculationBenefitWarnings[]): void {
    if (!inputData) {
      return;
    }
    inputData?.forEach((item) => {
      this.warningList.push(item?.message);
    });
  }

  private getDocumentListData(): void {
    const payload: Omit<GetRetirementBenefitDetailDocumentsRequest, 'sortNames' | 'sortType'> = {
      pageNumber: this.pageDocumentIndex,
      pageSize: this.pageDocumentSize,
      memberId: this.memberId,
      calculationType: this.calculationType,
      benefitTypeId: this.calculationBenefitId,
    };
    const request = this.retirementBenefitDetailService.getGridDataRequest(
      RetirementBenefitDetailGridDataType.RetirementBenefitDocuments,
      payload,
      this.sortInfoDocument,
    ) as GetRetirementBenefitDetailDocumentsRequest;
    this.calculationStore.dispatch(getRetirementBenefitDetailDocumentsAction({ request }));
  }

  private selectDocumentListState(): void {
    this.calculationStore
      .select(getRetirementBenefitDetailDocumentsSelector)
      .pipe(
        filter((res) => !!res),
        tap((res) => (this.isDocumentLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetRetirementBenefitDetailDocumentsStateAction());
        this.retirementBenefitDocuments = (response?.payload ?? []).map((item) => {
          return {
            ...item,
            fileName: item?.fileName ?? '',
            uploadDate: getDateString(item.uploadDate ?? ''),
          };
        });
        this.totalBenefitDocuments = response?.total ?? 0;
      });
  }

  private getExceptionListData(): void {
    const payload: Omit<GetExceptionRequest, 'sortNames' | 'sortType'> = {
      pageNumber: this.pageExceptionIndex,
      pageSize: this.pageExceptionSize,
      memberId: this.memberId,
      calculationType: this.calculationType,
      benefitTypeId: this.benefitEntityId ?? '',
      calculationId: this.calculationBenefitId ?? '',
    };
    const request = this.retirementBenefitDetailService.getGridDataRequest(
      RetirementBenefitDetailGridDataType.Exceptions,
      payload,
      this.sortInfoException,
    ) as GetExceptionRequest;
    this.calculationStore.dispatch(getExceptionListAction({ request }));
  }

  private selectExceptionListState(): void {
    this.calculationStore
      .select(getExceptionListSelector)
      .pipe(
        filter((res) => !!res),
        tap((res) => (this.isExceptionLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetExceptionListStateAction());
        this.exceptionList = (response?.payload ?? []).map((item) => {
          return {
            ...item,
            exceptionTime: DateTime.fromISO(getDateString(item.exceptionTime), { zone: 'utc' }).toJSDate().toString(),
            // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
            exceptionName: item.exceptionName.replace(CalculationType[CalculationType.QDRO], this.qdroLabelName)
          };
        });
        this.totalExceptionRecords = response?.total ?? 0;
      });
  }

  onChangeSortDocuments(sortInfo: Sort) {
    if (sortInfo.active === 'documentType') {
      this.sortInfoDocument = {
        active: 'documentTypeName',
        direction: sortInfo.direction,
      };
    } else if (sortInfo.active === 'uploadDate') {
      this.sortInfoDocument = {
        active: 'uploadDateNoTime',
        direction: sortInfo.direction,
      };
    } else {
      this.sortInfoDocument = sortInfo;
    }
    this.getDocumentListData();
  }

  onChangeDocumentsPage(event: PageEvent) {
    this.pageDocumentSize = event.pageSize;
    this.pageDocumentIndex = event.pageNumber;
    this.getDocumentListData();
  }

  onChangeSortException(sortInfo: Sort) {
    this.sortInfoException = sortInfo;
    this.getExceptionListData();
  }

  onChangeExceptionPage(pageEvent: PageEvent) {
    this.pageExceptionSize = pageEvent.pageSize;
    this.pageExceptionIndex = pageEvent.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + this.calculationType + BenefitType.BenefitException + PAGE_SIZE_CONST,
      pageEvent.pageSize.toString(),
    );
    this.getExceptionListData();
  }

  onDownloadFile(row: any) {
    if (!row) {
      return;
    }
    this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
    this.documentStore.dispatch(
      getDocumentDownloadAction({ fileId: row?.fileId as string, fileName: row.fileName as string }),
    );
  }

  private selectDownloadDocumentState(): void {
    this.calculationStore
      .select(getRetirementBenefitDownloadDocumentSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((downloadDocument) => {
        if (!downloadDocument?.isLoading && downloadDocument?.success && !this.isAddDocumentOpen) {
          let blobFile = downloadDocument?.payload ? downloadDocument?.payload[0] : new Blob();
          downloadFile.call(this, blobFile, this.downloadFileName);
          this.calculationStore.dispatch(clearGetRetirementBenefitDownloadDocumentStateAction());
        }
      });
  }

  selectorCheckLoddDocumentCanEdit() {
    this.calculationStore
      .select(checkExistLoddDocumentCanEditSelector)
      .pipe(
        filter((state) => !state?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearCheckLoddDocumentCanEditStateAction());
        if (response?.success) {
          const canEditDocument = !response?.payload?.isValidRemoveDocumentBenefit;
          if (canEditDocument) {
            this.calculationStore.dispatch(
              editRetirementBenefitDocumentAction({
                memberId: this.memberId,
                calculationBenefitDocumentId: this.documentId,
                request: this.dataEdit,
              }),
            );
          } else {
            this.dialog.open(ConfirmPopupComponent, {
              panelClass: 'confirm-popup',
              data: {
                text: response.payload?.message,
                type: ConfirmType.Warning,
                cancelButtonTitle: 'Close',
                title: 'Error',
                hideConfirmButton: true,
              },
            });
          }
        }
      });
  }

  onEditDocument(file: any) {
    // Open form upload
    let rowData = deepClone(file);
    this.documentId = rowData?.id;
    rowData['tagDescriptionsList'] = rowData?.tags;
    rowData['showOnOverview'] = rowData?.showOnOverview === true ? 'Yes' : 'No';
    rowData['documentLocation'] = this.isLODDSurvivor
      ? DOCUMENT_LOCATION.LODD_SURVIVOR
      : this.isLODDDeath
        ? DOCUMENT_LOCATION.LODD_DEATH
        : DOCUMENT_LOCATION.RETIREMENT;
    rowData['documentLocationRouter'] =
      `/member/benefit-overview/${this.calculationType}/${this.memberId}/detail/${this.calculationBenefitId}`;
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    };
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: this.validateExistDocumentNameExist(rowData?.fileId ?? ''),
    };
    const documentTypeOptionList = this.retirementBenefitDialogComponentService.getCalculationDocumentTypeList(
      this.layoutService.fundType as unknown as FundType,
    );
    const specificMenuData = {
      usedForMenu: USED_FOR_MENU.EDIT_COMMON_DOCUMENT,
      documentTypeOptionList: this.isLODD ? this.documentTypeOfLODD : deepClone(documentTypeOptionList),
    };
    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        document: rowData,
        infoForm,
        specificMenuData,
      },
    });
    editDocumentDialog.afterClosed().subscribe((objectEdit: any) => {
      if (objectEdit) {
        const request = {
          documentName: objectEdit.documentName,
          tags: objectEdit.tags,
          documentDescription: objectEdit.description,
          showOnOverview: objectEdit.showOnOverview,
          documentType: objectEdit.type,
        };
        this.dataEdit = request;

        if (
          this.isLODD &&
          rowData['documentType'] === DisplayDocumentTypeName['Death Certificate'] &&
          objectEdit.type === DisplayDocumentTypeName['Other']
        ) {
          const request = {
            memberId: this.memberId,
            calculationBenefitId: this.calculationBenefitId,
          };
          this.calculationStore.dispatch(checkLoddDocumentCanEditAction({ request }));
          return;
        }

        this.calculationStore.dispatch(
          editRetirementBenefitDocumentAction({
            memberId: this.memberId,
            calculationBenefitDocumentId: rowData?.id,
            request: request,
          }),
        );
      }
    });
  }

  registerEditDocumentSelector() {
    this.calculationStore
      .pipe(select(editRetirementBenefitDocumentsSelector), takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data) {
          if (data?.state?.state === STATE.FAIL && data?.errorMsg) {
            showBanner.call(this, data?.state?.state, '', '', { customMessage: data?.errorMsg });
          } else if (data?.state?.state === STATE.FAIL && !data?.errorMsg) {
            showBanner.call(
              this,
              data?.state?.state,
              this.isLODDDeath
                ? 'Line of Duty Death Document'
                : this.isLODDSurvivor
                  ? 'Line of Duty Death Survivor Document'
                  : 'Retirement Benefit Document',
              data?.state?.action,
            );
          } else {
            showBanner.call(
              this,
              data?.state?.state,
              this.isLODDDeath
                ? 'Line of Duty Death Document'
                : this.isLODDSurvivor
                  ? 'Line of Duty Death Survivor Document'
                  : 'Retirement Benefit Document',
              data?.state?.action,
            );
            this.getDocumentListData();
          }
          this.calculationStore.dispatch(clearEditRetirementBenefitDocumentStateAction());
        }
      });
  }

  onRemoveDocument(row: any) {
    this.calculationBenefitDocumentDetailId = row.calculationBenefitDocumentDetailId;
    if (this.isLODD && row.documentType === DisplayDocumentTypeName['Death Certificate']) {
      const request = {
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
      };
      this.calculationStore.dispatch(checkLoddDocumentCanRemoveAction({ request }));
      return;
    }
    if (this.retirementBenefitDocuments.length === 1 && !this.isLODD) {
      const ALERT_MESSAGE =
        'It is required to have at least one document uploaded for the retirement benefit, so this document cannot be removed. Please upload the new document before removing this one.';
      this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          text: ALERT_MESSAGE,
          type: ConfirmType.Warning,
          title: 'Error',
          cancelButtonTitle: 'Close',
          hideConfirmButton: true,
        },
      });
    } else {
      this.removeDocumentEvent();
    }
  }

  selectorCheckLoddDocumentCanRemove() {
    this.calculationStore
      .select(checkExistLoddDocumentCanRemoveSelector)
      .pipe(
        filter((state) => !state?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearCheckLoddDocumentCanRemoveStateAction());
        if (response?.success) {
          const canRemoveDoc = !response?.payload?.isValidRemoveDocumentBenefit; // BE named this field not clearly
          if (canRemoveDoc) {
            this.removeDocumentEvent();
          } else {
            this.dialog.open(ConfirmPopupComponent, {
              panelClass: 'confirm-popup',
              data: {
                text: response.payload?.message,
                type: ConfirmType.Warning,
                cancelButtonTitle: 'Close',
                title: 'Error',
                hideConfirmButton: true,
              },
            });
          }
        }
      });
  }

  removeDocumentEvent() {
    const ALERT_MESSAGE =
      'This document will be removed and no longer appear in the system. Are you sure you want to proceed?';
    const confirmRemove = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: ALERT_MESSAGE,
        type: ConfirmType.Destruct,
        cancelButtonTitle: 'Cancel',
      },
    });

    confirmRemove
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          const removeRequest = {
            memberId: this.memberId,
            calculationBenefitId: this.calculationBenefitId,
            calculationType: this.calculationType,
            id: this.calculationBenefitDocumentDetailId,
          };
          this.calculationStore.dispatch(removeRemoveCalculationDocumentDetailAction({ request: removeRequest }));
        }
      });
  }

  private selectRemoveDocumentState(): void {
    this.calculationStore
      .select(removeRemoveCalculationDocumentDetailSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response) {
          this.calculationStore.dispatch(clearRemoveRemoveCalculationDocumentDetailStateAction());
          showBanner.call(
            this,
            response?.state,
            this.isLODDDeath
              ? 'Line of Duty Death Document'
              : this.isLODDSurvivor
                ? 'Line of Duty Death Survivor Document'
                : 'Retirement Benefit Document',
            response?.action,
          );
          if (response?.state === STATE.SUCCESS) {
            this.getDocumentListData();
          }
        }
      });
  }

  onUploadDocuments() {
    this.isAddDocumentOpen = true;

    if (this.calculationType === CalculationType.LODDDeath || this.calculationType === CalculationType.LODDSurvivor) {
      this.uploadDocumentsFromParticipantDocuments();
    } else {
      this.openUploadDocumentPopup(this.benefitDocuments);
    }
  }

  private openUploadDocumentPopup(benefitDocuments: any[]) {
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    };
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: this.validateExistDocumentNameExist(''),
      // @-> US #102907
      // Default value of Show on Overview should be Check
      // but with Retirement Benefit or LODD, default value of Existing Document/New Document is Existing
      // so this one still need to be Uncheck, then will change to Check if user change radio to New Document
      defaultShowOnOverview: false,
    };
    const documentTypeOptionList = this.retirementBenefitDialogComponentService.getCalculationDocumentTypeList(
      this.layoutService.fundType as unknown as FundType,
    );
    const specificMenuData = {
      shouldUseCommonBreadcrumbs: true,
      usedForMenu: this.isLODD ? USED_FOR_MENU.LODD_CALCULATION_DETAIL : USED_FOR_MENU.RETIREMENT_BENEFIT_DETAIL,
      documentTypeOptionList: this.isLODD ? this.documentTypeOfLODD : documentTypeOptionList,
      acceptFile: '.pdf',
      existDocumentRadioOptionList: this.existDocumentOptionList,
      benefitDocuments: benefitDocuments,
      detailBenefitDocument: this.retirementBenefitDocuments,
      memberId: this.memberId,
      calculationType: this.calculationType,
      checkPattern: new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i'),
    };

    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        infoForm,
        specificMenuData,
      },
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const documentList = objectUpload?.documentList;
        let fileRequest = documentList.map((doc: any) => {
          if (doc.id) {
            return {
              id: doc.id,
              uploadDate: doc.uploadDate,
              type: doc.type,
              documentLocationTitle: this.isLODDSurvivor
                ? DOCUMENT_LOCATION.LODD_SURVIVOR
                : this.isLODDDeath
                  ? DOCUMENT_LOCATION.LODD_DEATH
                  : DOCUMENT_LOCATION.RETIREMENT,
              documentLocationRouter: `/member/benefit-overview/${this.calculationType}/${this.memberId}/detail/${this.calculationBenefitId}`,
            };
          } else {
            return {
              calculationBenefitId: this.calculationBenefitId,
              uploadDate: doc.uploadDate,
              documentName: doc.documentName,
              type: doc.type,
              file: doc.file,
              tags: doc.tags,
              fileName: doc.file.name,
              documentLocationTitle: this.isLODDSurvivor
                ? DOCUMENT_LOCATION.LODD_SURVIVOR
                : this.isLODDDeath
                  ? DOCUMENT_LOCATION.LODD_DEATH
                  : DOCUMENT_LOCATION.RETIREMENT,
              documentLocationRouter: `/member/benefit-overview/${this.calculationType}/${this.memberId}/detail/${this.calculationBenefitId}`,
              documentDescription: doc.decription,
              showOnOverview: doc.showOnOverview,
            };
          }
        });
        const request: CreateRetirementBenefitDetailUploadDocumentRequest = {
          memberId: this.memberId,
          calculationType: this.calculationType,
          calculationBenefitId: this.calculationBenefitId,
          files: fileRequest,
        };
        this.calculationStore.dispatch(createRetirementBenefitDetailUploadDocumentAction({ request }));
      }
      this.isAddDocumentOpen = false;
    });
  }

  private uploadDocumentsFromParticipantDocuments() {
    this.memberStore.dispatch(getParticipantDocumentsBenefitsAction({ memberId: this.memberId }));
  }

  private selectParticipantDocumentsBenefitsState() {
    this.memberStore
      .pipe(
        select(fromMember.getParticipantDocumentsBenefitsState),
        filter((state) => !!state && !!state.success),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        this.openUploadDocumentPopup(state?.payload ?? []);
        this.memberStore.dispatch(clearGetParticipantDocumentsBenefitsStateAction());
      });
  }

  private getValidateCalculationBenefitData(processToStatus: CalculationBenefitHistoryStatus) {
    this.calculationStore.dispatch(
      validateCalculationBenefitAction({
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
        calculationType: this.calculationType,
        processToStatus,
      }),
    );
  }

  private selectValidateCalculationBenefitState(): void {
    this.calculationStore
      .select(validateCalculationBenefitSelector)
      .pipe(
        filter((rsp) => !!rsp?.payload),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearValidateCalculationBenefitAction());

        // When there is no error
        const isValid = res?.payload?.isValid;
        if (typeof isValid !== 'boolean') {
          return;
        }

        // Handle when click Approve button and data is valid
        if (isValid && this.currentStatus === CalculationBenefitHistoryStatus['Pending Approval']) {
          this.calculationStore.dispatch(
            approveCalculationBenefitAction({
              memberId: this.memberId,
              calculationBenefitId: this.calculationBenefitId,
              calculationType: this.calculationType,
            }),
          );
          return;
        }
        // Handle when click Complete button and data is valid
        if (isValid && this.currentStatus === CalculationBenefitHistoryStatus.Initiated) {
          this.calculationStore.dispatch(
            completeCalculationBenefitAction({
              memberId: this.memberId,
              calculationBenefitId: this.calculationBenefitId,
              calculationType: this.calculationType,
            }),
          );
          return;
        }

        // When there is error but not null response
        const errorType = res?.payload?.errorType;
        if (typeof errorType !== 'number') {
          return;
        }

        let content = '';

        if (
          this.calculationType === CalculationType.RetirementBenefit &&
          errorType === CheckComputeDataChangeErrorType.MemberDeceased
        ) {
          content =
            'New payment instructions will NOT be generated due to member is deceased. Are you sure you want to proceed?';
          this.handleMemberDeceasedConfirmPopup('Confirmation', content, ConfirmType.Cancel);
          return;
        }

        switch (errorType) {
          case CheckComputeDataChangeErrorType.InformationHasBeenChanged:
            content =
              'Some information has been changed and might affect calculation. Please re-calculate before proceeding.';
            break;
          case CheckComputeDataChangeErrorType.NeedToFillInAllInfo:
            content = 'Please fill in all information to proceed.';
            break;
          case CheckComputeDataChangeErrorType.Exception:
            content = 'Please resolve exception to proceed.';
            break;
          case CheckComputeDataChangeErrorType.DeathCertificateIsMissing:
            content = 'Please upload Death Certificate before proceeding.';
            break;
          default:
            break;
        }

        this.handleConfirmPopup(BannerType.Error, content, ConfirmType.Warning);
      });
  }

  handleMemberDeceasedConfirmPopup(title: string, text: string, type: ConfirmType) {
    const confirmResult = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text,
        title,
        type,
        cancelButtonTitle: BUTTON_LABEL_NO,
        hideConfirmButton: false,
      },
    });
    confirmResult
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.calculationStore.dispatch(
            approveCalculationBenefitAction({
              memberId: this.memberId,
              calculationBenefitId: this.calculationBenefitId,
              calculationType: this.calculationType,
            }),
          );
        }
      });
  }
  handleConfirmPopup(title: BannerType, text: string, type: ConfirmType) {
    const confirmResult = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text,
        title,
        type,
        cancelButtonTitle: BUTTON_LABEL_CLOSE,
        hideConfirmButton: true,
      },
    });
    confirmResult
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.getCalculationBenefitDetailData();
        this.getCalculationAuditTrailData();
      });
  }

  closeAlert(): void {
    const params: CalculationBenefitWarningsParams = {
      memberId: this.memberId,
      calculationBenefitId: this.calculationBenefitId,
      detailType: this.calculationType,
    };
    this.calculationStore.dispatch(closeBenefitWarningMessageAction({ params }));
  }

  /* START of AUDIT TRAILS SECTION */
  onChangeSortAuditTrail(sortInfo: Sort): void {
    this.sortInfoCalculationAuditTrail = sortInfo;
    this.getCalculationAuditTrailData();
  }

  onChangeAuditTrailPage(pageEvent: PageEvent): void {
    this.pageCalculationAuditTrailSize = pageEvent.pageSize;
    this.pageCalculationAuditTrailIndex = pageEvent.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + this.calculationType + BenefitType.BenefitAuditTrail + PAGE_SIZE_CONST,
      pageEvent.pageSize.toString(),
    );
    this.getCalculationAuditTrailData();
  }

  private getCalculationAuditTrailData(): void {
    const payload: Omit<GetCalculationAuditTrailRequest, 'sortNames' | 'sortType'> = {
      pageNumber: this.pageCalculationAuditTrailIndex,
      pageSize: this.pageCalculationAuditTrailSize,
      memberId: this.memberId,
      calculationType: this.calculationType,
      calculationId: this.calculationBenefitId,
    };

    const request: GetCalculationAuditTrailRequest = this.retirementBenefitDetailService.getGridDataRequest(
      RetirementBenefitDetailGridDataType.CalculationAuditTrails,
      payload,
      this.sortInfoCalculationAuditTrail,
    );

    let sortType = SortType.DESC;
    let sortNames = '';
    if (this.sortInfoCalculationAuditTrail?.active && this.sortInfoCalculationAuditTrail?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfoCalculationAuditTrail.active);
      sortType = this.sortInfoCalculationAuditTrail.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }
    this.calculationStore.dispatch(getCalculationAuditTrailAction({ request: { ...request, sortType, sortNames } }));
  }

  private selectCalculationAuditTrailState(): void {
    this.calculationStore
      .select(getCalculationAuditTrailSelector)
      .pipe(
        filter((res) => !!res),
        tap((res) => (this.isCalculationAuditTrailLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetCalculationAuditTrailStateAction());
        this.calculationAuditTrails = (response?.payload ?? []).map((item) => {
          return {
            ...item,
            createdDate: getDateString(item.createdDate) ?? '',
          };
        });
        this.totalCalculationAuditTrailRecords = response?.total ?? 0;
      });
  }
  /* END of AUDIT TRAILS SECTION */

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.passedExceptionTrigger.complete();
    this.clearStates();
  }

  private clearStates(): void {
    this.calculationStore.dispatch(clearCheckExceptionConfigurationStateAction());
    this.calculationStore.dispatch(clearGetCalculationBenefitDetailsStateAction());
    this.calculationStore.dispatch(clearGetExceptionListStateAction());
    this.calculationStore.dispatch(clearGetRetirementBenefitDetailDocumentsStateAction());
    this.calculationStore.dispatch(clearGetCalculationAuditTrailStateAction());
    this.calculationStore.dispatch(clearCreateGenerateCalculationWorksheetStateAction());
    this.calculationStore.dispatch(clearComputeCalculationBenefitStateAction());
    this.calculationStore.dispatch(clearCompleteCalculationBenefitStateAction());
    this.calculationStore.dispatch(clearValidateBeforeCalculationBenefitStateAction());
  }

  private openRetirementBenefitDialog(content: Partial<RetirementBenefitDataInput>) {
    this.validateBeforeCalculationBenefit(() => this.handleEditBenefitOptionSection(content), content);
  }

  private handleEditBenefitOptionSection(content: Partial<RetirementBenefitDataInput>): void {
    if (!this.memberId || !content.benefitEntityId) {
      return;
    }

    const editDialog = this.dialog.open(RetirementBenefitDialogComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        isEdit: true,
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId ?? '',
        currentFund: this.layoutService.currentFund$.value,
        calculationType: this.calculationType,
        benefitEntityId: this.benefitEntityId ?? '',
        ...content,
      } as RetirementBenefitDataInput,
    });
    editDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.getCalculationBenefitDetailData();
        this.getCalculationAuditTrailData();
      });
  }

  private openEditCalculationParameters(content: Partial<RetirementBenefitDataInput>) {
    this.validateBeforeCalculationBenefit(() => this.handleEditCalculationParametersSection(content), content);
  }

  private handleEditCalculationParametersSection(content: Partial<RetirementBenefitDataInput>): void {
    const editDialog = this.dialog.open(EditCalculationParameterComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId ?? '',
        currentFund: this.layoutService.currentFund$.value,
        calculationType: this.calculationType,
        ...content,
        benefitEntityId: this.benefitEntityId ?? '',
        isSurvivorOrJointSurvivorRecalculate: this.isSurvivorOrJointSurvivorRecalculate,
      } as RetirementBenefitDataInput,
    });
    editDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.getCalculationBenefitDetailData();
        this.getCalculationAuditTrailData();
      });
  }

  // TODO: for Step 2
  // private openBeneficiaryConfirmationDialog(sectionKey: string, rowId: string) {
  //   if (!sectionKey) {
  //     return;
  //   }
  //   this.dialog.open(BeneficiaryConfirmationComponent, {
  //     panelClass: 'dialog-full-screen',
  //     disableClose: true,
  //     autoFocus: false,
  //     data: {
  //       isSetBenefitDetail: true,
  //       memberId: this.memberId,
  //       benefitEntityId: this.calculationBenefitDetails[0].benefitEntityId,
  //       calculationBenefitId: this.calculationBenefitId,
  //       sectionKey,
  //       rowId,
  //     },
  //   });
  // }

  private getAction(item: DetailItemInfo): Action {
    const isSurvivorOrJointSurvivor =
      this.calculationType === (CalculationType.Survivor || CalculationType.JointSurvivor);
    const isApproved = this.currentStatus === CalculationBenefitHistoryStatus.Approved;
    switch (item.sectionType) {
      case CalculationBenefitDetailType.RetirementOption:
        return {
          showMenu: false,
          list:
            isApproved ||
            this.currentStatus !== CalculationBenefitHistoryStatus.Initiated ||
            (!this.getCalculationBenefitDetailsResponse?.isSurvivorOrJointSurvivorRecalculate &&
              item?.properties.length <= 0) ||
            (isSurvivorOrJointSurvivor && !item?.isShowEditBtn)
              ? []
              : [
                  {
                    buttonLabel: ButtonLabelType.Edit,
                    iconFont: IconFontType.Edit,
                    callback: this.openRetirementBenefitDialog.bind(this, item),
                    class: 'text-only',
                  },
                ],
        };
      case CalculationBenefitDetailType.CalculationParameter:
        return {
          showMenu: false,
          list:
            isApproved || this.currentStatus !== CalculationBenefitHistoryStatus.Initiated
              ? []
              : [
                  {
                    buttonLabel: ButtonLabelType.Edit,
                    iconFont: IconFontType.Edit,
                    callback: this.openEditCalculationParameters.bind(this, item),
                    class: 'text-only',
                  },
                  // {
                  //   buttonLabel: ButtonLabelType.Remove,
                  //   iconFont: IconFontType.Remove,
                  //   callback: () => {},
                  // },
                ],
        };
      case CalculationBenefitDetailType.Beneficiary:
        // const beneficiaryKey = item.entityComponentId;
        // const rowId = item.rowId;
        return {
          showMenu: false,
          list: [],
        };
      case CalculationBenefitDetailType.BenefitCalculation:
        return {
          showMenu: false,
          list:
            [CalculationType.RetirementBenefit, CalculationType.Survivor, CalculationType.JointSurvivor].includes(this.calculationType)
            && !this.isChicagoParks
            ? [
                {
                  buttonLabel: 'Calculation Worksheet',
                  callback: this.generateCalculationWorksheet.bind(this),
                  class: 'link',
                  labelClass: 'text-[18px]',
                },
              ]
            : [],
        };
      default:
        return {} as Action;
    }
  }

  private getPropertyType(prop: CalculationBenefitDetails) {
    return EntityPropertyType[prop.type];
  }

  private getPropertyValue(prop: CalculationBenefitDetails, valueKey: string = 'value') {
    if (prop.type === EntityPropertyType['Entity Reference']) {
      return prop.option;
    }
    return (prop as any)[valueKey];
  }

  private getMaxWidth(columnCount: number = 1) {
    return `calc((100% / ${columnCount}) - (${columnCount - 1}rem / ${columnCount}))`;
  }

  validateExistDocumentNameExist(documentId?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || !control.value.trim()) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.retirementBenefitDialogService
              .checkExits({
                memberId: this.memberId || '',
                name: control.value.toString(),
                fileId: documentId,
                calculationType: this.calculationType
              })
              .pipe(
                map((response: CheckExistsDocumentNameResponse) => {
                  if (response?.exists) {
                    return { errMsgDocumentName: 'Document Name already exists.' };
                  }
                  return null;
                }),
                catchError(({ error }) => {
                  return of({ errMsgDocumentName: error?.errorMessage });
                }),
              ),
        ),
      );
    };
  }

  uploadDocumentSelector() {
    this.calculationStore
      .select(createRetirementBenefitDetailUploadDocumentSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((retirementBenefitDocument) => {
        if (retirementBenefitDocument && !retirementBenefitDocument?.isLoading) {
          showBanner.call(
            this,
            retirementBenefitDocument?.success ? STATE.SUCCESS : STATE.FAIL,
            'Document',
            ACTION.UPLOAD,
          );

          if (retirementBenefitDocument?.success) {
            this.getDocumentListData();
          }
          this.calculationStore.dispatch(clearCreateRetirementBenefitDetailUploadDocumentStateAction());
        }
      });
  }

  getRetirementBenefitDocumentData() {
    this.calculationStore.dispatch(
      getRetirementBenefitDocumentsAction({
        request: {},
        memberId: this.memberId,
        calculationType: this.calculationType,
        calculationBenefitId: this.calculationBenefitId,
      }),
    );
  }

  selectBenefitDocument() {
    this.calculationStore
      .select(getRetirementBenefitDocumentSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((retirementBenefitDocument) => {
        if (!retirementBenefitDocument?.isLoading && retirementBenefitDocument?.success) {
          this.benefitDocuments = retirementBenefitDocument?.payload ?? [];
        }
      });
  }

  private generateCalculationWorksheet() {
    const request = {
      calculationBenefitId: this.calculationBenefitId,
      benefitEntityId: this.benefitEntityId,
      fundType: this.layoutService.fundType as FundType,
      calculationType: this.calculationType,
      timeZoneOffSet: new Date().getTimezoneOffset(),
    };
    this.calculationStore.dispatch(createGenerateCalculationWorksheetAction({ memberId: this.memberId, request }));
  }

  selectCreateGenerateCalculationWorksheetState() {
    this.calculationStore
      .select(createGenerateCalculationWorksheetSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        map((res) => res?.payload),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((createGenerateCalculationWorksheetResponse) => {
        if (!createGenerateCalculationWorksheetResponse) {
          return;
        }
        this.calculationStore.dispatch(clearCreateGenerateCalculationWorksheetStateAction());
        this.openPreviewPdfDialog(createGenerateCalculationWorksheetResponse);
      });
  }

  private openPreviewPdfDialog(createGenerateCalculationWorksheetResponse: CreateGenerateCalculationWorksheetResponse) {
    return this.dialog.open(PreviewPdfFileDialogComponent, {
      panelClass: 'edit-popup',
      height: '90vh',
      disableClose: true,
      autoFocus: false,
      data: {
        ...createGenerateCalculationWorksheetResponse,
        file: createGenerateCalculationWorksheetResponse.file?.[0],
        getFileName: () => {
          const separator = '_';
          const currentDateTime = DateTime.now().toFormat('yyyyMMddHHmmss');
          const splitFileName = createGenerateCalculationWorksheetResponse.fileName?.split(separator);
          if (splitFileName.length) {
            splitFileName[splitFileName.length - 1] = currentDateTime;
          }
          return splitFileName.join(separator) ?? createGenerateCalculationWorksheetResponse.fileName;
        },
      } as PreviewPdfFileDialogData,
    });
  }

  getHyperlinkParams(benefitDetail: CalculationBenefitDetails) {
    if (benefitDetail.hyperlinkParams && benefitDetail.value) {
      return {
        ...(benefitDetail.hyperlinkParams ?? {}),
        targetId: benefitDetail.value,
      };
    }
    return undefined;
  }

  onFieldClick(item: BodyContent) {
    {
      if (!item?.hyperlinkParams?.entityId || !this.memberId) {
        return;
      }
      const recordId = item.hyperlinkParams.targetId;
      const entityReferenceLinkedId = item?.hyperlinkParams?.entityId;
      this.store.dispatch(
        LayoutActions.profileNavigationItemRequest({
          memberId: recordId || this.memberId,
          entityReferenceLinkedId,
          isHyperlink: true,
        }),
      );

    this.store
      .select(fromLayoutReducer.selectProfileNavigationState)
      .pipe(
        filter((state) => !!state.isHyperlink),
        take(1),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (state?.isHyperlink && state?.menu?.length && recordId) {
          const overviewView = state.memberNavigationList as any;
          const isOverviewDetailView = (state.memberNavigationList as any)?.isOverviewDetailView;
          const url = `${isOverviewDetailView ? '/member/detail-view/true' : '/member/summary-view/true'}/${
            overviewView.id
          }/${overviewView.overviewViewId}/${recordId}?${
            STRING_QUERY_PARAM.PROFILE_NAVIGATE
          }&entityReferenceLinkedId=${overviewView.entityId}`;
          const entityReferenceLinkedIdParam = new URLSearchParams(document.location.search).get(
            'entityReferenceLinkedId',
          );

            if (
              url.slice(0, url.indexOf('?')) === document.location.pathname &&
              entityReferenceLinkedId === entityReferenceLinkedIdParam
            ) {
              this.store.dispatch(LayoutActions.selectTab({ tab: DisplayedTabName.IndividualParticipant, url }));
              return;
            }

            this.store.dispatch(LayoutActions.clearProfileNavigationItemState());
            this.store.dispatch(
              LayoutActions.selectTab({
                tab: DisplayedTabName.IndividualParticipant,
                url,
              }),
            );
          }
        });
    }
  }

  getQDROBenefitInfoSectionShowStatus(isShow: boolean): void {
    this.isShowQDROBenefitInfoSection = isShow;
  }

  private validateBeforeCalculationBenefit(callback: () => void, content?: Partial<RetirementBenefitDataInput>) {
    if (this.calculationType === CalculationType.JointSurvivor) {
      this.content = content;

      this.calculationStore.dispatch(
        validateBeforeCalculationBenefitAction({
          memberId: this.memberId,
          calculationBenefitId: this.calculationBenefitId,
          calculationType: this.calculationType,
          sectionType: this.content?.sectionType,
        }),
      );
    } else {
      callback();
    }
  }

  // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
  private selectCalculationQDRO() {
    this.calculationStore
      .select(getCalculationQDROSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((getCalculationQDRO) => {
        if (!getCalculationQDRO?.isLoading && getCalculationQDRO?.success) {
          this.qdroLabelName = getCalculationQDRO?.payload?.labelName ?? '';
          if (this.calculationType === CalculationType.QDRO) {
            this.changeTitleWithCalculationTypeQDRO(this.qdroLabelName);
          }
        }
      });
  }

  // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
  private changeTitleWithCalculationTypeQDRO(_qdroLabelName: string) {
    let getTitle = this.retirementBenefitDetailComponentService.getTitleByCalculationType(this.calculationType);
    getTitle = getTitle.replace('{qdroLabelName}', _qdroLabelName);

    this.title = getTitle;
    this.listBreadcrumbs = this.getBreadcrumbs;
  }
}
