import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef, EventEmitter,
  Input,
  OnChanges,
  OnInit, Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseComponent } from '@ptg-shared/components';
import {
  InputType,
  StatusOption,
} from '@ptg-shared/controls/dynamic-input/types/enums/dynamic-input.enum';
import { deepClone, isNumeric } from '@ptg-shared/utils/common.util';
import { getDateString, isEmpty, stringToBoolean } from '@ptg-shared/utils/string.util';
import { BodyContent } from '../types/models';

@Component({
  selector: 'ptg-field-display',
  templateUrl: './field-display.component.html',
  styleUrls: ['./field-display.component.scss'],
})
export class FieldDisplayComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() data?: BodyContent[];
  @Input() columnNumber!: number;
  @Input() isCustomData?: boolean = false;
  @ViewChildren('contentEl') contentElList!: QueryList<ElementRef>;
  @Output() fieldClick: EventEmitter<BodyContent> = new EventEmitter();

  constructor(
    public readonly route: ActivatedRoute,
    private router: Router,
    private readonly renderer: Renderer2,
  ) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.handleData();
    }
  }
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const flexBasisValue = `${(100 / (this.columnNumber ?? 1))?.toFixed(2)}%`;
    this.contentElList.forEach((elRef) => this.renderer.setStyle(elRef.nativeElement, 'width', flexBasisValue));
  }

  handleData() {
    this.data = (this.data ?? []).map((item) => {
      if (!item.type) {
        return item;
      }
      if ([InputType.Currency].includes(item?.type as InputType) 
        && !isEmpty(item?.value) 
        && item.value.includes(',')
      ) {
        item.value = item.value.replaceAll(',', '');
      }
      if (
        [
          InputType.Number,
          InputType.Currency,
          InputType.Decimal,
          InputType.Percentage,
          InputType.Whole_Number,
        ].includes(item?.type as InputType) &&
        (isEmpty(item.value) || item.value === '' || !isNumeric(item.value))
      ) {
        item.value = null;
      }
      if ([InputType.Date, InputType.DateTime].includes(item?.type as InputType) && (item.value === 'Invalid Date' || item.value === 'Invalid DateTime')) {
        item.value = null;
      }
      if (item.value === 'Invalid Date' && InputType.Date === item?.type) {
        item.value = null;
      }
      if (item.value === 'Invalid Date' && InputType.DateTime === item?.type) {
        item.value = null;
      }
      if (item.type === InputType.Binary) {
        item.value = isEmpty(item.value) ? null : stringToBoolean(item.value ?? '');
        if (this.isCustomData && Object.keys(item.config).length === 0) {
          item.config = {
            affirmative: 'Yes',
            negative: 'No',
          };
        }
      }
      if ([InputType.Person_Name, InputType.Address].includes(item.type as InputType)) {
        if (typeof item?.value === 'object') {
          return item;
        }
        const parsedValue = JSON.parse(item?.value || '{}');
        if (parsedValue && typeof parsedValue === 'object') {
          item.value = Object.assign(
            {},
            ...Array.from(Object.entries(parsedValue), ([k, v]) => ({
              [k[0].toLowerCase() + k.slice(1)]: v,
            })),
          );
        } else {
          item.value = parsedValue;
        }
      }
      if (item.type === InputType.Status) {
        if (item.option === StatusOption.Date) {
          item.value = getDateString(item.value.statusDate);
          item.type = InputType.Date;
          return item;
        }
        if (item.option) {
          const optionValue = item.options.find((option: any) => option.id === item.value[item.option === StatusOption.Event ? 'event' : 'status']);
          item.value = optionValue?.text;
        }
        return item;
      }
      if ([InputType.Ssn, InputType.Phone, InputType.Email].includes(item.type as InputType)) {
        item.valueMask = deepClone(item.value);
        item.value = stringToBoolean(item.config?.masked) ? item.valueMask?.maskedValue : item.valueMask?.originalValue;
      }
      return item;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClickIcon(property: BodyContent) {
    if (property.isMasked) {
      property.value = property.valueMask?.originalValue;
      property.isMasked = false;
    } else {
      property.value = property.valueMask?.maskedValue;
      property.isMasked = true;
    }
  }

  onClickCaseNumber(property: BodyContent) {
    const path = property.path;
    if (path) {
      this.router.navigateByUrl(path);
      return;
    }
    this.fieldClick.emit(property);
  }
}
