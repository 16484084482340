import { Component, Inject, Input, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ACTION, CANCEL_CONFIRM_MESSAGE, DEFAULT_PAGE_SIZE, ENTITY_ORGANIZATION_GUID, SortType, STATE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { Option } from '@ptg-shared/controls/select/select.component';
import { EntityType } from '../../types/enums/entity-type.enum';
import { AddTagComponent } from '../add-tag/add-tag.component';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { TagsState } from '../../store/reducers';
import { select, Store } from '@ngrx/store';
import { clearGetDocumentDownloadStateAction, clearGetRetirementBenefitDownloadDocumentStateAction, clearTagDetailStateAction, getDocumentDownloadAction, getRetirementBenefitDownloadDocumentAction, getTagListAction } from '../../store/actions';
import { Observable, Subject, combineLatest } from 'rxjs';
import { addTagListSelector, getRetirementBenefitDownloadDocumentSelector, getTagListSelector } from '../../store/selectors';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '@ptg-shared/components';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { deepClone, downloadFile } from '@ptg-shared/utils/common.util';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { DatePipe } from '@angular/common';
import { MemberDocumentService } from '@ptg-member/services/member-document.service';
import { EmployerDocumentService } from '@ptg-employer/services/employer-document.service';
import { getDateString, isNullOrUndefinedOrEmptyString, localeCompare } from '@ptg-shared/utils/string.util';
import { USED_FOR_MENU } from '@ptg-shared/constance/document-location.const';
import { SpecificMenuData } from 'src/app/admin/services/models';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { ACTION_COLUMN, Align, Column, ColumnType } from '@ptg-shared/controls/grid';
import { CalculationDocumentType, DisplayDocumentTypeName } from '@ptg-member/features/calculation/types/enums/calculation.enum';
import { CalculationBenefitDocumentInputDto } from '@ptg-member/features/calculation/services/models/retirement-benefit-detail-upload-document.model';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination/constants';
import { PageEvent } from '@ptg-shared/controls/pagination/types/page-event.model';
import { Sort } from '@angular/material/sort/sort';
import { DateTime } from 'luxon';
import { RetirementBenefitDocument } from '@ptg-member/features/calculation/services/models/retirement-benefit-history.model';
import { RetirementBenefitDetailDocument } from '@ptg-member/features/calculation/services/models/retirement-benefit-detail-document.model';
import { UploadComponent } from '@ptg-shared/controls/upload/upload.component';
import { MemberDetailService } from '@ptg-member/services/member-detail.service';
import { UploadMultipleComponent } from '@ptg-shared/controls/upload-multiple/upload-multiple.component';
import { DocumentState } from '@ptg-employer/reducers/employer-document.reducer';
import {
  EMPLOYER_DOCUMENT_TYPE_OPTIONS,
  PARTICIPANT_DOCUMENT_TYPE_OPTIONS,
  PARTICIPANT_DOCUMENT_TYPE_OPTIONS_FOR_BVFF,
  ParticipantGenerateDocumentType,
} from '../../constants';
import * as fromReducer from '@ptg-reducers';
import { FundType } from '@ptg-shared/types/enums';

@Component({
  selector: 'ptg-edit-document',
  templateUrl: './edit-document.component.html',
  styleUrls: ['./edit-document.component.scss'],
})
export class EditDocumentComponent extends BaseComponent {
  @ViewChild(UploadMultipleComponent) uploadMultipleComponent!: UploadMultipleComponent;
  readonly EntityType = EntityType;
  readonly EMPLOYER_DOCUMENT_TYPE_OPTIONS = EMPLOYER_DOCUMENT_TYPE_OPTIONS;
  readonly ENTITY_ORGANIZATION_GUID = ENTITY_ORGANIZATION_GUID;
  formSubmit$ = new Subject<boolean>();
  listBreadcrumbs: Breadcrumb[] = [];
  entityType!: EntityType;
  editForm!: FormGroup;
  title: string = 'Upload Document';
  targetId?: string;
  labelShowOverview: string = '';
  filteredTagOptions!: Observable<Option[]>;
  isGetTagListLoading: boolean = true;
  filteredByTypingTagOptions!: Observable<Option[]>;
  currentTagsChipValue!: Option;
  currentTagsChipValueList: string[] = [];
  listOptionTag: Option[] = [];
  parameterFormControlList: any[] = [];
  isEditDocumentFile: boolean = false;
  filesEdit: any[] = [];
  isNewTagAddedSuccess: boolean = false;
  @Input() acceptFile = `*.*`;
  supportedFileTypes: string = '';
  isUnsupportedFileType = false;
  checkPattern: RegExp = new RegExp(/[^\\]*\.(\w+)$/, "i"); // @-> This regex use for all cases of any file types, use as default
  filesUpload: any[] = [];
  documentLocation: string = '';
  documentLocationRouter: string = '';
  errorMessageRequire: string = 'Document is required.';
  isShowMessageErrorFilesControl: boolean = false;
  isNoUpload: boolean = true;
  usedForMenu!: USED_FOR_MENU;
  usedForMenuConst = USED_FOR_MENU;
  documentTypeOptionList: Option[] = [];
  existDocumentType: boolean = false;
  uploadDate!: Date;
  isShowExistDocumentRadio: boolean = false;
  isShowErrorMessage: boolean = false;
  existDocumentRadioOptionList: RadioOption[] = [];
  existDocumentDropdownOptionList: Option[] = [];
  isShowDocumentTable: boolean = false;
  uploadedDate: string = '';
  lastUpdatedDate: string = '';
  defaultPageSize: number = DEFAULT_PAGE_SIZE;
  documentColumns: Column[] = [
    {
      name: 'uploadDate',
      header: {
        title: 'Upload Date',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
    },
    {
      name: 'documentName',
      header: {
        title: 'Document Name',
      },
      align: Align.Left,
      sortable: true,
      truncate: true,
    },
    {
      name: 'fileName',
      header: {
        title: 'File Name',
      },
      align: Align.Left,
      sortable: true,
      truncate: true,
    },
    {
      name: 'type',
      header: {
        title: 'Document Type',
      },
      align: Align.Left,
      sortable: true,
      truncate: true,
      cell: (row) => {
        return DisplayDocumentTypeName[row.type];
      },
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '200px',
    },
  ];
  benefitDocuments: RetirementBenefitDocument[] = [];
  detailBenefitDocument: RetirementBenefitDetailDocument[] | undefined = [];
  documentList: CalculationBenefitDocumentInputDto[] = [];
  filteredDocuments: CalculationBenefitDocumentInputDto[] = [];
  selectedDocument: any;
  sortInfo: any;
  pageNumber = FIRST_PAGE;
  pageSize = DEFAULT_PAGE_SIZE;
  notFoundMessage: string = '';
  documentTableTitle: string = '';

  // Use for Single File Upload only //
  acceptFileForSingleUpload = 'application/pdf';
  supportedFileTypesForSingleUpload = 'pdf file format';
  checkPatternForSingleUpload = new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i');
  fileSelected!: File;
  @ViewChild('fileDocumentSingleUpload')
  private fileDocumentSingleUpload!: UploadComponent;
  singleFile?: File;
  // Use for Single File Upload only //

  attachmentFile?: File;
  @ViewChild('fileDocument')
  private fileDocument!: UploadMultipleComponent;
  errDuplicated!: string;

  downloadFileName: string = '';
  documentTypeValueSelected: string = '';
  isParticipantDocument: boolean = false;
  isEmployerDocument: boolean = false;
  isUsedForCalculationDocumentFeatures: boolean = false;
  isRetirementBenefitDetailOrLODDDetail: boolean = false;
  participantDocumentTypeOptionList: Option[] = [];

  constructor(
    public layoutService: LayoutService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        currentEntity: any,
        document: any, // includes: entityType, entityId, employerName
        infoForm: any, // includes: isUploadMultipleFile, validateDocumentName, defaultShowOnOverview
        specificMenuData?: SpecificMenuData,
      },
    private readonly fb: FormBuilder,
    public tagStore: Store<TagsState>,
    public documentStore: Store<DocumentState>,
    private datePipe: DatePipe,
    private memberDocumentService: MemberDocumentService,
    private employerDocumentService: EmployerDocumentService,
    private memberDetailService: MemberDetailService,
    private store: Store<fromReducer.State>,
  ) {
    super();
  }

  get tagsChipListControl(): FormArray {
    return this.editForm?.get('tagsChipList') as FormArray;
  }

  get tagsAutoCompleteControl(): FormControl {
    return this.editForm?.get('tags') as FormControl;
  }

  getBreadcrumbs(entityType: EntityType, entityId: string): Breadcrumb[] {
    const url = entityType == EntityType.Employer ? `/employer/individual-document/${entityId}` : `/member/individual-document/${entityId}`;

    // Start of handle for specific menu Upload //
    if (this.data?.specificMenuData?.shouldUseCommonBreadcrumbs) {
      return [
        { name: this.title },
      ];
    }
    // End of handle for specific menu Upload //

    return [
      {
        name: 'Document List',
        url,
      },
      { name: this.title },
    ];
  }

  ngOnInit(): void {
    if (this.layoutService.fundType === FundType.BVFF) {
      this.participantDocumentTypeOptionList = PARTICIPANT_DOCUMENT_TYPE_OPTIONS_FOR_BVFF;
    } else {
      this.participantDocumentTypeOptionList = PARTICIPANT_DOCUMENT_TYPE_OPTIONS;
    }

    this.isEmployerDocument = this.data.currentEntity.entityType === EntityType.Employer
      && !isNullOrUndefinedOrEmptyString(this.data?.document?.documentType);

    this.isParticipantDocument = this.data.currentEntity.entityType === EntityType.Participant
      && !this.data?.document?.calculationBenefitDocumentId
      && !this.data?.document?.accidentId
      && !isNullOrUndefinedOrEmptyString(this.data?.document?.participantDocumentType)
      // check if document was generated
      && !Object.values(ParticipantGenerateDocumentType).includes(this.data?.document?.participantDocumentType);

    // Start of handle for specific menu Upload //
    if (this.data?.specificMenuData?.usedForMenu) {
      this.usedForMenu = this.data?.specificMenuData?.usedForMenu;

      this.existDocumentType = [
        USED_FOR_MENU.EDIT_COMMON_DOCUMENT,
        USED_FOR_MENU.ACCIDENT,
        USED_FOR_MENU.DISABILITY_DETAIL,
        USED_FOR_MENU.DISABILITY_CALCULATION_DETAIL,
        USED_FOR_MENU.REFUND_DETAIL,
        USED_FOR_MENU.REFUND_CALCULATION_DETAIL,
        USED_FOR_MENU.RETIREMENT_BENEFIT_OVERVIEW,
        USED_FOR_MENU.RETIREMENT_BENEFIT_DETAIL,
        USED_FOR_MENU.LODD_CALCULATION_DETAIL,
      ]?.includes(this.usedForMenu);

      this.isShowExistDocumentRadio = [
        USED_FOR_MENU.DISABILITY_CALCULATION_DETAIL,
        USED_FOR_MENU.REFUND_CALCULATION_DETAIL,
        USED_FOR_MENU.RETIREMENT_BENEFIT_DETAIL,
        USED_FOR_MENU.LODD_CALCULATION_DETAIL,
      ]?.includes(this.usedForMenu);

      this.isUsedForCalculationDocumentFeatures = [
        USED_FOR_MENU.DISABILITY_CALCULATION_DETAIL,
        USED_FOR_MENU.REFUND_CALCULATION_DETAIL,
        USED_FOR_MENU.RETIREMENT_BENEFIT_DETAIL,
        USED_FOR_MENU.LODD_CALCULATION_DETAIL,
      ]?.includes(this.usedForMenu);

      this.isRetirementBenefitDetailOrLODDDetail = [
        USED_FOR_MENU.RETIREMENT_BENEFIT_DETAIL,
        USED_FOR_MENU.LODD_CALCULATION_DETAIL,
      ]?.includes(this.usedForMenu);

      this.isShowDocumentTable = [
        USED_FOR_MENU.DISABILITY_CALCULATION_DETAIL,
        USED_FOR_MENU.REFUND_CALCULATION_DETAIL,
        USED_FOR_MENU.RETIREMENT_BENEFIT_DETAIL,
        USED_FOR_MENU.LODD_CALCULATION_DETAIL,
      ]?.includes(this.usedForMenu);

      if (this.isShowDocumentTable) {
        this.setValueByType();
      }

      if (this.data?.specificMenuData?.documentTypeOptionList) {
        this.documentTypeOptionList = this.data?.specificMenuData?.documentTypeOptionList;
      }

      if (this.data?.specificMenuData?.existDocumentRadioOptionList?.length) {
        this.existDocumentRadioOptionList = this.data?.specificMenuData?.existDocumentRadioOptionList;
      }

      if (this.data?.specificMenuData?.detailBenefitDocument?.length) {
        this.detailBenefitDocument = this.data?.specificMenuData?.detailBenefitDocument;
      }

      if (this.data?.specificMenuData?.benefitDocuments?.length) {
        this.benefitDocuments = this.data?.specificMenuData?.benefitDocuments;
        this.filterExistingDocument();
      }

      if (this.data?.specificMenuData?.acceptFile) {
        this.acceptFile = this.data?.specificMenuData?.acceptFile;
      }

      if (this.data?.specificMenuData?.checkPattern) {
        this.checkPattern = this.data?.specificMenuData?.checkPattern;
      }
    }
    // End of handle for specific menu Upload //

    if (this.data.document) {
      this.uploadedDate = this.datePipe.transform(getDateString(this.data.document?.dateCreated), 'MM/dd/yyyy') || '';
      this.lastUpdatedDate = this.datePipe.transform(getDateString(this.data.document?.dateUpdated), 'MM/dd/yyyy') || '';
    }
    this.getCurrentFundData();
    this.selectDataTagList();
    this.getTagList();
    this.onInitForm();
    this.registerAddNewTagSelector();
    this.selectorSelectedDownloadFile();
    this.submitForm();
  }

  submitForm() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
          this.isShowMessageErrorFilesControl =
            this.isUnsupportedFileType && !this.data?.infoForm?.isUploadMultipleFile
              ? false
              : !this.filesUpload || this.filesUpload.length === 0;
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter((status) => status === AbstractControlStatus.VALID),
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  getDefaultValueForExistingRadio() {
    if (this.isRetirementBenefitDetailOrLODDDetail) return false // Should be Existing Document for Retirement Benefit Detail and LODD
    if (this.isShowExistDocumentRadio) return true // Should be New Document for remaining cases (but Existing Radio must exist)
    return null;
  }

  onInitForm(): void {
    let documentEdit = this.data.document;
    let chipList: Option[] = [];

    // @-> US 102907 will apply default value of Show on Overview checkbox = Check for all Document screens
    // if we have another new features need to use other default value for this checkbox
    // please implement cases based on injected data of this component (`data.infoForm?.defaultShowOnOverview`)
    let showOnPreview = this.data.infoForm?.defaultShowOnOverview ?? true;

    // Prepare data for Edit Document case
    if (documentEdit) {
      this.title = 'Edit Document';
      this.isEditDocumentFile = true;
      this.filesEdit.push({
        name: documentEdit.fileName,
      });
      this.documentLocation = documentEdit.documentLocation;
      this.documentLocationRouter = documentEdit.documentLocationRouter;
      // get chip list
      if (documentEdit?.tagDescriptionsList) {
        chipList = documentEdit?.tagDescriptionsList?.map((item: any) => ({
          value: item.id,
          displayValue: item.name,
        } as Option));
        this.currentTagsChipValueList.push(...documentEdit?.tagDescriptionsList?.map((item: any) => item.id));
      }
      showOnPreview = documentEdit.showOnOverview === 'Yes' ? true : false;
      this.documentTypeValueSelected = documentEdit?.documentType;
    }

    // Setup initial form data
    this.editForm = this.fb.group({
      documentName: this.fb.control(documentEdit ? documentEdit.documentName : ''
        , {
          validators:
            this.isShowExistDocumentRadio
              ? this.checkDocumentNameTextboxDuplicateAndRequireValidator()
              : [Validators.required],
          asyncValidators: this.data.infoForm.validateDocumentName,
        },
      ),
      type: this.fb.control(
        this.documentTypeValueSelected,
        this.isShowExistDocumentRadio
          ? this.checkDocumentTypeDropdownRequireValidator()
          : this.existDocumentType
            ? [Validators.required]
            : null
      ),
      tags: this.fb.control(''),
      description: this.fb.control(documentEdit ? documentEdit.description : ''),
      showOnOverview: this.fb.control(showOnPreview),
      tagsChipList: this.fb.array(documentEdit ? chipList : []),
      isNewDocument: this.fb.control(
        this.getDefaultValueForExistingRadio()
      ),
      documentId: this.fb.control(
        null,
        this.isShowExistDocumentRadio
          ? this.checkDocumentNameDropdownRequireValidator()
          : null
      ),
      existingDocumentType: this.fb.control(''),
      singleFileUpload: this.fb.control(
        null,
        this.isShowExistDocumentRadio
          ? this.checkSingleUploadFileRequireValidator()
          : null),
      participantDocumentType: this.fb.control(documentEdit?.participantDocumentType),
      employerDocumentType: this.fb.control(documentEdit?.documentType),
    });

    this.entityType = this.data.currentEntity.entityType;
    this.listBreadcrumbs = this.getBreadcrumbs(this.data.currentEntity.entityType, this.data.currentEntity.entityId);
    this.targetId = this.data.currentEntity.entityId;

    // @-> US 102907 will apply new label for all Document screens
    // if we have another new features need to use other label's content
    // please implement cases here by customize injected data of this component (`data.infoForm?`)
    this.labelShowOverview = `Show on Overview`;
  }

  get singleFileUploadControl(): FormControl {
    return this.editForm.get('singleFileUpload') as FormControl;
  }

  onClickBreadcrumb() {
    this.dialogRef.close();
  }

  getTagList() {
    let sortType = SortType.ASC;
    let sortNames = 'Name';
    this.tagStore.dispatch(getTagListAction({
      request: {
        sortNames,
        sortType,
      },
    }));
  }

  registerAddNewTagSelector() {
    this.tagStore
      .pipe(select(addTagListSelector),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (response?.state === STATE.SUCCESS && response?.action === ACTION.ADD) {
          this.isNewTagAddedSuccess = true;
        }
        this.tagStore.dispatch(clearTagDetailStateAction());
      });
  }

  selectDataTagList() {
    this.tagStore
      .pipe(select(getTagListSelector),
        takeUntil(this.unsubscribe$),
      ).subscribe((data) => {
        if (data) {
          this.isGetTagListLoading = false;
          if (data?.success && data?.payload?.length) {
            const previousList = deepClone(this.listOptionTag);

            this.listOptionTag = data?.payload.map((item) => ({
              value: item.id,
              displayValue: item.name,
            } as Option));

            if (this.isNewTagAddedSuccess) {
              const newTag = this.listOptionTag.find(item => !previousList.some(tag => tag.value === item.value));
              if (newTag) {
                this.tagsChipListControl.push(new FormControl(newTag));
                this.currentTagsChipValueList.push(newTag?.value);
              }
              this.isNewTagAddedSuccess = false;
            }
          }
        }
      });
  }

  uploadFile(files: any) {
    if (!this.checkPattern.test(files?.[0]?.name) && !this.data.infoForm?.isUploadMultipleFile) {
      this.isUnsupportedFileType = true;
      return;
    } else {
      this.isUnsupportedFileType = false;
    }
    if (this.usedForMenu === USED_FOR_MENU.ATTACHMENTS) {
      this.uploadFileForAttachmentScreen(files[0]);
    }
    this.filesUpload = files;
    const listFileName = files.map((item: any) => item.name);
    if (this.filesUpload?.length > 1) {
      this.validateDocumentNameMultiple(listFileName);
      this.editForm.get('documentName')?.removeValidators(Validators.required);
      this.editForm.get('documentName')?.clearValidators();
      this.editForm.get('documentName')?.updateValueAndValidity();
    } else {
      this.editForm.get('documentName')?.addValidators(Validators.required);
    }
    if (this.filesUpload) {
      this.uploadDate = new Date();
      this.isShowMessageErrorFilesControl = false;
    }
  }

  deleteFile() {
    if (!this.data.infoForm?.isUploadMultipleFile) {
      this.isUnsupportedFileType = false;
      this.filesUpload = [];
    }
    if (this.filesUpload.length === 1 && this.data.infoForm?.isUploadMultipleFile) {
      this.editForm.get('documentName')?.reset();
    }
  }

  validateDocumentNameMultiple(listFileName: string[]): any {
    if (this.data.currentEntity.entityType === EntityType.Participant) {
      this.memberDocumentService.checkExistDocumentNameMultiple(
        this.data.currentEntity.entityId ?? '',
        listFileName
      ).subscribe((res) => {
        if (res.existedDocumentNames?.length) {
          this.uploadMultipleComponent.filesSelected.forEach((item) => {
            item['isExist'] = res.existedDocumentNames?.includes(item.name.toLowerCase());
          })
          this.editForm.get('documentName')?.setErrors({ existDocumentName: 'Document Name already exists' });
        }
      })

    } else if (this.data.currentEntity.entityType === EntityType.Employer) {
      this.employerDocumentService.checkExistDocumentNameMultiple(
        this.data.currentEntity.entityId ?? '',
        listFileName
      ).subscribe((res) => {
        if (res.existedDocumentNames?.length) {
          this.uploadMultipleComponent.filesSelected.forEach((item) => {
            item['isExist'] = res.existedDocumentNames?.includes(item.name.toLowerCase());
          })
          this.editForm.get('documentName')?.setErrors({ existDocumentName: 'Document Name already exists' });
        }
      })
    }
  }

  onChangeTags() {
  }

  tagsFocus(event: any) {
    event.stopPropagation();
    this.currentTagsChipValue = deepClone(this.tagsAutoCompleteControl?.value);
    this.filteredByTypingTagOptions = this.tagsAutoCompleteControl.valueChanges.pipe(
      startWith(''),
      map((value) =>
        this.listOptionTag.filter(item =>
          item?.displayValue?.toLowerCase()?.includes((event.target.value as string).toLowerCase()) &&
          !this.currentTagsChipValueList?.includes(item?.value),
        )));
  }

  displayTagFn(value: Option): string {
    return value ? value.displayValue : '';
  }

  validateTags(): void {
    if (this.tagsAutoCompleteControl.value) {
      if (
        this.tagsAutoCompleteControl.enabled &&
        typeof this.tagsAutoCompleteControl.value === 'string' ||
        this.currentTagsChipValueList?.includes(this.tagsAutoCompleteControl.value.value)
      ) {
        this.tagsAutoCompleteControl.setErrors({ inValidAsync: true });
        return;
      }
    }
  }

  addNewTags() {
    if (this.editForm.get('isNewDocument')?.value === false) {
      return;
    }
    // call add tag component
    const dialogRef = this.dialog.open(AddTagComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: true,
      data: {
        tag: null,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.isSuccess) {
        this.getTagList();
      }
    });
  }

  onAddNewChip() {
    if (!this.tagsAutoCompleteControl.value ||
      this.tagsAutoCompleteControl.invalid) {
      this.tagsAutoCompleteControl.markAsTouched();
      return;
    }

    const chip = this.currentTagsChipValue;
    this.tagsAutoCompleteControl.reset('');
    const chipControl = new FormControl(chip);

    this.tagsChipListControl.push(chipControl);

    if (!this.currentTagsChipValueList.includes(chip.value)) {
      this.currentTagsChipValueList.push(chip.value);
    }
  }

  onRemoveChip(value: string, index: number) {
    if (!value) return;
    this.currentTagsChipValueList.splice(index, 1);
    this.tagsChipListControl.removeAt(index);
  }

  onSubmit(): void {
    // No Save when upload a wrong data type file (now just implemented for Upload Single file case yet)
    if (this.isUnsupportedFileType &&
      !this.data?.infoForm?.isUploadMultipleFile &&
      !this.checkPattern.test(this.fileDocument.controlField.value)
      ) {
      return;
    }

    // No Save in case Upload Single file (by Upload Multiple component) and dont have
    // @-> (Disability/Refund Detail or Retirement Benefit Overview or Accident Detail or Attachment screens -> Upload file)
    if (!this.isShowExistDocumentRadio && this.existDocumentType && !this.filesUpload.length && !this.data.document) {
      return;
    }

    // No Save in case have Exist Document radio with a table of file but table length = 0
    // @-> (Disability/Refund Calculation Detail or Retirement Benefit Detail screens -> Upload file)
    if (this.isShowExistDocumentRadio && !this.documentList.length) {
      this.isShowErrorMessage = true;
      return;
    }

    // No Save in case Upload Multiple Document has error or duplicated
    if (!!this.fileDocument && (this.fileDocument.hasError || this.fileDocument.isDuplicated)) {
      return;
    }

    // pass obj form
    const objUpload = this.editForm.value;
    objUpload['tags'] = this.currentTagsChipValueList;
    objUpload['files'] = this.filesUpload;
    objUpload['uploadDate'] = this.uploadDate;
    objUpload['documentList'] = this.documentList;
    objUpload['existDocumentRadio'] = this.editForm.get('isNewDocument')?.value;
    objUpload['documentId'] = this.editForm.get('documentId')?.value;
    objUpload['existingDocumentType'] = this.editForm.get('existingDocumentType')?.value;
    objUpload['attachmentFile'] = this.attachmentFile;
    // upload form
    if (!this.data.document && this.editForm.get('isNewDocument')?.value === null) {
      // Require upload file
      if (!this.filesUpload || this.filesUpload.length === 0) {
        this.isShowMessageErrorFilesControl = true;
        return;
      }
    }

    // update list file upload
    this.dialogRef.close(objUpload);
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  resetForm(isNewDocument: boolean) {
    // @-> US #102905, default value of Show On Overview checkbox should be Checked in case New Document,
    // or dont have Existing Document / New Document Radio
    // Applied for all Upload document screens
    this.editForm.get('showOnOverview')?.reset(isNewDocument);

    this.editForm.get('description')?.reset('');
    this.tagsAutoCompleteControl.reset('');
    this.tagsChipListControl.clear();
    this.currentTagsChipValueList = [];
    this.editForm.get('documentId')?.reset('');
    this.editForm.get('type')?.reset('');
    this.editForm.get('documentName')?.reset('');
    this.editForm.get('existingDocumentType')?.reset('');
    this.singleFileUploadControl?.setValue(null);
    this.singleFileUploadControl?.reset('');
    this.isShowErrorMessage = false;
  }

  onChangeExistDocumentRadio($event: boolean) {
    if ($event === false) { // Existing Document
      this.tagsAutoCompleteControl.disable();
      this.editForm.get('description')?.disable();

      this.editForm.get('documentId')?.addValidators([
        this.checkDocumentNameDropdownRequireValidator()
      ]);

      this.editForm.get('documentName')?.removeValidators([
        this.checkDocumentNameTextboxDuplicateAndRequireValidator(),
      ]);
      this.editForm.get('documentName')?.removeValidators([
        this.checkDocumentNameTextboxDuplicateAndRequireValidator(),
      ]);
      this.editForm.get('type')?.removeValidators([
        this.checkDocumentTypeDropdownRequireValidator(),
      ]);
      this.editForm.get('singleFileUpload')?.removeValidators([
        this.checkSingleUploadFileRequireValidator(),
      ]);
      this.editForm.get('documentName')?.clearValidators();
      this.editForm.get('type')?.clearValidators();
      this.editForm.get('singleFileUpload')?.clearValidators();
    } else { // New Document
      this.tagsAutoCompleteControl.enable();
      this.editForm.get('description')?.enable();

      this.editForm.get('documentName')?.addValidators([
        this.checkDocumentNameTextboxDuplicateAndRequireValidator(),
      ]);
      this.editForm.get('type')?.addValidators([
        this.checkDocumentTypeDropdownRequireValidator(),
      ]);
      this.editForm.get('singleFileUpload')?.addValidators([
        this.checkSingleUploadFileRequireValidator(),
      ]);

      this.editForm.get('documentId')?.removeValidators([
        this.checkDocumentNameDropdownRequireValidator()
      ]);
      this.editForm.get('documentId')?.clearValidators();
    }
    this.resetForm($event);
  }

  onChangeDocumentName() {
    const documentNameId = this.editForm.get('documentId')?.value;
    this.selectedDocument = this.benefitDocuments.find((document) => document.id === documentNameId) || {};
    this.editForm.get('existingDocumentType')?.setValue(this.selectedDocument?.documentTypeName);
    this.tagsChipListControl.clear();
    this.currentTagsChipValueList = [];
    if (this.selectedDocument?.tags?.length) {
      this.selectedDocument?.tags.forEach((item: any) => {
        const chipControl = new FormControl({ value: item?.id, displayValue: item?.name});
        this.tagsChipListControl.push(chipControl);
        this.currentTagsChipValueList.push(item?.id);
      })
    }
    this.editForm.get('description')?.setValue(this.selectedDocument?.description ?? '');
    this.editForm.get('showOnOverview')?.setValue(this.selectedDocument?.showOnOverview ?? false);
  }

  addExistingDocument() {
    this.editForm.get('documentId')?.markAllAsTouched();
    if (this.isFieldValueEmpty(this.editForm.get('documentId')?.value)) {
      return;
    }
    const cloneSelectedDocument = deepClone(this.selectedDocument);

    const document = {
      ...cloneSelectedDocument,
      fileName: cloneSelectedDocument.fileName,
      type: cloneSelectedDocument.documentType,
      index: new Date().getTime(),
      uploadDate: new Date(),
      tags: this.currentTagsChipValueList,
      description: this.editForm.get('description')?.value,
      showOnOverview: this.editForm.get('showOnOverview')?.value,
    };
    this.documentList = [document, ...this.documentList];
    this.selectedDocument = null;
    this.editForm.get('documentId')?.reset();
    this.editForm.get('existingDocumentType')?.reset();
    this.filterExistingDocument();
    this.getFilteredDocuments();
    this.tagsChipListControl.clear();
    this.currentTagsChipValueList = [];
    this.editForm.get('description')?.reset();
    this.editForm.get('showOnOverview')?.reset(false);
  }

  isFieldValueEmpty(value: any) {
    return typeof value === 'undefined' || value === null || value === '';
  }

  checkDocumentNameTextboxDuplicateAndRequireValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.editForm?.get('isNewDocument') && this.editForm?.get('isNewDocument')?.value === true) {
        // Duplicated record -> duplicated error
        const selectedDocument = this.documentList.findIndex((document) => document.id === control.value);
        if (selectedDocument !== -1) {
          return { documentExisted: 'Document Name already exists.' };
        }
        // Have at least 1 record, Single Upload file must not have value -> not require
        if (this.documentList.length && this.isFieldValueEmpty(this.editForm.get('singleFileUpload')?.value)) {
          return null;
        }
        // Have at least 1 record, Single Upload file have value -> require error
        if (this.documentList.length && !this.isFieldValueEmpty(this.editForm.get('singleFileUpload')?.value) && this.isFieldValueEmpty(control?.value)) {
          return { required: true };
        }
        // Dont have record -> require error
        if (!this.documentList.length && this.isFieldValueEmpty(control.value)) {
          return { required: true };
        }
      }
      return null;
    };
  }

  checkDocumentNameDropdownRequireValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.editForm?.get('isNewDocument') && this.editForm?.get('isNewDocument')?.value === false) {
        // Have at least 1 record -> not require
        if (this.documentList.length) {
          return null;
        }
        // Dont have record -> require error
        if (!this.documentList.length && this.isFieldValueEmpty(control.value)) {
          return { required: true };
        }
      }
      return null;
    };
  }

  checkDocumentTypeDropdownRequireValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.editForm?.get('isNewDocument') && this.editForm?.get('isNewDocument')?.value === true) {
        // Have at least 1 record, Single Upload file must not have value -> not require
        if (this.documentList.length && this.isFieldValueEmpty(this.editForm.get('singleFileUpload')?.value)) {
          return null;
        }
        // Have at least 1 record, Single Upload file have value -> require error
        if (this.documentList.length && !this.isFieldValueEmpty(this.editForm.get('singleFileUpload')?.value) && this.isFieldValueEmpty(control?.value)) {
          return { required: true };
        }
        // Dont have record -> require error
        if (!this.documentList.length && this.isFieldValueEmpty(control.value)) {
          return { required: true };
        }
      }
      return null;
    };
  }

  checkSingleUploadFileRequireValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.editForm?.get('isNewDocument') && this.editForm?.get('isNewDocument')?.value === true) {
        // Have at least 1 record -> not require
        if (this.documentList.length) {
          return null;
        }
        // Dont have record -> require error
        if (!this.documentList.length && this.isFieldValueEmpty(control.value)) {
          return { required: true };
        }
      }
      return null;
    };
  }

  onSortDocumentsTable(sort: Sort) {
    this.sortInfo = sort;
    this.getFilteredDocuments();
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    this.getFilteredDocuments();
  }

  downloadSelectedFile(row: any) {
    // For downloading file on server
    if (row?.id) {
      this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
      this.documentStore.dispatch(
        getDocumentDownloadAction({ fileId: row?.fileId as string, fileName: row.fileName as string }),
      );
      this.downloadFileName = row?.fileName;
      return;
    }

    // For downloading file that recently upload to table
    if (this.singleFile) downloadFile.call(this, this.singleFile, this.singleFile?.name);
  }

  removeDocument(row: any) {
    this.documentList.splice(this.documentList.findIndex((document) => document === row));
    this.filterExistingDocument();
    this.getFilteredDocuments();
  }

  getFilteredDocuments() {
    this.filteredDocuments = [...this.documentList].sort((a: any, b: any) => {
      if (!this.sortInfo?.direction || !this.sortInfo?.active) {
        if (
          DateTime.fromISO(a.uploadDate.toString()).startOf('day') <=
          DateTime.fromISO(b.uploadDate.toString()).startOf('day')
        ) {
          return -1;
        }
      }

      let ascendingSort = this.sortInfo?.direction === 'asc' ? 1 : -1;

      if (this.sortInfo?.active === 'uploadDate') {
        const currentDate = DateTime.fromISO(a.uploadDate).startOf('day');
        const nextDate = DateTime.fromISO(b.uploadDate).startOf('day');
        return (currentDate <= nextDate ? -1 : 1) * ascendingSort;
      }
      const currentItem = (a as any)?.[this.sortInfo?.active] ?? '';
      const nextItem = (b as any)?.[this.sortInfo?.active] ?? '';
      return localeCompare(currentItem, nextItem) * ascendingSort;
    }).slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
  }

  filterExistingDocument() {
    const existDocumentList = this.benefitDocuments
      .filter((document) => {
        return !this.detailBenefitDocument?.some((detailDocument) => detailDocument.id === document.id);
      })
      .filter((document) => {
        return !this.documentList.find((item) => item.id === document.id);
      });

    this.existDocumentDropdownOptionList = existDocumentList
      .map((doc) => {
        return {
          value: doc.id,
          displayValue: doc.documentName,
        };
      })
      .sort((a, b) => a.displayValue.localeCompare(b.displayValue));
  }

  validationBeforeSave() {
    const controls = this.editForm.controls;
    for (const control in controls) {
      if (controls[control].invalid) {
        if (control === 'singleFileUpload') {
          this.fileDocumentSingleUpload.firstTouch = false;
          this.fileDocumentSingleUpload.fileSelected = false;
        } else {
          this.editForm.get(control)?.markAllAsTouched();
        }
      }
    }
  }

  uploadSingleFile(selectedFile?: File) {
    if (!selectedFile) {
      this.singleFileUploadControl.markAsTouched();
    }
    if (this.editForm.get('isNewDocument')?.value === true && this.isShowExistDocumentRadio) {
      if (this.isFieldValueEmpty(this.editForm.get('documentName')?.value) || this.isFieldValueEmpty(this.editForm.get('type')?.value)) {
        if (this.editForm.get('singleFileUpload')?.invalid) {
          this.fileDocumentSingleUpload.firstTouch = false;
          this.fileDocumentSingleUpload.fileSelected = false;
        }
        setTimeout(() => {
          this.editForm.get('documentName')?.updateValueAndValidity();
          this.editForm.get('documentName')?.markAsTouched();
          this.editForm.get('type')?.updateValueAndValidity();
          this.editForm.get('type')?.markAsTouched();
        }, 0);
        return;
      }

      const existDocumentName = this.documentList.findIndex(
        (document) => document.documentName?.localeCompare(this.editForm.get('documentName')?.value) === 0,
      );
      if (existDocumentName !== -1) {
        this.editForm.get('documentName')?.setErrors({ duplicated: true });
        return;
      }

      this.singleFile = selectedFile;

      const document = {
        uploadDate: new Date(),
        documentName: this.editForm.get('documentName')?.value,
        type: this.editForm.get('type')?.value,
        fileName: selectedFile?.name,
        file: selectedFile,
        documentTypeName: CalculationDocumentType[this.editForm.get('type')?.value],
        tags: this.currentTagsChipValueList,
        decription: this.editForm.get('description')?.value,
        showOnOverview: this.editForm.get('showOnOverview')?.value,
        index: new Date().getTime(),
      };

      this.documentList = [document, ...this.documentList];
      this.getFilteredDocuments();
      if (this.singleFile) {
        this.fileDocumentSingleUpload.fileSelected = null;
        this.fileDocumentSingleUpload.isDuplicated = false;
        this.fileDocumentSingleUpload.firstTouch = true;
        this.fileDocumentSingleUpload.inputFile.nativeElement.value = '';
        this.singleFileUploadControl?.setValue(null);
        this.singleFileUploadControl?.reset('');
        this.resetForm(false);
      }
    }
  }

  deleteSingleUploadFile(event: any) {
    setTimeout(() => {
      this.editForm.get('documentName')?.updateValueAndValidity();
      this.editForm.get('documentName')?.markAsTouched();
      this.editForm.get('type')?.updateValueAndValidity();
      this.editForm.get('type')?.markAsTouched();
    }, 0);
  }

  private setValueByType() {
    switch (this.usedForMenu) {
      case USED_FOR_MENU.RETIREMENT_BENEFIT_DETAIL:
        this.notFoundMessage = 'No data to Display';
        this.documentTableTitle = 'Retirement Benefit Documents';
        break;
      case USED_FOR_MENU.REFUND_CALCULATION_DETAIL:
        this.notFoundMessage = 'No Refund Documents to Display';
        this.documentTableTitle = 'Refund Documents';
        break;
      case USED_FOR_MENU.DISABILITY_CALCULATION_DETAIL:
        this.notFoundMessage = 'No Disability Documents to Display';
        this.documentTableTitle = 'Disability Documents';
        break;
      case USED_FOR_MENU.LODD_CALCULATION_DETAIL:
        this.notFoundMessage = 'No LODD Documents to Display';
        this.documentTableTitle = 'LODD Documents';
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  uploadFileForAttachmentScreen(event: any) {
    this.attachmentFile = event;
    this.memberDetailService
      .checkMemberEntityAttachmentExists({
        memberId: this.data?.specificMenuData?.memberId,
        targetId: this.data?.specificMenuData?.recordId,
        name: event.name,
      })
      .subscribe(
        (res: any) => {
          if (res && res.exists) {
            this.fileDocument.isDuplicated = true;
            this.errDuplicated = `${event.name} (${event.size} bytes) already exists. Please select another file.`;
          } else {
            this.fileDocument.isDuplicated = false;
          }
        },
        (error) => {
          this.fileDocument.isDuplicated = false;
        }
      );
  }

  selectorSelectedDownloadFile() {
    this.documentStore
      .select(getRetirementBenefitDownloadDocumentSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((downloadDocument) => {
        if (!downloadDocument?.isLoading && downloadDocument?.success) {
          let blobFile = downloadDocument?.payload ? downloadDocument?.payload[0] : new Blob();
          downloadFile.call(this, blobFile, this.downloadFileName);

          this.documentStore.dispatch(clearGetRetirementBenefitDownloadDocumentStateAction());
        }
      });
  }

  // @-> Use this workaround to allow form can be save in case Existing Document
  onSaveBtnClicked() {
    if (this.isShowExistDocumentRadio && this.editForm.get('isNewDocument')?.value === false && this.documentList?.length) {
      this.editForm.get('type')?.updateValueAndValidity();
      this.editForm.get('documentName')?.updateValueAndValidity();
      this.editForm.get('singleFileUpload')?.updateValueAndValidity();
    }
    this.formSubmit$.next();
  }

  getCurrentFundData() {
    combineLatest([
      this.store.select(fromReducer.selectCurrentFundState),
    ])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([currentFund]) => {
      this.pageSize = currentFund?.defaultPageSize ?? DEFAULT_PAGE_SIZE;
    });
  }
}
