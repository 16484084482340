import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { FundModel } from '@ptg-fund-list/models/fund-list.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { DateTime } from 'luxon';
import { take } from 'rxjs/operators';
import { ACTION, CANCEL_CONFIRM_MESSAGE, SortType, STATE } from '../constance/value.const';
import { isEmpty } from './string.util';

export function getBannerMessage(action: string, state: string, propertyName: string) {
  if (!state) {
    return '';
  }
  let actionType = '';
  switch (action) {
    case ACTION.ADD:
      actionType = state === STATE.SUCCESS ? 'added' : 'adding';
      break;
    case ACTION.EDIT:
      actionType = state === STATE.SUCCESS ? 'updated' : 'updating';
      break;
    case ACTION.REMOVE:
      actionType = state === STATE.SUCCESS ? 'removed' : 'removing';
      break;
    case ACTION.UPLOAD:
      actionType = state === STATE.SUCCESS ? 'uploaded' : 'uploading';
      break;
    case ACTION.LOCK:
      actionType = state === STATE.SUCCESS ? 'locked' : 'locking';
      break;
    case ACTION.UNLOCK:
      actionType = state === STATE.SUCCESS ? 'unlocked' : 'unlocking';
      break;
    case ACTION.SAVE:
      actionType = state === STATE.SUCCESS ? 'saved' : 'saving';
      break;
    case ACTION.RESET:
      actionType = state === STATE.SUCCESS ? 'reset' : 'resetting';
      break;
    case ACTION.CANCEL:
      actionType = state === STATE.SUCCESS ? 'canceled' : 'cancelling';
      break;
    case ACTION.SCHEDULED:
      actionType = state === STATE.SUCCESS ? 'scheduled' : 'scheduling';
      break;
    case ACTION.REJECT:
      actionType = state === STATE.SUCCESS ? 'rejected' : 'rejecting';
      break;
    case ACTION.COMPUTE:
      actionType = state === STATE.SUCCESS ? 'computed' : 'computing';
      break;
    case ACTION.COMPLETE:
      actionType = state === STATE.SUCCESS ? 'completed' : 'completing';
      break;
    case ACTION.APPROVE:
      actionType = state === STATE.SUCCESS ? 'approved' : 'approving';
      break;
    case ACTION.DOWNLOAD:
      actionType = state === STATE.SUCCESS ? 'downloaded' : 'downloading';
      break;
    case ACTION.GENERATE:
      actionType = state === STATE.SUCCESS ? 'generated' : 'generating';
      break;
    case ACTION.ATTACH:
      actionType = state === STATE.SUCCESS ? 'attached' : 'attaching';
      break;
    case ACTION.APPLY:
      actionType = state === STATE.SUCCESS ? 'applied' : 'applying';
      break;
  }
  if (state === STATE.SUCCESS) {
    return `${propertyName} successfully ${actionType}.`;
  }
  if (state === STATE.FAIL) {
    return `Error occurred ${actionType} ${propertyName}. Please try again.`;
  }
  return '';
}

export function showBanner(
  state: string,
  propertyName: string,
  action: string,
  options: { customMessage: string } = { customMessage: '' },
) {
  if (!state) {
    return;
  }
  // @ts-ignore
  this.bannerType = state as BannerType;
  if (options?.customMessage) {
    // @ts-ignore
    this.message = options.customMessage;
    return;
  }
  // @ts-ignore
  this.message = getBannerMessage(action, state, propertyName);
}

export function checkErrorDuplicate(listParameter: FormArray, propertyName: string) {
  let list = listParameter.getRawValue();
  listParameter.controls.forEach((control) => {
    if (control.get(propertyName)?.hasError('duplicated')) {
      let parameter = control.get(propertyName)?.value?.toLowerCase();
      let x = 0;
      list?.forEach((el: any) => {
        if (parameter && el.parameter?.toLowerCase().replace(/\s+/, ' ') === parameter.replace(/\s+/, ' ')) {
          x += 1;
        }
      });
      if (x === 1) {
        control.get(propertyName)?.setErrors(null);
        control.get(propertyName)?.markAsTouched();
      }
    }
  });
}

export function deepClone<T>(value: T): T {
  if (value === undefined || value === null) {
    return value as T;
  }
  return JSON.parse(JSON.stringify(value)) as T;
}

export function downloadFile(res: Blob, fileName: string) {
  const url = window.URL.createObjectURL(res);
  const anchor = document.createElement('a');
  anchor.download = fileName;
  anchor.href = url;
  anchor.click();
}

export function reorderItems(items: any[], reorderItemId?: string | number, upperAdjacentId?: string | number) {
  let tmp = JSON.parse(JSON.stringify(items));
  let itemUpperAdjacent: any;
  let itemReorderItem: any;
  if (upperAdjacentId) {
    itemUpperAdjacent = items.filter((item: any) => item.id === upperAdjacentId)[0];
  }
  itemReorderItem = items.filter((item: any) => item.id === reorderItemId)[0];
  tmp.forEach((item: any) => {
    if (item.id === reorderItemId) {
      item.order = itemUpperAdjacent?.id
        ? itemReorderItem.order < itemUpperAdjacent.order
          ? itemUpperAdjacent.order
          : itemUpperAdjacent.order + 1
        : 1;
    } else if (itemUpperAdjacent?.id) {
      if (itemReorderItem.order < itemUpperAdjacent.order) {
        if (item.order > itemReorderItem.order && item.order < itemUpperAdjacent.order + 1) {
          item.order = item.order - 1;
        }
      } else {
        if (item.order < itemReorderItem.order + 1 && item.order > itemUpperAdjacent.order) {
          item.order = item.order + 1;
        }
      }
    } else {
      if (item.order < itemReorderItem.order) {
        item.order = item.order + 1;
      }
    }
  });
  tmp = tmp.sort((a: any, b: any) => {
    if (a.order > b.order) {
      return 1;
    }
    return -1;
  });
  return tmp;
}

export function lowercaseFirstCharacterKeys(object: Record<string, any>): Record<string, any> {
  return Object.keys(object).reduce((result: Record<string, any>, key) => {
    result[key[0].toLowerCase() + key.substring(1)] = object[key];
    return result;
  }, {});
}

export function toUtcDate(dateTime: DateTime) {
  return DateTime.utc(
    dateTime.year,
    dateTime.month,
    dateTime.day,
    dateTime.hour,
    dateTime.minute,
    dateTime.second,
    dateTime.millisecond,
  );
}

export function formatUtcDateString(value: string, format: string = 'MM/dd/yyyy') {
  return DateTime.fromISO(value, { zone: 'utc' }).toFormat(format);
}

export function parseDateToSubmit(value: string | Date, format?: string) {
  let date = value;
  if (!isValidDate(date)) {
    date = new Date(date);
  }
  const dateWithoutTime = DateTime.fromJSDate(date as Date, { zone: 'local' }).startOf('day');
  if (format) {
    return dateWithoutTime.toFormat(format);
  }
  return dateWithoutTime.toFormat("yyyy-MM-dd'T'HH:mm:ss.000'Z'");
}

export function isValidDate(date: any) {
  return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
}

export function toTimeZoneLocal(dateTime: Date) {
  return new Date(dateTime.setMinutes(dateTime.getMinutes() - new Date().getTimezoneOffset()));
}

export function isNumeric(value: number | string) {
  return !isEmpty(value) && value !== '' && !isNaN(Number(value));
}

export function isObjectEmpty(value: any) {
  return isEmpty(value) || Object.values(value).every((item) => isEmpty(item));
}

export function deepEqual(x: any, y: any): boolean {
  if (x && y && typeof x === 'object' && typeof y === 'object') {
    if (Object.keys(x).length !== Object.keys(y).length) {
      return false;
    }
    return Object.keys(x).every((key, index) => {
      return deepEqual(x[key], y[key]);
    });
  }
  return x === y;
}

export function deepFlattenArray<T>(sourceArray: Array<T>, childrenKey: string = 'children') {
  let result: T[] = [];
  sourceArray.forEach((item: any) => {
    result.push(item);
    if (Array.isArray(item?.[childrenKey])) {
      result = result.concat(deepFlattenArray(item?.[childrenKey], childrenKey));
    }
  });
  return result;
}

export function toCamelCase(key: any, value: any) {
  if (value && typeof value === 'object') {
    for (var k in value) {
      if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
        value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
        delete value[k];
      }
    }
  }
  return value;
}

export function checkExistNestedKeyValue(
  sourceArray: any[],
  values: string[],
  keys: { parentKey: string; childKey: string } = { parentKey: 'Key', childKey: 'Child' },
): boolean {
  const existItem = sourceArray.find((item) => item[keys.parentKey] === values[0]);
  if (!existItem) {
    return false;
  }
  if (!deepClone(values).slice(1).length) {
    return true;
  }
  return checkExistNestedKeyValue(existItem[keys.childKey], deepClone(values).slice(1));
}

export function formatCurrencyUtil(amount: number): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(amount);
}

export function showCancelDialog(
  dialog: MatDialog,
  dialogRef: MatDialogRef<any>,
  text: string = CANCEL_CONFIRM_MESSAGE,
  callback?: () => void,
) {
  const newDialogRef = dialog.open(ConfirmPopupComponent, {
    panelClass: 'confirm-popup',
    data: {
      text,
      type: ConfirmType.Cancel,
    },
  });

  newDialogRef
    .afterClosed()
    .pipe(take(1))
    .subscribe((result: any) => {
      if (result) {
        dialogRef.close();
        if (callback) {
          callback();
        }
      }
    });
}

export function getCurrentFundData(fundModel: FundModel) {
  return {
    currentFund: {
      name: fundModel.name,
      id: fundModel.id,
      key: fundModel.key,
      active: fundModel.active,
      supportPhone: fundModel.supportPhone,
      fundType: fundModel.fundType,
      modules: fundModel.modules,
      defaultPageSize: fundModel.defaultPageSize,
      stripeAccountId: fundModel.stripeAccountId,
      email: fundModel.email,
      country: fundModel.country,
    },
  };
}

export function cloneAbstractControl<T extends AbstractControl>(control: T): T {
  let newControl: T;

  if (control instanceof FormGroup) {
    const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
    const controls = control.controls;
    Object.keys(controls).forEach((key) => {
      formGroup.addControl(key, cloneAbstractControl(controls[key]));
    });
    newControl = formGroup as any;
  } else if (control instanceof FormArray) {
    const formArray = new FormArray([], control.validator, control.asyncValidator);

    control.controls.forEach((formControl) => formArray.push(cloneAbstractControl(formControl)));

    newControl = formArray as any;
  } else if (control instanceof FormControl) {
    newControl = new FormControl(control.value, control.validator, control.asyncValidator) as any;
  } else {
    throw new Error('Error: unexpected control value');
  }

  if (control.disabled) {
    newControl.disable({ emitEvent: false });
  }

  return newControl;
}

export function openFile(file: Blob | File) {
  const url = window.URL.createObjectURL(file);
  window.open(url, '_blank', '');
}

export function generateActionName(key: string) {
  return {
    Send: `[${key}] Send Request`,
    Success: `[${key}] Success`,
    Failure: `[${key}] Failure`,
    Clear: `[${key}] Clear`,
  };
}

export function getSortType(sortInfo: Sort) {
  return sortInfo?.direction === 'asc' ? SortType.ASC : SortType.DESC;
}

export function convertBase64ToBlob(base64String: string, contentType: string): Blob {
  // Step 1: Convert Base64 to Uint8Array
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const uint8Array = new Uint8Array(byteNumbers);

  // Step 2: Create Blob from Uint8Array
  return new Blob([uint8Array], { type: contentType });
}
