import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { EntityState } from '@ptg-entity-management/store/reducers';
import { BaseComponent } from '@ptg-shared/components';
import { Column, ColumnType } from '@ptg-shared/controls/grid';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { deepClone } from '@ptg-shared/utils/common.util';
import {
  CalculationBenefitDetails,
  CalculationDeathLoddPayeeInformation,
  CalculationSurvivorLoddPayeeInformation,
} from '../../services/models';
import { CalculationType } from '../../types/enums';

const loddSurvivorColumns: Column[] = [
  {
    name: 'Name',
    header: {
      title: 'Name',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'Relationship',
    header: {
      title: 'Relationship',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'DateOfBirth',
    type: ColumnType.DateTime,
    header: {
      title: 'Date Of Birth',
    },
    templateArgs: {
      format: 'MM/dd/yyyy',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'Age',
    header: {
      title: 'Age',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'Amount',
    header: {
      title: 'Amount',
    },
    type: ColumnType.Decimal,
    templateArgs: { decimal: 2, unit: '$', unitPosition: 'left' },
    truncate: true,
    sortable: true,
  },
];

const loddDeathColumns: Column[] = [
  {
    name: 'Name',
    header: {
      title: 'Name',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'Relationship',
    header: {
      title: 'Relationship',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'DateOfBirth',
    header: {
      title: 'Date Of Birth',
    },
    type: ColumnType.DateTime,
    templateArgs: {
      format: 'MM/dd/yyyy',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'Percentage',
    header: {
      title: 'Percentage',
    },
    type: ColumnType.Decimal,
    templateArgs: {
      decimal: 0,
      unit: '%',
      unitPosition: 'right'
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'IsPrimary',
    header: {
      title: 'Is Primary',
    },
    type: ColumnType.Binary,
    templateArgs: {
      affirmative: 'Yes',
      negative: 'No',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'Amount',
    header: {
      title: 'Amount',
    },
    type: ColumnType.Decimal,
    templateArgs: { decimal: 2, unit: '$', unitPosition: 'left' },
    truncate: true,
    sortable: true,
  },
];

@Component({
  selector: 'ptg-lodd-payee-information-table',
  templateUrl: './lodd-payee-information-table.component.html',
  styleUrls: ['./lodd-payee-information-table.component.scss'],
})
export class LoddPayeeInformationTableComponent extends BaseComponent {
  @Input() title!: string;
  @Input() properties!: any[];
  @Input() calculationType!: number;

  payeeInformationColumns: Column[] = [];
  payeeInformationList: CalculationSurvivorLoddPayeeInformation[] | CalculationDeathLoddPayeeInformation[] = [];

  constructor(
    public readonly route: ActivatedRoute,
    public readonly layoutService: LayoutService,
    public entityStore: Store<EntityState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData(): void {
    const payeeValues = this.properties.map((item: CalculationBenefitDetails) => {
      const parseValue = JSON.parse(item.value);
      return {
        ...parseValue,
        DateOfBirth: parseValue.DateOfBirth !== null ? new Date(parseValue.DateOfBirth) : '',
      };
    });
    this.payeeInformationList = deepClone(payeeValues);
    this.payeeInformationColumns =
      this.calculationType === CalculationType.LODDSurvivor ? loddSurvivorColumns : loddDeathColumns;
  }
}
