<div class="member-detail-display" [ngClass]="{'section-non-list': !isList}">
    <div class="detail-header">
      <span class="title truncate">{{ isList ? 'Selected ' : '' }}{{ menuItemName }} </span>
      <ng-container>
        <div class="edit-btn" *ngIf="!(!currentRow.canEdit || (isBVFFFund && currentRow.values[0].entityComponentId === NOTES_CARD.Id))" (click)="edit()" [ngClass]="{'disabled': isLoading}">
          <mat-icon>edit</mat-icon>
          <span class="title-edit">Edit</span>
        </div>
        <div class="edit-btn" *ngIf="!(!currentRow.canRemove || currentRow.values[0].entityComponentId === NOTES_CARD.Id)" (click)="remove()" [ngClass]="{'disabled': isLoading}">
          <mat-icon>delete_forever</mat-icon>
          <span class="title-edit">Remove</span>
        </div>
      </ng-container>
    </div>
    <div class="detail-content">
      <ng-container *ngFor="let item of listValue">
        <div class="first-row"
          [ngClass]="{
            'column4': attachmentType === IncludeAttachmentType.CardLevel ||
              attachmentType === IncludeAttachmentType.NoAttachment}"
          [hidden]="(item?.configs.fixedKey === FixedPropertyKey.RelationShip || item?.configs.fixedKey === FixedPropertyKey.RejectReason) && !item.value"
          >
          <div class="item">
            <label>{{item?.title }}</label>
            <p class="value">
              <ng-container [ngSwitch]="item.type">
                <span *ngSwitchCase="EntityPropertyType.Text">{{ (item.label ?? item.value) || '-' }}</span>
                <span *ngSwitchCase="EntityPropertyType.Currency">{{ (item.value | numberLocalDecimal:'$') || '-' }}</span>
                <span *ngSwitchCase="EntityPropertyType.Date">{{ item.value || '-' }}</span>
                <span *ngSwitchCase="EntityPropertyType['Person Name']">{{ (item.value | personName : item.options) || '-' }}</span>
                <div class="value w-100" *ngSwitchCase="EntityPropertyType.Address">
                  <ptg-view-address *ngIf="item.value"
                    [value]="item?.value"
                    [options]="item.options"
                  ></ptg-view-address>
                  <span *ngIf="!item?.value">{{ '-' }}</span>
                </div>
                <span *ngSwitchCase="EntityPropertyType.Decimal">
                  <ptg-number-decimal-column [value]="item.value" [decimal]="
                                  item?.configs?.fractionalLengthInput || item?.configs?.fractionalLengthInput === 0
                                      ? item?.configs?.fractionalLengthInput
                                      : 2
                                  "></ptg-number-decimal-column>
                </span>
                <span *ngSwitchCase="EntityPropertyType['Whole Number']">{{ (item.value | numberDecimal) || '-' }}</span>
                <span *ngSwitchCase="EntityPropertyType.Lookup">{{ item.value || '-' }}</span>
                <span class="value truncate" *ngSwitchCase="EntityPropertyType.Binary">{{
                  item?.value === null || item?.value === undefined
                    ? "-"
                    : item?.value
                    ? item?.configs?.affirmative
                    : item?.configs?.negative
                }}</span>
                <span *ngSwitchCase="EntityPropertyType.Percentage">{{(item.value | numberLocalDecimal:'') || '-'}}{{(item.value | numberLocalDecimal:'') ? '%' : ''}}</span>
                <span *ngSwitchCase="EntityPropertyType.Employer">{{ item.value || '-' }}</span>
                <span *ngSwitchCase="EntityPropertyType.Tier">{{ item.value || '-' }}</span>
                <span class="value" *ngSwitchCase="EntityPropertyType.Email">
                  {{ (item.visibilityOff ? item?.value : item?.valueUnMasked) || '-' }}
                </span>
                <span *ngSwitchCase="EntityPropertyType['Entity Reference']">
                  <span *ngIf="item?.value; else entityReferenceEmptyValue">
                    <a
                    *ngIf="item.displayFieldType !== EntityPropertyType.Address"
                    (click)="_onClickEntityReference(item)"
                    href="javascript:void(0)"
                    style="
                      color: #408BF9;
                      cursor: pointer;
                      text-decoration: underline;
                    "
                    >{{ item?.value?.prefix ? (item?.value | personName : item.options) : item?.value }}</a>
                    <a
                    *ngIf="item.displayFieldType === EntityPropertyType.Address"
                    (click)="_onClickEntityReference(item)"
                    href="javascript:void(0)"
                    style="
                      color: #408BF9;
                      cursor: pointer;
                      text-decoration: underline;
                    "
                    >
                      <ptg-view-address *ngIf="item.value"
                        [value]="item?.value"
                        [options]="item.options"
                      ></ptg-view-address>
                    </a>
                  </span>
                  <ng-template #entityReferenceEmptyValue>-</ng-template>
                </span>
                <ng-container *ngSwitchCase="EntityPropertyType.RichText">
                  <ptg-view-rich-text [title]="item?.title" [content]="item.value" [defaultContent]="'-'"></ptg-view-rich-text>
                </ng-container>
                <span *ngSwitchCase="EntityPropertyType['Date Time']">{{ (item?.value | date: 'MM/dd/yyyy hh:mm a') || '-' }}</span>
                <span *ngSwitchCase="'colUserFixedCard'">{{ item?.colFixed?.trim() || '-' }}</span>
                <span *ngSwitchCase="'colDateFixedCard'">{{(item?.colFixed?.trim() | date: 'MM/dd/yyyy hh:mm a') || '-' }}</span>
                <span *ngSwitchDefault>
                  <span
                  *ngIf="!item.masked; else ssn"
                >
                  {{item.valueUnMasked || item.value || "-" }}
                </span>
                <ng-template #ssn>

                  <span style="color: #b1b1b1" *ngIf="item.visibilityOff && item.value">XXX-XX-</span>
                  <span>{{ item.visibilityOff ? item.value : item.valueUnMasked || "-"}}</span>
                </ng-template>
                </span>
              </ng-container>
              <span>
                &nbsp;
                <mat-icon
                  *ngIf="item?.masked && item.value"
                  class="masked-icon"
                  matSuffix
                  (click)="onClickIcon(item)"
                  >{{ item.visibilityOff ? "visibility_off" : "visibility" }}</mat-icon
                >
              </span>
            </p>
          </div>
        </div>
      </ng-container>

    </div>
    <div id="loading-table"
      *ngIf="isLoading">
      <div class="wrap-item">
        <mat-progress-spinner diameter="50" mode="indeterminate">
        </mat-progress-spinner>
        <p>Loading...</p>
      </div>
    </div>
  </div>
