import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  CheckComputeDisabilityDataResponse,
  ComputationDisabilityDataResponse,
  GetDisabilityBenefitDetailResponse,
  GetTotalDaysUsedResponse,
  GetDisabilityCalculationDetailResponse,
  InitiateDisabilityBenefitResponse,
} from '../../services/models';
import {
  approveDisabilityBenefitAction,
  approveDisabilityBenefitFailureAction,
  approveDisabilityBenefitSuccessAction,
  completeDisabilityBenefitAction,
  completeDisabilityBenefitFailureAction,
  completeDisabilityBenefitSuccessAction,
  computeDisabilityBenefitAction,
  computeDisabilityBenefitFailureAction,
  computeDisabilityBenefitSuccessAction,
  editDisabilityBenefitAction,
  editDisabilityBenefitFailureAction,
  editDisabilityBenefitSuccessAction,
  getDisabilityBenefitDetailAction,
  getDisabilityBenefitDetailFailureAction,
  getDisabilityBenefitDetailSuccessAction,
  getTotalDaysUsedAction,
  getTotalDaysUsedFailureAction,
  getTotalDaysUsedSuccessAction,
  getDisabilityCalculationDetailAction,
  getDisabilityCalculationDetailFailureAction,
  getDisabilityCalculationDetailSuccessAction,
  initiateDisabilityBenefitAction,
  initiateDisabilityBenefitFailureAction,
  initiateDisabilityBenefitSuccessAction,
  removeDisabilityCalculationAction,
  removeDisabilityCalculationFailureAction,
  removeDisabilityCalculationSuccessAction,
  validateBeforeComputingAction,
  validateBeforeComputingFailureAction,
  validateBeforeComputingSuccessAction,
  validateDisabilityBenefitAction,
  validateDisabilityBenefitFailureAction,
  validateDisabilityBenefitSuccessAction,
  validateRemoveCalculationAction,
  validateRemoveCalculationSuccessAction,
  validateRemoveCalculationFailureAction,
  editCalculationDetailAction,
  editCalculationDetailSuccessAction,
  editCalculationDetailFailureAction,
} from '../actions';
import { DisabilityBenefitService } from '../../services';

@Injectable()
export class DisabilityBenefitEffects {
  constructor(
    private actions$: Actions,
    private disabilityBenefitService: DisabilityBenefitService,
  ) {}

  getDisabilityBenefitDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDisabilityBenefitDetailAction),
      switchMap(({ request }) =>
        this.disabilityBenefitService.getDisabilityBenefitDetail(request).pipe(
          map((response: GetDisabilityBenefitDetailResponse) => getDisabilityBenefitDetailSuccessAction({ response })),
          catchError((error) => of(getDisabilityBenefitDetailFailureAction({ error }))),
        ),
      ),
    ),
  );

  // Complete Disability Benefit
  completeDisabilityBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(completeDisabilityBenefitAction),
      switchMap(({ memberId, calculationBenefitId, calculationType }) =>
        this.disabilityBenefitService.completeDisabilityBenefit(memberId, calculationBenefitId, calculationType).pipe(
          map(() => completeDisabilityBenefitSuccessAction()),
          catchError((error) => of(completeDisabilityBenefitFailureAction({ error }))),
        ),
      ),
    ),
  );

  // Approve Disability Benefit
  approveCalculationBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveDisabilityBenefitAction),
      switchMap(({ memberId, calculationBenefitId, calculationType }) =>
        this.disabilityBenefitService.approveDisabilityBenefit(memberId, calculationBenefitId, calculationType).pipe(
          map(() => approveDisabilityBenefitSuccessAction()),
          catchError((error) => of(approveDisabilityBenefitFailureAction({ error }))),
        ),
      ),
    ),
  );

  // validate Disability Benefit
  validateDisabilityBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateDisabilityBenefitAction),
      switchMap(({ request }) =>
        this.disabilityBenefitService.validateDisabilityBenefit(request).pipe(
          map((response: CheckComputeDisabilityDataResponse) => validateDisabilityBenefitSuccessAction({ response })),
          catchError((error) => of(validateDisabilityBenefitFailureAction({ error }))),
        ),
      ),
    ),
  );

  // Validation Before Computing
  validationBeforeComputing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateBeforeComputingAction),
      switchMap(({ memberId, calculationBenefitId, calculationType }) =>
        this.disabilityBenefitService.validateDataCompute(memberId, calculationBenefitId, calculationType).pipe(
          map((response: CheckComputeDisabilityDataResponse) => validateBeforeComputingSuccessAction({ response })),
          catchError((error) => of(validateBeforeComputingFailureAction({ error }))),
        ),
      ),
    ),
  );

  // compute Disability Benefit
  computeDisabilityBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(computeDisabilityBenefitAction),
      switchMap(({ memberId, calculationBenefitId, calculationType }) =>
        this.disabilityBenefitService.computeDisabilityBenefit(memberId, calculationBenefitId, calculationType).pipe(
          map((response: ComputationDisabilityDataResponse) => computeDisabilityBenefitSuccessAction({ response })),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommandException') {
              errorMessage = error?.error?.errorMessage[1] ?? '';
            }
            return of(computeDisabilityBenefitFailureAction({ error: errorMessage }))
          }),
        ),
      ),
    ),
  );

  // initiate Disability Benefit
  initiateDisabilityBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initiateDisabilityBenefitAction),
      switchMap(({ request, memberId }) =>
        this.disabilityBenefitService.initiateDisabilityBenefit(request, memberId).pipe(
          map((response: InitiateDisabilityBenefitResponse) => initiateDisabilityBenefitSuccessAction({ response })),
          catchError((error) => of(initiateDisabilityBenefitFailureAction({ error }))),
        ),
      ),
    ),
  );

  // edit Disability Benefit
  editDisabilityBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editDisabilityBenefitAction),
      switchMap(({ request, calculationType, calculationBenefitId }) =>
        this.disabilityBenefitService.editDisabilityBenefit(request, calculationType, calculationBenefitId).pipe(
          map((response: any) => editDisabilityBenefitSuccessAction({ response })),
          catchError((error) => of(editDisabilityBenefitFailureAction({ error }))),
        ),
      ),
    ),
  );

  // get Total days
  getTotalDaysUsed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTotalDaysUsedAction),
      switchMap(({ memberId, calculationType, calculationBenefitId }) =>
        this.disabilityBenefitService.getTotalDaysUsed(memberId, calculationType, calculationBenefitId).pipe(
          map((response: GetTotalDaysUsedResponse) => getTotalDaysUsedSuccessAction({ response })),
          catchError((error) => of(getTotalDaysUsedFailureAction({ error }))),
        ),
      ),
    ),
  );
  // Validation Before remove calculation
  validationRemoveCalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateRemoveCalculationAction),
      switchMap(({ memberId, calculationRecordId }) =>
        this.disabilityBenefitService.validateRemoveCalculation(memberId, calculationRecordId).pipe(
          map((response: CheckComputeDisabilityDataResponse) => validateRemoveCalculationSuccessAction({ response })),
          catchError((error) => of(validateRemoveCalculationFailureAction({ error }))),
        ),
      ),
    ),
  );

  // remove Disability Calculation
  removeDisabilityCalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeDisabilityCalculationAction),
      switchMap(({ memberId, calculationRecordId }) =>
        this.disabilityBenefitService.removeDisabilityCalculation(memberId, calculationRecordId).pipe(
          map((response: any) => removeDisabilityCalculationSuccessAction({ response })),
          catchError((error) => of(removeDisabilityCalculationFailureAction({ error }))),
        ),
      ),
    ),
  );

  //get Disability Calculation detail
  getDisabilityCalculationDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDisabilityCalculationDetailAction),
      switchMap(({ calculationBenefitId, memberId, calculationType, calculationRecordId }) =>
        this.disabilityBenefitService
          .getDisabilityCalculationDetail(calculationBenefitId, memberId, calculationType, calculationRecordId)
          .pipe(
            map((response: GetDisabilityCalculationDetailResponse) =>
              getDisabilityCalculationDetailSuccessAction({ response }),
            ),
            catchError((error) => of(getDisabilityCalculationDetailFailureAction({ error }))),
          ),
      ),
    ),
  );

  // edit Calculation detail
  editCalculationDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editCalculationDetailAction),
      switchMap(({ request, calculationType, calculationBenefitId, calculationRecordId }) =>
        this.disabilityBenefitService
          .editCalculationDetail(request, calculationType, calculationBenefitId, calculationRecordId)
          .pipe(
            map((response: any) => editCalculationDetailSuccessAction({ response })),
            catchError((error) => of(editCalculationDetailFailureAction({ error }))),
          ),
      ),
    ),
  );
}
