import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { stringToBoolean } from '@ptg-shared/utils/string.util';
import { CREATED_AT, CREATED_BY, GUID_EMPTY, UPDATED_AT, UPDATED_BY } from '@ptg-shared/constance';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { DisplayedTabName, STRING_QUERY_PARAM } from '@ptg-shared/layout/constance/layout.const';
import { BaseComponent } from '@ptg-shared/components';
import { LayoutActions } from '@ptg-shared/layout/actions';

import { EntityPropertyType, IncludeAttachmentType } from '@ptg-entity-management/types/enums';
import { getEntityValue } from '@ptg-member/helper';
import { CardComponent, ListPropertyForCard } from '@ptg-entity-management/services/models';
import * as fromReducer from '@ptg-reducers';
import { FixedPropertyKey, NOTES_CARD } from '@ptg-entity-management/constants';
import { CalculationType } from '@ptg-member/features/calculation/types/enums';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { FundType } from '@ptg-shared/types/enums';

@Component({
  selector: 'ptg-member-navigation-list-view-detail',
  templateUrl: './member-navigation-list-view-detail.component.html',
  styleUrls: ['./member-navigation-list-view-detail.component.scss'],
})
export class MemberNavigationListViewDetailComponent extends BaseComponent{
  readonly EntityPropertyType = EntityPropertyType;
  readonly IncludeAttachmentType = IncludeAttachmentType;
  readonly FixedPropertyKey = FixedPropertyKey;
  readonly NOTES_CARD = NOTES_CARD;

  // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
  @Input() qdroLabelName?: string;
  @Input() currentRow!: any;
  @Input() metadataSection?: any;
  @Input() menuItemName!: string;
  @Input() isList?: boolean;
  @Input() memberId!: string;
  @Input() isLoading: boolean = true;
  @Input() isBenefitCard: boolean = false;
  @Input() attachmentType?: IncludeAttachmentType;
  @Output() editButtonEvent = new EventEmitter();
  @Output() removeButtonEvent = new EventEmitter();
  memberNavigationItem: any;
  listValue: any[] = [];
  visibilityOff: boolean = true;
  isBVFFFund: boolean = false;

  constructor(
    private router: Router,
    private layoutService: LayoutService,
    private store: Store<fromReducer.State>
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.layoutService.fundType === FundType.BVFF) {
      this.isBVFFFund = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.listValue = [];
    if (changes.currentRow && changes.currentRow.currentValue || changes.metadataSection && changes.metadataSection.currentValue) {
      this.metadataSection?.forEach((p: any) => {
        this.getProperties(p);
      });
    }
  }

  getProperties(cardComponent: CardComponent): any {
    this.listValue.push(this.setDataProperties(cardComponent));
    this.listValue.sort((a, b) => Number(a.orderColumn) - Number(b.orderColumn));
  }

  setDataProperties(cardComponent: any): any {
    let currentRowData: any;
    if (cardComponent?.entityReferencePropertyId) {
      currentRowData = this.currentRow?.values?.find((item: any) => {
        if (
          item.entityPropertyId === cardComponent?.entityReferencePropertyId &&
          item.entityReferencePropertyId === cardComponent?.propertyId
        ) {
          return cardComponent?.type === EntityPropertyType['Person Name'] ? item?.option === cardComponent?.option : true;
        }
        return false;
      });
    } else if (cardComponent?.propertyId) {
      currentRowData = this.currentRow?.values?.find((item: any) =>
        cardComponent?.type === EntityPropertyType['Person Name'] || cardComponent?.type === EntityPropertyType.Address
          ? item.entityPropertyId === cardComponent?.propertyId && item?.option === cardComponent?.option
          : item.entityPropertyId === cardComponent?.propertyId && item.propertyType === cardComponent.type,
      );
    } else {
      currentRowData = this.currentRow?.value?.find((item: any) => item.fixedPropertyKey === cardComponent?.option);
    }
    const entityData: ListPropertyForCard = {
      value: currentRowData?.value,
      options: currentRowData?.options,
      option: currentRowData?.option,
      displayFieldType: currentRowData?.displayFieldType
    };
    const opiton = {
      type: cardComponent?.type,
      config: cardComponent?.config,
    };
    const keysDateColFixed = [CREATED_AT, UPDATED_AT];
    const keysUserColFixed = [CREATED_BY, UPDATED_BY];
    // const data = getEntityValue(entityData, opiton, true);

    // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
    let data = getEntityValue(entityData, opiton, true);
    if (data.includes(CalculationType[CalculationType.QDRO])) {
      data = data.replaceAll(CalculationType[CalculationType.QDRO], this.qdroLabelName);
    }
    // End of [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9

    return {
      key: this.getColumnName(cardComponent),
      value: data,
      title: cardComponent?.propertyLabel,
      type: [EntityPropertyType.Binary].includes(cardComponent.type as EntityPropertyType)
        ? EntityPropertyType.Text
        : keysDateColFixed.includes(cardComponent.option)
          ? 'colDateFixedCard'
          : keysUserColFixed.includes(cardComponent.option)
            ? 'colUserFixedCard'
            : cardComponent.type,
      displayFieldType: entityData.displayFieldType,
      configs: cardComponent?.config,
      options: cardComponent.type === EntityPropertyType.Address ? currentRowData?.options : (cardComponent?.options ?? currentRowData?.options),
      masked: stringToBoolean(cardComponent?.config?.masked),
      valueUnMasked: this.mapDataUnMasked(entityData),
      visibilityOff: stringToBoolean(cardComponent?.config?.masked),
      colFixed: cardComponent.dataCol,
      label: cardComponent.label,
      entityReferenceLinkedId: currentRowData?.entityReferenceLinkedId,
      recordId: currentRowData?.recordId
    };
  }

  getColumnName(column: any): string {
    let columnName = '';
    const optColNameList = [UPDATED_BY, CREATED_AT, CREATED_BY, UPDATED_AT];
    if (optColNameList.includes(column.option)) {
      columnName = GUID_EMPTY;
    } else {
      columnName = column.propertyId;
    }
    if (column.propertyId) {
      columnName = columnName + '_' + column.entityReferencePropertyId;
    }

    if (column.option) {
      columnName = columnName + '_' + column.option;
    }

    return columnName;
  }

  mapDataUnMasked(entityData: any) {
    return entityData?.value?.originalValue;
  }

  onClickIcon(row: any) {
    row.visibilityOff = !row.visibilityOff;
  }

  edit() {
    if (this.isLoading) {
      return;
    }
    this.editButtonEvent.emit(this.currentRow);
  }
  remove() {
    if (this.isLoading) {
      return;
    }
    this.removeButtonEvent.emit(this.currentRow);
  }

  _onClickEntityReference(data: any) {
    this.store.dispatch(
      LayoutActions.profileNavigationItemRequest({
        memberId: data?.recordId,
        entityReferenceLinkedId: data?.entityReferenceLinkedId,
        isHyperlink: true
      })
    );

    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.isHyperlink && state?.menu && state?.menu.length > 0 && data?.recordId) {
          const overviewView = state.memberNavigationList as any;
          const isOverviewDetailView = (state.memberNavigationList as any)?.isOverviewDetailView;
          const url = `${isOverviewDetailView ? '/member/detail-view/true' : '/member/summary-view/true'}/${overviewView.id}/${overviewView.overviewViewId}/${data?.recordId}?${STRING_QUERY_PARAM.PROFILE_NAVIGATE}&entityReferenceLinkedId=${data.entityReferenceLinkedId}`;
          const entityReferenceLinkedIdParam = new URLSearchParams(document.location.search).get("entityReferenceLinkedId");

          if(url.slice(0, url.indexOf('?')) === document.location.pathname
            && data.entityReferenceLinkedId === entityReferenceLinkedIdParam) {
              this.router.navigateByUrl(url);
            }
          else{
            this.store.dispatch(LayoutActions.clearProfileNavigationItemState());
            this.store.dispatch(
              LayoutActions.selectTab({
                tab: DisplayedTabName.IndividualParticipant,
                url,
              }),
            );
          }
        }
      });
  }
}
