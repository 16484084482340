import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { finalize, takeUntil } from 'rxjs/operators';

import * as fromReducer from '@ptg-reducers';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { LayoutActions } from '@ptg-shared/layout/actions';

import { GetInitiationPropertiesResponse, InitiationProperty } from '@ptg-entity-management/services/models';
import { MemberState } from '@ptg-member/store/reducers';
import { clearCreateMemberStateAction, clearSetNonMemberToMemberStateAction, clearUpdateMemberCardStateAction, createMemberAction, setNonMemberToMemberAction, updateMemberCardAction } from '@ptg-member/store/actions';
import { createMemberSelector, setNonMemberToMemberSelector, updateMemberCardSelector } from '@ptg-member/store/selectors/member.selector';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { MemberService } from '@ptg-member/services/member.service';
import { PERSON_BASE_PROPERTIES } from '@ptg-entity-management/constants';
import { CheckMemberExistsBySsnRequest } from '@ptg-member/types/models';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { FundType } from '@ptg-shared/types/enums';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'ptg-upsert-member',
  templateUrl: './upsert-member.component.html',
  styleUrls: ['./upsert-member.component.scss'],
})
export class UpsertMemberComponent extends BaseComponent {
  FundType = FundType;
  title: string = '';
  entityId: string = '';
  properties: InitiationProperty[] = [];
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  isAddSuccess = false;
  isSubmitting: boolean = false;
  memberId: string = '';

  constructor(
    private memberStore: Store<MemberState>,
    public dialogRef: MatDialogRef<UpsertMemberComponent>,
    private dialog: MatDialog,
    private store: Store<fromReducer.State>,
    @Inject(MAT_DIALOG_DATA) public data: GetInitiationPropertiesResponse,
    private memberService: MemberService,
    public layoutService: LayoutService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.title = this.data.entityLabel;
    this.entityId = this.data.entityId;
    this.properties = this.data.properties;

    super.ngOnInit();
    this._createMemberSelector();
    this._updateMemberSelector();
    this._setNonMemberToMemberSelector();
    this.getOverviewURL();
  }

  getOverviewURL() {
    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        let url = '';
        if (state?.menu && state?.menu.length > 0 && this.isAddSuccess) {
          this.isAddSuccess = false;
          const overviewView = state.memberNavigationList as any;
          if ((state.memberNavigationList as any)?.isOverviewDetailView) {
            url = `/member/detail-view/true/${overviewView.id}/${overviewView.overviewViewId}/${state.memberId}`;
          } else {
            url = `/member/summary-view/true/${overviewView.id}/${overviewView.overviewViewId}/${state.memberId}`;
          }
          this.store.dispatch(LayoutActions.clearProfileNavigationItemState());
          this.store.dispatch(
            LayoutActions.selectTab({
              tab: DisplayedTabName.IndividualParticipant,
              url,
            }),
          );

          this.dialogRef.close();
        } else if (state?.isReloading && this.isAddSuccess) {
          this.isAddSuccess = false;
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              text: 'Screen not yet configured. Please contact your administrator for support.',
              title: 'Warning',
              cancelButtonTitle: 'Close',
              hideConfirmButton: true,
            },
          });
          this.dialogRef.close(true);
        }
      });
  }

  private _createMemberSelector(): void {
    this.memberStore
      .pipe(
        select(createMemberSelector),
        takeUntil(this.unsubscribe$),
        finalize(() => (this.isSubmitting = false)),
      )
      .subscribe((el) => {
        if (el) {
          this.layoutService.showLoading = !!el.isLoading;
          if (el?.success) {
            this.isAddSuccess = true;
            this.store.dispatch(LayoutActions.profileNavigationItemRequest({ memberId: el.payload?.memberId || '' }));
          } else if (el.success === false) {
            this.bannerType = BannerType.Fail;
            this.message = `Error occurred adding ${this.title}. Please try again.`;
          }

          this.memberStore.dispatch(clearCreateMemberStateAction());
        }
      });
  }

  private _updateMemberSelector(): void {
    this.memberStore.pipe(
      select(updateMemberCardSelector),
      takeUntil(this.unsubscribe$)
    ).subscribe((state) => {
      if (state) {
        this.layoutService.showLoading = !!state.isLoading;
        if (state?.success) {
          this.isAddSuccess = true;
          this.store.dispatch(LayoutActions.profileNavigationItemRequest({ memberId: this.memberId || '' }));
        } else if (state?.success === false) {
          this.bannerType = BannerType.Fail;
          this.message = `Error occurred adding ${this.title}. Please try again.`;
        }
        this.memberStore.dispatch(clearUpdateMemberCardStateAction());
      }
    });
  }

  onSubmit(request: any): void {
    if (!this.checkExistIdentityProperties()) {
      this.saveData(request);
    } else {
      this.checkExistsAndSaveData(request);
    }
  }

  checkExistIdentityProperties(): boolean {
    const identityPropertyIds = [
      PERSON_BASE_PROPERTIES.SSN,
      PERSON_BASE_PROPERTIES.Name,
      PERSON_BASE_PROPERTIES.DateOfBirth,
    ];
    return identityPropertyIds.every(propId => this.properties.some(p => p.entityPropertyId.toLowerCase() === propId.toLowerCase()));
  }

  checkExistsAndSaveData(request: any) {
    const datepipe = new DatePipe('en-US');
    const ssnValue = request?.entityPropertyValues?.find((p: any) => p.entityPropertyId.toLowerCase() === PERSON_BASE_PROPERTIES.SSN.toLowerCase())?.value;
    const nameValue = request?.entityPropertyValues?.find((p: any) => p.entityPropertyId.toLowerCase() === PERSON_BASE_PROPERTIES.Name.toLowerCase())?.value;
    const nameObj = nameValue ? JSON.parse(nameValue) : null;
    const dateOfBirthValue = request?.entityPropertyValues?.find((p: any) => p.entityPropertyId.toLowerCase() === PERSON_BASE_PROPERTIES.DateOfBirth.toLowerCase())?.rawValue;

    const checkMemberExistsRequest: CheckMemberExistsBySsnRequest = {
      ssn: ssnValue,
      firstName: nameObj?.First,
      lastName: nameObj?.Last,
      dateOfBirth: dateOfBirthValue ? datepipe.transform(dateOfBirthValue, 'MM/dd/yyyy') : null,
    }
    this.memberService.checkMemberExists(checkMemberExistsRequest).subscribe(res => {
      // not matched
      if (!res.isMatched && !res.isFullyMatched) {
        this.saveData(request);
        return;
      }

      // fully matched SSN
      if (res.isFullyMatched) {
        // fully matched SSN and 2 out of 3 fields First Name, Last Name, Date of Birth matched
        if (res.existedMemberId) {
          this.memberId = res.existedMemberId;
          const confirmDialogRef = this.showConfirmPopup();
          confirmDialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              if (res.existedRecordIsMember) {
                this.memberStore.dispatch(
                  updateMemberCardAction({
                    memberId: res.existedMemberId,
                    cardName: '',
                    request,
                  }),
                );
              } else {
                this.memberStore.dispatch(setNonMemberToMemberAction({ recordId: res.existedMemberId, request }));
              }
            }
          });
          return;
        };

        // fully matched SSN and less than 2 out of 3 fields First Name, Last Name, Date of Birth matched
        this.showWarningAndConfirmExistsPopup(request, res.message);
        return;
      }

      // not fully matched SSN(at least 6 out of 9 characters of newly added SSN matched)
      if (res.isMatched) {
        // not fully matched SSN and 2 out of 3 fields First Name, Last Name, Date of Birth matched
        if (res.existedMemberId) {
          this.showWarningAndConfirmExistsPopup(request, res.message);
          return;
        }

        // not fully matched SSN and less than 2 out of 3 fields First Name, Last Name, Date of Birth matched
        const confirmDialogRef = this.showConfirmPopup();
        confirmDialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
            this.saveData(request);
          }
        });
      }
    });
  }

  saveData(request: any) {
    this.isSubmitting = true;
    this.memberStore.dispatch(
      createMemberAction({
        request,
      }),
    );
  }

  showWarningAndConfirmExistsPopup(request: any, message: string) {
    const warningDialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: message,
        title: 'Warning',
        type: ConfirmType.ConfirmSave,
        saveButtonTitle: 'Yes',
        cancelButtonTitle: 'Cancel',
        hideSaveAsButton: true,
        hideConfirmButton: true,
        iconConfig: {
          icon: 'warning',
          style: { color: '#FD8A03', fontSize: '40px' }
        },
      },
    });

    warningDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        const confirmDialogRef = this.showConfirmPopup();
        confirmDialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
            this.saveData(request);
          }
        });
      }
    });
  }

  showConfirmPopup() {
    return this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          title: 'Confirmation',
          text: 'Are you sure you want to add this New Member?',
          type: ConfirmType.ConfirmSave,
          saveButtonTitle: 'Yes',
          cancelButtonTitle: 'Cancel',
          hideSaveAsButton: true,
          hideConfirmButton: true,
        },
      }
    );
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.CancelPopup,
        cancelButtonTitle: 'No',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  private _setNonMemberToMemberSelector(): void {
    this.memberStore
      .pipe(
        select(setNonMemberToMemberSelector),
        takeUntil(this.unsubscribe$),
        finalize(() => (this.isSubmitting = false)),
      )
      .subscribe((el) => {
        if (el) {
          if (el?.success) {
            this.isAddSuccess = true;
            this.store.dispatch(LayoutActions.profileNavigationItemRequest({ memberId: el.payload?.memberId || '' }));
          } else if (el.success === false) {
            this.bannerType = BannerType.Fail;
            this.message = `Error occurred adding ${this.title}. Please try again.`;
          }

          this.memberStore.dispatch(clearSetNonMemberToMemberStateAction());
        }
      });
  }
}
