import { createAction, props } from "@ngrx/store";

import { AddFundResponse, FundData } from "../models/add-fund.model";
import { FundListQuery, TimeZone, GetFundListResponse, GetNotificationAlertResponse, CreateNotificationAlertRequest } from '../models/fund-list.model';

export const FundListRequest = '[FundList/API] Send Request';
export const FundListFailure = '[FundList/API] Failure';
export const FundListSuccess = '[FundList/API] Success';
export const SelectFund = '[Fund] Selection';
export const ResetCurrentFund = '[Fund] Reset Current Fund';
export const ChangeStatusRequest = '[FundStatus/API] Send Request';
export const ChangeStatusFailure = '[FundStatus/API] Failure';
export const ChangeStatusSuccess = '[FundStatus/API] Success';
export const ChangeStatusClear = '[FundStatus] Clear';
export const RemoveFundRequest = '[RemoveFund/API] Send Request';
export const RemoveFundFailure = '[RemoveFund/API] Failure';
export const RemoveFundSuccess = '[RemoveFund/API] Success';
export const RemoveFundReset = '[RemoveFundFund] Reset';
export const AddFundRequest = '[AddFund/API] Send Request';
export const AddFundFailure = '[AddFund/API] Failure';
export const AddFundSuccess = '[AddFund/API] Success';
export const AddFundReset = '[AddFund] Reset';
export const UpdateFundLayout = '[CurrentFundLayout] Update'
export const GetAllFundRequest = '[AllFund/API] Send Request';
export const GetAllFundFailure = '[AllFund/API] Failure';
export const GetAllFundSuccess = '[AllFund/API] Success';
export const GetTimeZonesRequest = '[GetTimeZones/API] Send Request';
export const GetTimeZonesFailure = '[GetTimeZones/API] Failure';
export const GetTimeZonesSuccess = '[GetTimeZones/API] Success';
export const GetNotificationAlertRequest = '[GetNotificationAlert/API] Send Request';
export const GetNotificationAlertFailure = '[GetNotificationAlert/API] Failure';
export const GetNotificationAlertSuccess = '[GetNotificationAlert/API] Success';
export const UpdateNotificationAlertIsRead = '[UpdateNotificationAlertIsRead/API] Send Request';
export const UpdateNotificationAlertIsReadFailure = '[UpdateNotificationAlertIsRead/API] Failure';
export const UpdateNotificationAlertIsReadSuccess = '[UpdateNotificationAlertIsRead/API] Success';
export const CreateNotificationAlert = '[CreateNotificationAlert/API] Send Request';
export const CreateNotificationAlertSuccess = '[CreateNotificationAlert/API] Success';
export const CreateNotificationAlertFailure = '[CreateNotificationAlert/API] Failure';
export const ClearCreateNotificationAlertState = '[CreateNotificationAlert/API] Clear';


export const getFundList = createAction(
  FundListRequest,
  props<{ query: FundListQuery }>()
);

export const getFundListSuccess = createAction(
  FundListSuccess,
  props<{ response: GetFundListResponse }>()
);

export const getFundListFailure = createAction(
  FundListFailure,
  props<{ error?: any }>()
);

export const selectFund = createAction(
  SelectFund,
  props<{ currentFund: any }>()
);
export const resetCurrentFund = createAction(
  ResetCurrentFund
);

export const changeStatusFund = createAction(
  ChangeStatusRequest,
  props<{ active: boolean, id: string }>()
);
export const changeStatusSuccess = createAction(
  ChangeStatusSuccess,
  props<{ active: boolean, id: string }>()
);
export const changeStatusFailure = createAction(
  ChangeStatusFailure,
  props<{ active: boolean, id: string }>()
);
export const changeStatusClear = createAction(
  ChangeStatusClear
);

export const removeFund = createAction(
  RemoveFundRequest,
  props<{ id: string }>()
);
export const removeFundSuccess = createAction(
  RemoveFundSuccess
);
export const removeFundFailure = createAction(
  RemoveFundFailure,
  props<{ errorMsg: string }>()
);
export const removeFundReset = createAction(RemoveFundReset);

export const addNewFund = createAction(
  AddFundRequest,
  props<{ dataFund: FundData }>()
);

export const addNewFundSuccess = createAction(
  AddFundSuccess,
  props<{ response: AddFundResponse }>()
);

export const addNewFundFailure = createAction(
  AddFundFailure,
  props<{ errorMsg: string }>()
);

export const addNewFundReset = createAction(AddFundReset);

export const updateFundLayout = createAction(
  UpdateFundLayout,
  props<{ updateLayout: {} }>()
);

export const getAllFund = createAction(
  GetAllFundRequest
);

export const getAllFundSuccess = createAction(
  GetAllFundSuccess,
  props<{ response: GetFundListResponse }>()
);

export const getAllFundFailure = createAction(
  GetAllFundFailure,
  props<{ error?: any }>()
);

export const getTimeZones = createAction(
  GetTimeZonesRequest
);
export const getTimeZonesSuccess = createAction(
  GetTimeZonesSuccess,
  props<{ timeZones: TimeZone[] }>()
);
export const getTimeZonesFailure = createAction(
  GetTimeZonesFailure,
  props<{ errorMsg: string }>()
);

export const getNotificationAlert = createAction(
  GetNotificationAlertRequest
);
export const getNotificationAlertSuccess = createAction(
  GetNotificationAlertSuccess,
  props<{ response: GetNotificationAlertResponse }>()
);
export const getNotificationAlertFailure = createAction(
  GetNotificationAlertFailure,
  props<{ error?: any }>()
);

export const updateNotificationAlertIsRead = createAction(
  UpdateNotificationAlertIsRead,
  props<{ notificationAlertId: string }>()
)
export const updateNotificationAlertIsReadFailure = createAction(
  UpdateNotificationAlertIsReadFailure,
  props<{ error?: any }>()
)
export const updateNotificationAlertIsReadSuccess = createAction(
  UpdateNotificationAlertIsReadSuccess,
  props<{ response?: any }>()
)

export const createNotificationAlert = createAction(
  CreateNotificationAlert,
  props<{ request: CreateNotificationAlertRequest }>()
)
export const createNotificationAlertFailure = createAction(
  CreateNotificationAlertFailure,
  props<{ error?: any }>()
);
export const clearCreateNotificationAlertState = createAction(
  ClearCreateNotificationAlertState
);
export const createNotificationAlertSuccess = createAction(
  CreateNotificationAlertSuccess,
  props<{ response?: any }>()
)

