import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  CreateTransactionBody,
  CreateValidateServiceHistoryRecordBeginDateTransactionRequest,
  EditMunicipalityServiceHistoryRequest,
  EmployerCheckServiceBeginDate,
  GetListStatusHistoryQuery,
  MunicipalityNameList,
  MunicipalityServiceRecordList,
  MunicipalityServiceRecordListEntity,
  RemoveServiceHistoryCJRequest,
  ValidateServiceHistoryRecordTerminatedMemberRequest,
  ValidateTerminatedMemberBody,
  ValidateTerminatedMemberResponse,
} from '../types/models';
import { FixedSection } from '@ptg-member/constance/metadata.const';
import { SKIP_ERRORS } from '@ptg-interceptor/httpconfig.interceptor';
import { EmployerCheckDisableRequest } from '@ptg-employer/models/employer.model';
import { getLocalDate } from '@ptg-shared/utils/string.util';

@Injectable({
  providedIn: 'root',
})
export class MunicipalityServiceHistoryService {
  constructor(private httpClient: HttpClient) {}

  getMunicipalityServiceHistoryList(
    query: GetListStatusHistoryQuery
  ): Observable<MunicipalityServiceRecordList> {
    let params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<MunicipalityServiceRecordList>(
      `${environment.apiUrl}/Members/ServiceRecord`,
      { params }
    );
  }

  getMunicipalityServiceHistoryEntityList(
    query: any, memberId: string,
  ): Observable<MunicipalityServiceRecordListEntity> {
    let params = new HttpParams();
    if (query) {
      params = params.append('Masked', true);
      params = params.append('PageSize', query.pageSize);
      params = params.append('PageIndex', query.pageIndex);
    }
    return this.httpClient.get<MunicipalityServiceRecordListEntity>(
      `${environment.apiUrl}/Members/${memberId}/${FixedSection.ServiceHistory}`,
      { params }
    );
  }

  getMunicipalityList(): Observable<MunicipalityNameList> {
    return this.httpClient.get<MunicipalityNameList>(
      `${environment.apiUrl}/municipality/get-employers`
    );
  }

  editMunicipalityServiceHistory(body: EditMunicipalityServiceHistoryRequest) {
    let bodyRequest = {
      id: body.id,
      memberId: body.memberId,
      municipalityId: body.municipalityId,
      beginDate: body.beginDate,
      endDate: body.endDate ? body.endDate : null,
    };
    return this.httpClient.post(
      `${environment.apiUrl}/municipality/edit-service-history`,
      {...bodyRequest, localDate: getLocalDate()},
    );
  }

  checkExits = (bodyCheck: any) => {
    return this.httpClient.post(
      `${ environment.apiUrl }/municipality/check-exist-service-record`,
      bodyCheck,
    );
  };

  validateTerminatedMember(body: ValidateTerminatedMemberBody): Observable<ValidateTerminatedMemberResponse> {
    return this.httpClient.post<ValidateTerminatedMemberResponse>(`${ environment.apiUrl }/municipality/service-history/validate-terminated-member`, body);
  }

  createTransaction(body: CreateTransactionBody): Observable<void> {
    return this.httpClient.post<void>(`${ environment.apiUrl }/municipality/annual-certification/no-activity-transaction`, body);
  }

  validateServiceHistoryRecordTerminatedMember (
    request: ValidateServiceHistoryRecordTerminatedMemberRequest,
  ) {
    return this.httpClient.post(
      `${environment.apiUrl}/municipality/service-history/validate-terminated-member`,
      request,
    );
  }

  createValidateServiceHistoryRecordBeginDateTransaction(
    request: CreateValidateServiceHistoryRecordBeginDateTransactionRequest,
  ) {
    return this.httpClient.post(
      `${environment.apiUrl}/member-portal/service-history/create-gap-year-transaction`,
      request,
    );
  }

  getMuniCheckServiceBeginDate(body: EmployerCheckServiceBeginDate){
    const context = new HttpContext().set(SKIP_ERRORS, '500');
    let params = new HttpParams();
  if (body?.serviceBeginDate) {
      params = params.append('ServiceBeginDate', body.serviceBeginDate);
    }
    if (body?.memberId) {
      params = params.append('MemberId', body.memberId);
    }
    return this.httpClient.get<EmployerCheckDisableRequest>(`${ environment.apiUrl }/members/validate-member-sixteen`, { params, context });
  }

  validateRemoveServiceHistory(id: string) {
    return this.httpClient.post(
      `${environment.apiUrl}/municipality/remove-service-history/validate`,
      {id, localDate: getLocalDate()},
    );
  }

  removeServiceHistory(body: RemoveServiceHistoryCJRequest) {
    return this.httpClient.delete(
      `${environment.apiUrl}/municipality/remove-service-history`,
      { body }
    );
  }
}
