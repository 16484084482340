import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';

import { Align, Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { DisplayedTabName, STRING_QUERY_PARAM } from '@ptg-shared/layout/constance/layout.const';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { deepClone } from '@ptg-shared/utils/common.util';
import { BaseComponent } from '@ptg-shared/components';
import { getDateString } from '@ptg-shared/utils/string.util';
import * as fromReducer from '@ptg-reducers';
import { EntityProfileComponentType, EntityPropertyType, EntityViewType, IncludeAttachmentType } from '@ptg-entity-management/types/enums';
import { FundModel } from '@ptg-fund-list/models/fund-list.model';

import { DisplaySectionWODataType } from '../../types/enums';
import {
  MemberDetailWithPaging,
  MemberStatus,
  MetadataOverView,
} from '../../types/models';
import { MemberDetailActions } from '../../store/actions';
import * as fromMember from '../../store/reducers';
import { MemberSummaryCardListComponent } from '../member-summary-card-list/member-summary-card-list.component';
import { PROPERTY_DISPLAY } from '../../constants';
import { getColumConfig, getEntityPropertyName, getEntityValue } from '../../helper';
import { PropertyType } from '../../constance/metadataPropertyType.const';
import { Menu, MenuItem } from '@ptg-shared/layout/models/layout.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { MatDialog } from '@angular/material/dialog';
import { EntityViewState } from '@ptg-entity-management/store/reducers';
import { getEntityViewsByCardSelector, getEntityViewsByEntitySelector } from '@ptg-entity-management/store/selectors';
import {
  clearGetEntityViewsByCardStateAction,
  clearGetEntityViewsByEntityStateAction,
  getEntityViewsByCardAction,
  getEntityViewsByEntityAction,
} from '@ptg-entity-management/store/actions';
import { CardComponent, CardProperty, EntityViewItem } from '@ptg-entity-management/services/models';
import { CREATED_AT, CREATED_BY, GUID_EMPTY, UPDATED_AT, UPDATED_BY } from '@ptg-shared/constance';
import { FixedPropertyKey } from '@ptg-entity-management/constants';
const PAGE_SIZE_CONST = '-ptg-member-profile-section-pageSize';

@Component({
  selector: 'ptg-member-summary-card',
  templateUrl: './member-summary-card.component.html',
  styleUrls: ['./member-summary-card.component.scss'],
})
export class MemberSummaryCardComponent extends BaseComponent {
  @ViewChild('memberSummaryCardList') memberSummaryCardList!: MemberSummaryCardListComponent;
  readonly DisplaySectionWODataType = DisplaySectionWODataType;
  readonly PROPERTY_DISPLAY = PROPERTY_DISPLAY;

  isDemographics: boolean = false;
  currentFund: any = {};
  columns: Column[] = [];
  attachment: any;
  sortInfo: {} | any = {};
  lengthPg!: number;
  pageSize: number = 10;
  pageNumber: number = FIRST_PAGE;
  notFoundMessage: string = '';
  isDragDrop: boolean | undefined = false;
  cardComponents: CardComponent[] = [];
  dataCard: any;
  dataCardList: any[] = [];
  isShowButtonEdit = false;
  isShowButtonAdd = false;
  isShowButtonManage = false;
  memberNavigationMenu!: Menu[];
  attachmentType?: IncludeAttachmentType = IncludeAttachmentType.NoAttachment;
  targetReferenceLinkedId?: string;
  isLoading: boolean = true;
  recordId: string = '';
  entityReferenceLinkedId: string = '';

  @Input() configCard!: EntityViewItem;
  @Input() memberId!: string;
  @Input() entityId!: string;
  @Input() menuName!: string;
  @Input() memberStatus: MemberStatus | undefined;
  @Input() profileOverviewConfig: MetadataOverView | undefined;
  @Input() viewName!: string;
  @Input() viewUrl!: string;
  @Output() deleteSectionEvent = new EventEmitter();
  @Output() downloadPdfEvent = new EventEmitter();
  @Output() editCardEvent = new EventEmitter();
  @Output() manageSectionEvent = new EventEmitter();
  @Output() showAttachmentsEvent = new EventEmitter();
  @Output() viewDetailBenefitEntity = new EventEmitter();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private store: Store<fromReducer.State>,
    private memberStore: Store<fromMember.MemberState>,
    private entityViewStore: Store<EntityViewState>,
    private datePipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.memberStore
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.isReloading && state.memberId) {
          this.memberNavigationMenu = state.menu;
        }
      });
    this.cardComponents = this.configCard.cardComponents;
    this.isShowButtonEdit = !!this.cardComponents.find(
      (item) => item.propertyDisplay !== null && item.cardProperties.length > 0,
    );
    this.isShowButtonAdd = this.configCard.isShowButtonAdd && !!this.cardComponents.find(
      (item) => item.propertyDisplay === null && item.cardProperties.length > 0,
    );

    this.store.pipe(select(fromReducer.selectCurrentFundState), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.pageSize = el.defaultPageSize ?? 10;
      this.currentFund = el;
      this.pageSize =
        Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0
          ? this.pageSize
          : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
    });
    this.isDragDrop = this.configCard?.isDragDrop;
    this.attachmentType = this.configCard?.attachmentType;

    this.memberStore
      .pipe(select(fromMember.selectMemberDataSummaryState))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.isLoading = data ? data?.isLoading : true;
        const memberDetail = deepClone(data?.payload);
        if (memberDetail) {
          this.dataCard = memberDetail;
          this.cardComponents.forEach((card: any) => {
            let listDataMappingId: any;
            if (
              card?.componentType === EntityProfileComponentType['List - Detail View'] ||
              card?.componentType === EntityProfileComponentType['List - Summary View']
            ) {
              this._mapProppertiesToColumns(card?.cardProperties || []);
              
              listDataMappingId = (card?.cardProperties || []).map((element: any) => {
                return {
                  id: this._getColumnName(element),
                  type: element.type,
                  config: element.config,
                };
              });
            }
            Object.entries(memberDetail).forEach(([key, value]) => {
              if (this.configCard.cardId === key.substring(0, key.length - 5) && key.includes('_list') && value) {
                this.lengthPg = (value as any).total;
                this.dataCardList = ((value as any).rowData || [])?.map((item: any, indexRow: number) => {
                  const obj: any = {};
                  item?.values?.forEach((element: any, index: any) => {
                    let columnName = '';
                    if (element.entityReferencePropertyId) {
                      columnName += element.entityReferencePropertyId;
                    }
                    if (element.entityPropertyId) {
                      if (columnName) {
                        columnName += '_';
                      }
                      columnName += element.entityPropertyId;
                    }
                    if (element.option) {
                      if (columnName) {
                        columnName += '_';
                      }
                      columnName += element.option;
                    }
                    const options = listDataMappingId?.find((item: any) => item.id === columnName);
                    obj[`${columnName}`] = getEntityValue(element, options);
                    if (options?.config?.fixedKey === FixedPropertyKey.Payee) {
                      element.propertyType = EntityPropertyType['Entity Reference'];
                      if ( !element?.entityReferenceLinkedId) {
                        obj[`${columnName}_isDisableLink`] = true;
                      } else {
                        obj[`${columnName}_recordIdPayee`] = element.value;
                      }
                    }
                    if (element?.recordLinkedId) {
                      obj[`${columnName}_recordLinkedId`] = element?.recordLinkedId;
                    }

                    if (element?.propertyType === EntityPropertyType['Entity Reference']) {
                      obj[`${columnName}_recordId`] = element.recordId;
                    }

                    if (element?.isExistProfile != undefined) {
                      obj[`${columnName}_isExistProfile`] = element.isExistProfile;
                    }

                    if (element?.entityReferenceLinkedId) {
                      obj[`${columnName}_entityReferenceLinkedId`] = element?.entityReferenceLinkedId;
                    }
                    if (element?.options) {
                      obj['options'] = element?.options;
                    }
                    obj['order'] = indexRow;
                  });
                  return { ...obj, id: item.id, noOfAttachments: item?.noOfAttachments };
                });
                
              } else {

                if (value && this.configCard.cardId === key) {
                  card.cardProperties = card.cardProperties.map((prop: any) => {
                    let result = (value as any).results.find(
                      (item: any) => (item.entityPropertyId === prop.entityReferencePropertyId &&
                          item.entityReferencePropertyId === prop.propertyId));
                    if (!result) {
                      if (prop.option) {
                        result = (value as any).results.find((item: any) => item.entityPropertyId === prop.propertyId && item.option === prop.option);
                      } else {
                        result = (value as any).results.find((item: any) => item.entityPropertyId === prop.propertyId);
                      }
                    }
                    if (!result) {
                      result = (value as any).results.find((item: any) => (item.fixedPropertyKey === prop.option));
                    }
                    return {
                      ...prop,
                      value: [CREATED_AT, UPDATED_AT].includes(prop.option) ? this.datePipe.transform(getDateString(result?.value), 'MM/dd/yyyy hh:mm a') : this._getPropertyValue(result?.type, result?.value, prop.option, result?.options),
                      valueMask: result?.value,
                      isMasked: true,
                      options: result?.options,
                    };
                  });
                }
              }
            });
          });
          this.cardComponents = [...this.cardComponents];
        }
      });

      // Listen for get the list of entity views by entity id
      this.entityViewStore
      .pipe(
        select(getEntityViewsByEntitySelector),
        filter((state) => !!state),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (!state?.isLoading && state?.success && state?.payload) {
          const viewCard = state.payload.find((v) => v.type === EntityViewType.Detail);
          if (viewCard?.id && this.targetReferenceLinkedId) {
            this.viewDetailBenefitEntity.emit({
              entityId: viewCard.entityId,
              viewName: viewCard.viewName, 
              viewId: viewCard.id,
              recordId: this.targetReferenceLinkedId
            });
          }

          this.entityViewStore.dispatch(clearGetEntityViewsByEntityStateAction());
        }
      });
    // Listen for get the list of entity views by card id
    this.entityViewStore
      .pipe(
        select(getEntityViewsByCardSelector),
        filter((state) => !!state),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (!state?.isLoading && state?.success && state?.payload) {
          const viewCard = state.payload.find((v) => v.type === EntityViewType.Detail);
          if (viewCard?.id) {
            const url = `/member/detail-view/false/${viewCard.id}/${this.memberId}?${STRING_QUERY_PARAM.PROFILE_NAVIGATE}`;
            this.router.navigateByUrl(url);
          }

          this.entityViewStore.dispatch(clearGetEntityViewsByCardStateAction());
        }
      });

    // Listen for get current member profile navigation
    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.isHyperlink && state?.menu && state?.menu.length > 0 && this.recordId) {
          const overviewView = state.memberNavigationList as any;
          const isOverviewDetailView = (state.memberNavigationList as any)?.isOverviewDetailView;
          const url = `${isOverviewDetailView ? '/member/detail-view/true' : '/member/summary-view/true'}/${overviewView.id}/${overviewView.overviewViewId}/${this.recordId}?${STRING_QUERY_PARAM.PROFILE_NAVIGATE}&entityReferenceLinkedId=${this.entityReferenceLinkedId}`;
          const entityReferenceLinkedIdParam = new URLSearchParams(document.location.search).get("entityReferenceLinkedId");
          
          if(url.slice(0, url.indexOf('?')) === document.location.pathname 
            && this.entityReferenceLinkedId === entityReferenceLinkedIdParam) {
              this.router.navigateByUrl(url);
          }
          else{
            this.store.dispatch(LayoutActions.clearProfileNavigationItemState());
            this.store.dispatch(
              LayoutActions.selectTab({
                tab: DisplayedTabName.IndividualParticipant,
                url,
              }),
            );
          }
        }
      });
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    if (this.cardComponents.length === 1 && this.cardComponents[0].propertyDisplay === null) {
      const tableWidth = document.getElementsByClassName('demo-content')[0]?.getBoundingClientRect()?.width;
      if (tableWidth) {
        const columnWidth = tableWidth / this.columns.length;
        const elements = document.getElementsByClassName('mat-header-cell');
        for (let i = 0; i < elements.length; i++) {
          const div = elements[i];
          div.setAttribute('style', 'max-width:' + (columnWidth - 20) + 'px');
        }
        const tds = document.getElementsByClassName('mat-cell');
        for (let i = 0; i < tds.length; i++) {
          const div = tds[i];
          div.setAttribute('style', 'max-width:' + (columnWidth - 35) + 'px');
        }
      }
    }
  }

  private _getPropertyValue(type: EntityPropertyType, value: any, option: string, options: any) {
    if (type === EntityPropertyType.Status) {
      return this._getStatusValue(value, option, options);
    } else if (type === EntityPropertyType.Address || type === EntityPropertyType['Person Name']) {
      if (value && typeof value === 'object' && Object.values(value).every((item) => item === null)) return null;
      return value;
    } else if (value?.maskedValue || value?.maskedValue === null) {
      return value?.maskedValue;
    } else if (value && type === EntityPropertyType['Date Time']) {
      return getDateString(value);
    }
    return value;
  }

  private _getStatusValue(value: any, option: string, options: any) {
    const status = options?.find((item: any) => item.id === value?.status);
    if (option === 'Name') {
      return status?.name;
    } else if (option === 'Event') {
      return status?.events?.find((item: any) => item.id === value?.event)?.name;
    } else {
      return value?.statusDate ? new DatePipe('en-US').transform(value?.statusDate, 'MM/dd/yyyy') : null;
    }
  }

  deleteSection(): void {
    this.deleteSectionEvent.emit(this.configCard);
  }

  editCard(isAddRecord?: boolean): void {
    this.editCardEvent.emit({ configCard: this.configCard, isAddRecord: isAddRecord });
  }

  manageSection(): void {
    this.manageSectionEvent.emit(this.configCard);
  }

  downloadPdf(statement: any): void {
    this.downloadPdfEvent.emit(statement);
  }

  showAttachments(): void {
    this.showAttachmentsEvent.emit(this.configCard);
  }

  onSortChange(event: Sort): void {
    this.sortInfo = event;
    this.getMemberSection();
  }

  onChangePage(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;

    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString());
    this.getMemberSection();
  }

  private getMemberSection(): void {
    this.isLoading = true;
    let sortType = 1;
    let sortNames = '';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = this.sortInfo.active;
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: MemberDetailWithPaging = {
      pageIndex: this.pageNumber,
      totalPerPage: this.pageSize,
      sortField: sortNames,
      sortType: sortType,
    };
    this.memberStore.dispatch(
      MemberDetailActions.getMemberDataCardAction({
        memberId: this.memberId,
        cardId: `${this.configCard.cardId}_list`,
        entityId: this.entityId,
        isSummary: true,
        query: query,
      }),
    );
  }

  private _mapProppertiesToColumns(entity: CardProperty[]) {
    this.columns = [];
    const optColDateList = [CREATED_AT, UPDATED_AT];

    entity?.forEach((column: CardProperty) => {
      if (column?.config?.fixedKey === FixedPropertyKey.Payee) {
        column.type = EntityPropertyType['Entity Reference'];
      }
      const columConfig = getColumConfig(getEntityPropertyName(column.type), column.config);
      const typeDateColFixed = {
        format: 'MM/dd/yyyy hh:mm a'
      }
      
      this.columns.push({
        name: this._getColumnName(column),
        header: {
          title: column.propertyLabel,
        },
        truncate: true,
        type: [
          ColumnType.Address,
          ColumnType.PersonName,
          ColumnType.Binary,
        ].includes(columConfig.type as ColumnType) ? ColumnType.Text : optColDateList.includes(column.option) ? ColumnType.DateTime : columConfig.type,
        align: column.type === PropertyType.TYPE_REGISTER ? Align.Center : undefined,
        templateArgs:
          columConfig.type === ColumnType.EntityReference
            ? {
                onClick: (row: FundModel & Row) => {
                  this._onClickEntityReference(row, column);
                },
              }
            : optColDateList.includes(column.option) ? typeDateColFixed
            : columConfig.templateArgs,
      });
    });

    if (this.attachmentType === IncludeAttachmentType.ListRecord) {
      this.columns.push({
        name: 'noOfAttachments',
        header: {
          title: 'Attachment',
        },
        align: Align.Right,
      });
    }
  }

  private _getColumnName(column: CardProperty): string {
    let columnName = '';
    const optColNameList = [UPDATED_BY, CREATED_AT, CREATED_BY, UPDATED_AT];
    if(optColNameList.includes(column.option)){
      columnName = GUID_EMPTY;
    } else {
      columnName = column.propertyId;
    }
    if (column.entityReferencePropertyId) {
      columnName = columnName + '_' + column.entityReferencePropertyId;
    }
    if (column.option) {
      columnName = columnName + '_' + column.option;
    }

    return columnName;
  }

  private _onClickEntityReference(row: any & Row, column: CardProperty) {
    const propertyName = this._buildPropertyName(column);
    const recordLinkedId = row[`${propertyName}_recordLinkedId`];
    this.entityReferenceLinkedId = row[`${propertyName}_entityReferenceLinkedId`];

    if (this.configCard.isBenefitCard) {
      if (this.entityReferenceLinkedId) {
        this.entityViewStore.dispatch(getEntityViewsByEntityAction({ entityId: this.entityReferenceLinkedId }));
        this.targetReferenceLinkedId = recordLinkedId;
      }
    } else {
      const recordIdPayee = row[`${propertyName}_recordIdPayee`];

      this.recordId = row[`${propertyName}_${recordIdPayee ? 'recordIdPayee' : 'recordId'}`];
      this.store.dispatch(
        LayoutActions.profileNavigationItemRequest({
          memberId: this.recordId,
          entityReferenceLinkedId: this.entityReferenceLinkedId,
          isHyperlink: true
        })
      );
    }
  }

  private _buildPropertyName(prop: CardProperty) {
    let propertyName = '';
    if (prop.entityReferencePropertyId) {
      propertyName += prop.entityReferencePropertyId;
    }
    if (prop.propertyId) {
      if (propertyName) {
        propertyName += '_';
      }
      propertyName += prop.propertyId;
    }
    if (prop.option) {
      if (propertyName) {
        propertyName += '_';
      }
      propertyName += prop.option;
    }
    return propertyName;
  }

  onRowClick(row: any & Row): void {
    if (this.configCard?.isExistDetailConfig || this.configCard?.viewFixType != null) {
      this.memberStore
        .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
        .subscribe((state) => {
          if (state.isReloading && state.memberId) {
            const allMenuItems = this.memberNavigationMenu.reduce((acc: MenuItem[], menu: Menu) => {
              return [...acc, ...(menu?.menuItems ?? [])];
            }, []);
            const menuSameCard = allMenuItems.find(
              (menuitem) =>
                menuitem.cardId?.length === 1 && ((menuitem.cardId?.includes(this.configCard.cardId) && menuitem?.isDetailView) ||
                (menuitem.viewFixType !== null && menuitem.viewFixType === this.configCard.viewFixType)),
            );
            let baseUrl = `member/detail-view/false/${GUID_EMPTY}/${GUID_EMPTY}`;
            if (menuSameCard) {
              baseUrl = menuSameCard.routerLink;
            }
            this.router.navigateByUrl(`${baseUrl
              .split('/')
              .concat([state.memberId])
              .filter((x) => x)
              .join('/')}?${STRING_QUERY_PARAM.PROFILE_NAVIGATE}&currentRowIndex=${row?.order}&cardId=${this.configCard.cardId}&prevName=${
                encodeURIComponent(this.viewName)
              }&prevUrl=${
                encodeURIComponent(this.viewUrl)
              }&currentRowId=${
                row.id
              }`);
          }
        });
    } else {
      this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          title: 'Warning',
          text: 'The Detailed View is Not Configured',
          type: ConfirmType.Warning,
          cancelButtonTitle: 'Close',
          hideConfirmButton: true,
        },
      });
    }
  }
}
