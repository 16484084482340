import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FundModel } from '@ptg-fund-list/models/fund-list.model';
import * as fromReducer from '@ptg-reducers';
import { BaseComponent } from '@ptg-shared/components';
import { MenuItem } from '@ptg-shared/layout/models/layout.model';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { FundType } from '@ptg-shared/types/enums';
import { deepClone, deepEqual } from '@ptg-shared/utils/common.util';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LayoutService extends BaseComponent {
  currentFund$: BehaviorSubject<FundModel> = new BehaviorSubject<FundModel>({} as any);
  activeMenuItem$: BehaviorSubject<MenuItem> = new BehaviorSubject<MenuItem>({} as any);
  isShowLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  set showLoading(isLoading: boolean) {
    this.isShowLoading.next(isLoading);
  }

  constructor(private store: Store<fromReducer.State>) {
    super();
    this.getCurrentFund();
    this.getCurrentMenuGroups();
  }

  get fundType() {
    return this.currentFund$.value.fundType;
  }

  get fundKey() {
    return this.currentFund$.value.key;
  }

  get fundId() {
    return this.currentFund$.value.id;
  }

  get employerLabel() {
    switch (this.currentFund$?.value?.fundType) {
      case FundType.BVFF:
        return 'Municipality';
      default:
        return 'Employer';
    }
  }

  getCurrentFund() {
    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentFund) => {
        this.currentFund$.next(currentFund);
      });
  }

  getCurrentMenuGroups() {
    combineLatest([
      this.store
        .select(fromLayoutReducer.selectCurrentMenuGroupsState)
        .pipe(distinctUntilChanged((previous, current) => deepEqual(previous, current))),
      this.store.select(fromLayoutReducer.selectCurrentUrlState),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([currentMenuGroups, url]) => {
        const selectedMenuGroup = deepClone(currentMenuGroups)?.find((item) => item.isSelected);
        const selectedMenuItem =
          ([] as MenuItem[])
            .concat(...(deepClone(selectedMenuGroup?.menu) ?? []).map(({ menuItems }) => menuItems || []))
            .find((item) => item.routerLink === url) ?? {};
        this.activeMenuItem$.next(selectedMenuItem as MenuItem);
      });
  }
}
