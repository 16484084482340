import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import {History, RelatedPerson } from '@ptg-member/types/models';

import {
  FIRST_PAGE,
  PageEvent,
  PaginationComponent,
} from '../../controls/pagination';
import { iconGenerateOutline } from '../../constance/listIcons.const';
import { Column, Row } from '../grid';
import { DocDetail } from '@ptg-member/types/models/participant-documents.model';

@Component({
  selector: 'ptg-list-display-box',
  templateUrl: './list-display.component.html',
  styleUrls: ['./list-display.component.scss'],
})
export class ListDisplayComponent implements OnInit {
  @Input() dataTable: (History | RelatedPerson | DocDetail)[] = [];
  @Input() columns!: Column[];
  @Input() sortInfo: any = {};
  @Input() lengthPg!: number | any;
  @Input() pageSize: number = 50;
  @Input() pageNumber: number = FIRST_PAGE;
  @Input() isLoading!: boolean;
  @Input() currentRowIndex: number = 0;
  @Input() title: string = '';
  @Input() pageName: string = '';
  @Input() btnAddTitle: string = '';
  @Input() btnGenerate: string = '';
  @Input() isDocument!: boolean;
  @Input() isAddress?: boolean;
  @Input() isAddressHistory?: boolean;
  @Input() paginable: boolean = true;
  @Input() isHideNotFoundMessage: boolean = false;
  @Input() isGenerateReport!: boolean;
  @Input() btnAddDisabilityPayment: string = '';

  @Output() selectRow = new EventEmitter();
  @Output() sortChange = new EventEmitter();
  @Output() changePage = new EventEmitter();
  @Output() addItem = new EventEmitter();
  @Output() generateRecord = new EventEmitter();
  @Output() removeClick = new EventEmitter<any & Row>();
  @Output() downloadDocumentEvent = new EventEmitter();
  @Output() uploadDocumentEvent = new EventEmitter();
  @Output() manageDocumentList = new EventEmitter();
  @ViewChild(PaginationComponent) paginator!: PaginationComponent;
  @Output() addDisabilityPayment = new EventEmitter();

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral(
      'generate-icon-outline',
      sanitizer.bypassSecurityTrustHtml(iconGenerateOutline)
    );
  }

  ngOnInit(): void {}

  onSelectRow(event: any) {
    this.selectRow.emit(event);
  }

  onSortChange(event: any) {
    this.sortChange.emit(event);
  }

  onChangePage(event: PageEvent) {
    this.changePage.emit(event);
  }

  onClickAddItem() {
    this.addItem.emit();
  }

  onClickGenerateRecord() {
    this.generateRecord.emit();
  }

  onClickAddDisabilityPayment() {
    this.addDisabilityPayment.emit();
  }

  onClickRemove(row: any){
    this.removeClick.emit(row)
  }

  downloadDocument(row: any) {
    this.downloadDocumentEvent.emit(row)
  }

  onUploadDocument(event: any) {
    this.uploadDocumentEvent.emit(event);
  }

  onManageListDocument(event: any){
    this.manageDocumentList.emit(event)
  }

}
