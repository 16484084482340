<div [ngClass]="{'overlay': isDisabledMenu}">
  <ptg-header></ptg-header>
  <ptg-captionbar (changeSelectedTab)="changeSelectedTab($event)"
                  (logoutAction)="logout()"
                  (closeSideMenu)="closeSideMenu()"
                  [(sidebarOpenedStatus)]="sidebarOpenedStatus"
                  [tabs]="tabs"></ptg-captionbar>
</div>
<div class="content">
  <div class="left-content"
       [ngClass]="{ 'sidebar-opened': sidebarOpenedStatus === SIDE_BAR_OPENED_STATUS_ENUM.Opened, 'sidebar-closed': sidebarOpenedStatus === SIDE_BAR_OPENED_STATUS_ENUM.Closed }"
  >
    <ptg-sidebar (sidebarOpenedStatusChange)="changeSidebarOpenedStatus($event)"
                 *ngIf="!checkPermissionService.isNoPermission"
                 [(sidebarOpenedStatus)]="sidebarOpenedStatus"
                 [menuGroups]="menuGroups"
                 [ngClass]="{'overlay': isDisabledMenu}"
                 [isDisabled]="disabledSideBar"
    ></ptg-sidebar>
  </div>

  <div #mainContent
       (scroll)="onScrollMainContent()"
       class="main-content"
       [ngClass]="{ 'sidebar-opened': sidebarOpenedStatus === SIDE_BAR_OPENED_STATUS_ENUM.Opened, 'sidebar-closed': sidebarOpenedStatus === SIDE_BAR_OPENED_STATUS_ENUM.Closed }"
  >
    <ng-content></ng-content>
  </div>
</div>
<div
  #globalLoadingIndicator
  *ngIf="layoutService.isShowLoading.value"
  class="bg-white/50 z-[9999] absolute {{ loadingCustomClass }} !top-0"
>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
