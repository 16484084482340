import { createReducer, on } from '@ngrx/store';

import { Menu, MenuItem, ViewFixType } from '@ptg-shared/layout/models/layout.model';
import { ItemMenuType } from '@ptg-shared/layout/constance/layout.const';
import {
  MemberNavigationList,
  MemberNavigationResponse,
} from '@ptg-member/types/models';
import { SectionLayout } from '@ptg-member/constance/metadata.const';

import { LayoutActions } from '../actions';
import { deepClone } from '@ptg-shared/utils/common.util';
import { GUID_EMPTY } from '@ptg-shared/constance';

export const ProfileNavigationFeatureKey = 'profileNavigation';

export interface State {
  isReloading: boolean;
  menu: Menu[];
  memberNavigationList: MemberNavigationList;
  memberId: string;
  profileName: string;
  isRelatedPerson?: boolean,
  isHyperlink?: boolean,
}

const initialState: State = {
  isReloading: false,
  menu: [],
  memberNavigationList: {} as MemberNavigationList,
  memberId: '',
  profileName: '',
  isRelatedPerson: false,
  isHyperlink: false
};

export const reducer = createReducer(
  initialState,
  on(LayoutActions.profileNavigationItemRequest, (state, { isHyperlink }) => {
    return isHyperlink ? state : {
      ...state,
      isReloading: false,
      menu: [],
      memberNavigationList: {} as any,
    };
  }),
  on(
    LayoutActions.profileNavigationItemSuccess,
    (state, { memberNavigationList, memberId, isRelatedPerson, isHyperlink }) => {
      // Note: Store isn't changed when the hyperlink is clicked and the entity doesn't have a profile configured
      if (isHyperlink && memberNavigationList?.id === GUID_EMPTY) {
        return state;
      }

      // Note: Switch between BVFF/ChicagoParks fund and other funds
      if (('fundType' in memberNavigationList)) {
        const navigationList = deepClone(memberNavigationList as any);
        const profileName = navigationList.profileName;
        const menu = navigationList?.memberNavigationMenu?.map(
          (memberNavigationResponse: any) => {
            const menuItem: Menu = {
              title: memberNavigationResponse.isSingleView ? '' : memberNavigationResponse.name,
              style: `color:${memberNavigationResponse.color} !important`,
              isSingleView: memberNavigationResponse?.isSingleView,
              menuItems: memberNavigationResponse?.menuItems?.map((item: any) => {
                var url = Object.keys(item).some(x => x === 'isDetailView')
                ? (item.isDetailView ? `member/detail-view/false/${item.id}/${item.entityViewId}` : `/member/summary-view/false/${item.id}/${item.entityViewId}/`)
                : `/member/navigation/${item.entityViewId}`;
                switch (item.viewFixType) {
                  case ViewFixType.ParticipantRelationships:
                    url = `/member/system-view/participant-relationships/${item.id}/${item.entityViewId}/`;
                    break;
                  case ViewFixType.PaymentInformation:
                    url = `/member/system-view/payment-information/${item.id}/${item.entityViewId}/`;
                    break;
                  case ViewFixType.StatusHistory:
                    url = `/member/system-view/status-history/${item.id}/${item.entityViewId}/`;
                    break;
                  case ViewFixType.ServiceHistory:
                    url = `/member/system-view/service-history/${item.id}/${item.entityViewId}/`;
                    break;
                  case ViewFixType.Payments:
                    url = `/member/system-view/payments/${item.id}/${item.entityViewId}/`;
                    break;
                  case ViewFixType.AddressHistory:
                    url = `/member/system-view/address-history/${item.id}/${item.entityViewId}/`;
                    break;
                }
                const subMenu: MenuItem = {
                  name: item.name,
                  routerLink: url,
                  routerLinkExpand: [url],
                  viewFixType: item.viewFixType,
                  cardId: item.cardIdList,
                  isDetailView: item.isDetailView,
                  ...(item?.viewFixType != null ? { viewFixType: item.viewFixType } : {})
                };
                return subMenu;
              }),
            };
            return menuItem;
          }
        );
        if (navigationList.profileName) {
          const url = navigationList.isOverviewDetailView ? `member/detail-view/true/${navigationList.id}/${navigationList.overviewViewId}` : `/member/summary-view/true/${navigationList.id}/${navigationList.overviewViewId}/`;
          menu.unshift({
            title: '',
            menuItems: [
              {
                name: navigationList.overviewLabel,
                routerLink: url,
                routerLinkExpand: [url],
                isOverView: !navigationList.isOverviewDetailView
              }
            ]
          });
        }
        return {
          ...state,
          isReloading: true,
          memberId,
          memberNavigationList,
          menu: menu,
          profileName: profileName,
          isRelatedPerson,
          isHyperlink
        } as any;
      } else {
        // TODO: check after move logic
        const listMemberNavigation =
          memberNavigationList?.memberNavigationMenu.filter(
            (item: MemberNavigationResponse) => item?.active
          );
        const menu = (listMemberNavigation || []).map(
          (memberNavigationResponse: MemberNavigationResponse) => {
            const menuItem: Menu = {
              title: memberNavigationResponse.name,
              style: `color:${memberNavigationResponse.color} !important`,
              menuItems: memberNavigationResponse?.menuItem?.map((item) => {
                const subMenu: MenuItem = {
                  name: item.name,
                  routerLink: `/member/navigation/${item.type}/${item.id}/`,
                };
                if (item.type === SectionLayout.Payment) {
                  subMenu.type = ItemMenuType.NextPayment;
                } else if (item.type === SectionLayout.PaymentHistory) {
                  subMenu.type = ItemMenuType.PaymentHistory;
                } else if (
                  item.type === SectionLayout.MunicipalityServiceRecord
                ) {
                  subMenu.type = ItemMenuType.MunicipalityServiceRecord;
                }
                return subMenu;
              }),
            };
            return menuItem;
          }
        );
        return {
          ...state,
          isReloading: true,
          memberId,
          memberNavigationList,
          menu,
          isRelatedPerson,
          isHyperlink
        } as any;
      }
    }
  ),
  on(LayoutActions.profileNavigationItemFailure, (state, { errorMsg }) => ({
    ...state,
    isReloading: true,
    menu: [],
    memberNavigationList: {} as MemberNavigationList,
  })),
  on(LayoutActions.clearProfileNavigationItemState, (state) => {
    return {
      ...state,
      menu: [],
      memberNavigationList: {} as MemberNavigationList,
    };
  })
);
