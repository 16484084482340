import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CurrencyMaskInputMode } from 'ngx-currency';

import {
  FORMAT_PHONE_NUMBER,
  InputType,
  LIMIT_PHONE_NUMBER,
  LIMIT_SSN_NUMBER,
} from '@ptg-member/constance/metadataPropertyType.const';

import { EIN_NUMBER, EMAIL, NON_ZERO_CURRENCY, PHONE_NUMBER, SSN_NUMBER } from '../../constance/regex.const';
import { ELEMENT_TYPE } from '../../constance/value.const';
import { getElementId } from '../../utils/string.util';
import { checkApiValidator } from '../../validators/checkApi.validator';
import { AsyncFunction } from '../../types/models/common.model';

@Component({
  selector: 'ptg-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
})
export class TextboxComponent implements OnInit, OnChanges, OnDestroy {
  readonly InputType = InputType;
  errorMaxLength = '';
  errorMin = '';
  errorMax = '';
  errorBetween = '';
  isMatInput = true;
  currentVal = '';
  currencyMaskInputMode = CurrencyMaskInputMode;
  icon?: string;
  inputId: string = '';
  matErrorId: string = '';
  unsubscribe$ = new Subject<void>();
  isMasked: boolean = true;
  maskExpression: string = '';
  customPatterns!: any;
  focused: boolean = false;
  firstFocus: boolean = true;

  @Input() controlField!: AbstractControl | any;
  @Input() placeholder!: string;
  @Input() maxLength?: number;
  @Input() minLength?: number;
  @Input() hasLabel?: boolean = false;
  @Input() pattern?: string | RegExp;
  @Input() min?: number;
  @Input() max?: number;
  @Input() isRequired: boolean = false;
  @Input() isDisabled?: boolean = false;
  @Input() class?: string;
  @Input() inputType?: string;
  @Input() customError: string = '';
  @Input() errorArray: string = '';
  @Input() errorAsync?: string;
  @Input() asyncFn?: AsyncFunction;
  @Input() customValidator?: any;
  @Input() mask: string = 'separator';
  @Input() thousandSeparator: string = '';
  @Input() allowNegativeNumbers: boolean = true;
  @Input() isAutoLeadingZeros: boolean = false;
  @Input() isValidation: boolean = true;
  @Input() isPercentage?: boolean;
  @Input() isPositive: boolean = false;
  @Input() isDecimal: boolean = true;
  @Input() allowZero: boolean = true;
  @Input() isStrict: boolean = true;
  @Input() errorPattern = 'Invalid format.';
  @Input() errorMinMaxValue?: string;
  @Input() errorRequire?: string = '';
  @Input() errorDuplicated?: string;
  @Input() showError: boolean = true;
  @Input() suffix?: string = '';
  @Input() prefix?: string = '$ ';
  @Input() customPrefix?: string = '';
  @Input() errorMinMax?: string = '';
  @Input() keepErrorMinMax?: boolean = true;
  @Input() precision?: number = 2;
  @Input() restrictedRegex: string = '';
  @Input() checkMinMaxValue?: boolean = true;
  @Input() isShowIconMasked: boolean = false;
  @Input() minErrorMessage?: string;
  @Input() maxErrorMessage?: string;
  @Input() hintText: string = '';
  @Input() isShowHintText: boolean = false;
  @Input() excludeZeroCurrencyFromEstimator: boolean = false;
  @Input() isNotAllowSpecialCharacter?: boolean = false;
  @Input() width?: string;
  @Input() formatValue: boolean = false;
  @Input() isFocusOut: boolean = false;
  @Input() isNumberOnly?: boolean = false;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onBlur: EventEmitter<void> = new EventEmitter<void>();
  @Output() onFocus: EventEmitter<void> = new EventEmitter<void>();
  @Output() onOutFocus: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeControlValue = new EventEmitter();
  @Output() showHintText: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onKeydown: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('inputField') inputField: any;
  @ViewChild('defaultInputField') defaultInputField: any;

  constructor() {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    // validate by type
    switch (this.inputType) {
      case InputType.TYPE_EMAIL:
        this.maxLength = 255;
        this.setMaskExpression();
        this.controlField.addValidators(Validators.pattern(EMAIL));
        this.errorPattern = `Invalid Email format.`;
        this.icon = 'mail_outline';
        break;
      case InputType.TYPE_PHONE:
        this.formatPhoneNumberInput(this.controlField);
        this.maxLength = 30;
        this.controlField.addValidators(Validators.pattern(PHONE_NUMBER));
        this.errorPattern =
          this.errorPattern && this.errorPattern !== 'Invalid format.'
            ? this.errorPattern
            : `Invalid Phone Number format.`;
        this.icon = 'phone_iphone';
        break;
        case InputType.TYPE_ZIP_CODE:
          this.errorPattern = 'The ZIP Code/Postal Code must follow the format XXXXX-XXXX or XXXXX.';
        break;
      case InputType.TYPE_FAX:
        this.formatFaxNumberInput(this.controlField);
        this.maxLength = 30;
        this.controlField.addValidators(Validators.pattern(PHONE_NUMBER));
        this.errorPattern = `Invalid Fax format.`;
        this.icon = 'phone_iphone';
        break;
      case InputType.TYPE_SSN:
        this.formatSSNNumberInput(this.controlField);
        this.controlField.addValidators(Validators.pattern(SSN_NUMBER));
        this.errorPattern = `${this.placeholder} must have 9 digits.`;
        this.maxLength = 11;
        break;
      case InputType.TYPE_EIN:
        this.formatEINNumberInput(this.controlField);
        this.controlField.addValidators(Validators.pattern(EIN_NUMBER));
        this.errorPattern = `${this.placeholder} must have 9 characters.`;
        this.maxLength = 9;
        break;
      case InputType.TYPE_CURRENCY:
        this.currencyMaskInputMode = CurrencyMaskInputMode;
        if (this.excludeZeroCurrencyFromEstimator) {
          this.controlField.addValidators(Validators.pattern(NON_ZERO_CURRENCY));
          this.errorPattern = "You must specify your Annual Average Salary at Retirement.";
        }
        break;
      case InputType.TYPE_DECIMAL:
        break;
      case InputType.TYPE_NUMBER:
        if (this.checkMinMaxValue) {
          if (this.min === undefined || !isFinite(this.min)) {
            this.min = this.isPositive ? 0 : -2147483647;
          }

          if (this.max === undefined || !isFinite(this.max)) {
            this.max = 2147483647;
          }
        }
        break;
      case InputType.TYPE_PASS_WORD:
        this.setMaskExpression();
        break;
    }
    if (this.isShowIconMasked) {
      this.icon = 'visibility_off';
    }
    if (this.isNotAllowSpecialCharacter) {
      this.controlField?.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value: any) => {
          let valueCleaned = ('' + value).replace(/[^0-9a-zA-Z]/g, '');
          if (valueCleaned !== value) {
            this.controlField?.setValue(valueCleaned);
          }
        });
    }
    this.addValidation();
    this.setSelectedValue();
  }

  addValidation() {
    if (this.customValidator) {
      this.controlField.addValidators(this.customValidator);
    }

    if (this.isRequired || this.errorRequire) {
      this.controlField.addValidators(Validators.required);
    }

    if (this.maxLength) {
      this.controlField.addValidators(
        Validators.maxLength(Number(this.maxLength))
      );
      this.errorMaxLength = `Exceed the ${this.maxLength} character limit.`;
    }

    if (this.pattern) {
      this.controlField.addValidators(Validators.pattern(this.pattern));
    }

    if (this.isDisabled) {
      this.controlField.disable();
    }

    if (this.asyncFn && this.asyncFn.function && this.asyncFn.fieldCheck) {
      this.controlField.addAsyncValidators(
        checkApiValidator(
          this.asyncFn.function,
          this.asyncFn.fieldCheck,
          this.asyncFn.editValue,
          this.asyncFn.config
        )
      );
    }

    if (
      this.max !== undefined &&
      isFinite(this.max) &&
      this.min === undefined
    ) {
      this.controlField.addValidators(Validators.max(this.max));
      this.errorMinMax = this.errorMinMax
        ? this.errorMinMax
        : `${this.placeholder} must be lower than ${this.max}.`;
    }

    if (
      this.min !== undefined &&
      isFinite(this.min) &&
      this.max === undefined
    ) {
      this.controlField.addValidators(Validators.min(Number(this.min)));
      this.errorMinMax = this.errorMinMax
        ? this.errorMinMax
        : `${this.placeholder} must be higher than ${this.min}.`;
    }

    if (
      this.min !== undefined &&
      isFinite(this.min) &&
      this.max !== undefined &&
      isFinite(this.max)
    ) {
      this.controlField.addValidators(Validators.max(Number(this.max)));
      this.controlField.addValidators(Validators.min(Number(this.min)));
      this.errorMinMax = this.errorMinMax
        ? this.errorMinMax
        : `${this.placeholder} must be within the range of ${this.min} - ${this.max}.`;

    }
    if (!this.minErrorMessage) {
      this.minErrorMessage = this.errorMinMax;
    }

    if (!this.maxErrorMessage) {
      this.maxErrorMessage = this.errorMinMax;
    }

    if (this.restrictedRegex) {
      this.controlField.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value: string) => {
          const valueCleaned = value?.replace(
            new RegExp(this.restrictedRegex, 'g'),
            ''
          );
          if (valueCleaned !== value) {
            this.controlField.setValue(valueCleaned);
            this.onValueChange();
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isMasked && (changes.controlField || changes.isShowIconMasked)) {
      this.setMaskExpression();
    }
    if (changes && changes.isDisabled && changes.isDisabled.currentValue) {
      this.controlField?.disable();
    } else if (
      changes &&
      changes.isDisabled &&
      !changes.isDisabled.currentValue
    ) {
      this.controlField?.enable();
    }

    if (changes.placeholder) {
      this.inputId = getElementId(ELEMENT_TYPE.INPUT, this.placeholder || '');
      this.matErrorId = getElementId(
        ELEMENT_TYPE.MAT_ERROR,
        this.placeholder || ''
      );
    }

    if (changes.pattern?.currentValue) {
      this.controlField.addValidators(Validators.pattern(changes.pattern.currentValue));
    }

    if (changes.max?.currentValue && isFinite(changes.max.currentValue) && !changes.min?.currentValue) {
      const newMaxValue = changes.max.currentValue;
      this.controlField.addValidators(Validators.max(Number(newMaxValue)));
      !this.keepErrorMinMax && (this.errorMinMax = `${this.placeholder} must be lower than ${newMaxValue}.`);
    }

    if (changes.min?.currentValue && isFinite(changes.min.currentValue) && !changes.max?.currentValue) {
      const newMinValue = changes.min.currentValue;
      this.controlField.addValidators(Validators.min(Number(newMinValue)));
      !this.keepErrorMinMax && (this.errorMinMax = `${this.placeholder} must be higher than ${newMinValue}.`);
    }

    if (
      changes.min?.currentValue &&
      isFinite(changes.min.currentValue) &&
      changes.max?.currentValue &&
      isFinite(changes.max.currentValue)
    ) {
      const newMaxValue = changes.max.currentValue;
      const newMinValue = changes.min.currentValue;
      this.controlField.addValidators(Validators.max(Number(newMaxValue)));
      this.controlField.addValidators(Validators.min(Number(newMinValue)));
      !this.keepErrorMinMax &&
        (this.errorMinMax = `${this.placeholder} must be within the range of ${newMinValue} - ${newMaxValue}.`);
    }

    if (!changes.minErrorMessage?.currentValue) {
      this.minErrorMessage = this.errorMinMax;
    }

    if (!changes.maxErrorMessage?.currentValue) {
      this.maxErrorMessage = this.errorMinMax;
    }
  }

  trimValue() {
    if (this.controlField.value) {
      this.controlField.setValue(this.controlField.value.trim());
      if (this.formatValue && this.controlField.value.length) {
        const replaceSpace = this.controlField.value.replace(/\s/g, ',');
        const coverString = replaceSpace.split(',').filter((value: string) => value.length).join(',');
        this.controlField.setValue(coverString);
        this.onBlur.emit();
      }
    }
  }

  formatFaxNumberInput(formControl: FormControl) {
    formControl?.valueChanges.subscribe((value) => {
      let valueCleaned = ('' + value).replace(/[^0-9\(\)\.\-\ ]/g, '');
      if (valueCleaned !== value) {
        formControl?.setValue(valueCleaned);
      }

      if (!FORMAT_PHONE_NUMBER.test(formControl.value)) {
        let cleaned = ('' + value).trim().replace(/[^0-9]/g, '');
        if (/^([0-9]{10})$/.test(cleaned)) {
          let match = cleaned.match(LIMIT_PHONE_NUMBER);
          if (match) {
            formControl?.setValue(
              '(' + match[1] + ') ' + match[2] + '-' + match[3],
              { emitEvent: false }
            );
          }
        }
      }
    });
  }

  formatPhoneNumberInput(formControl: FormControl) {
    formControl?.valueChanges.subscribe((value) => {
      let valueCleaned = ('' + value).replace(/[^0-9\(\)\.\-\ ]/g, '');
      if (valueCleaned !== value) {
        formControl?.setValue(valueCleaned);
      }

      setTimeout(() => {
        const cleaned = ('' + value).trim().replace(/[^0-9]/g, '');
        if (!cleaned) {
          formControl?.setValue('', { emitEvent: false });
        } else if (/^([0-9]{10})$/.test(cleaned)) {
          let match = cleaned.match(LIMIT_PHONE_NUMBER);
          if (match) {
            formControl?.setValue(
              '(' + match[1] + ') ' + match[2] + '-' + match[3],
              { emitEvent: false }
            );
          }
        }
      });
      this.setMaskExpression();
    });
  }

  formatSSNNumberInput(formControl: FormControl) {
    formControl?.valueChanges.subscribe((value) => {
      let valueCleaned = ('' + value).replace(/[^0-9\-]/g, '');
      if (valueCleaned !== value) {
        formControl?.setValue(valueCleaned);
      }

      setTimeout(() => {
        const cleaned = ('' + value).trim().replace(/[^0-9]/g, '');
        if (!cleaned) {
          formControl?.setValue('', { emitEvent: false });
        } else if (/^([0-9]{9})$/.test(cleaned)) {
          const match = cleaned.match(LIMIT_SSN_NUMBER);
          if (match) {
            this.controlField?.setValue(
              match[1] + '-' + match[2] + '-' + match[3],
              { emitEvent: false }
            );
          }
        }
      });
      this.setMaskExpression();
    });
  }

  formatEINNumberInput(formControl: FormControl) {
    formControl?.valueChanges.subscribe((value) => {
      let valueCleaned = ('' + value).replace(/[^0-9]/g, '');
      if (valueCleaned !== value) {
        formControl?.setValue(valueCleaned);
      }

      setTimeout(() => {
        const cleaned = ('' + value).trim().replace(/[^0-9]/g, '');
        if (!cleaned) {
          formControl?.setValue('', { emitEvent: false });
        } else if (/^([0-9]{9})$/.test(cleaned)) {
          const match = cleaned.match(EIN_NUMBER);
          if (match) {
            this.controlField?.setValue(
              match[1],
              { emitEvent: false }
            );
          }
        }
      });
    });
  }

  setNumberValue() {
    this.onBlur.emit();
  }

  onFocusField() {
    this.onFocus.emit();
    this.focused = true;
    if (this.isShowHintText) {
      if (this.firstFocus) {
        this.controlField.setErrors(null);
        this.showHintText.emit(true);
      } else {
        this.showHintText.emit(!this.controlField.errors);
      }
    }
    this.firstFocus = false;
  }

  onFocusOut(event: any) {
    const val = event.target.value;
    // Auto leading zeros by maxlength
    if (
      this.isAutoLeadingZeros &&
      this.maxLength &&
      this.maxLength > 0 &&
      val &&
      val?.trim() !== ''
    ) {
      this.controlField.setValue(
        ('0'.repeat(this.maxLength) + val).slice(-Math.abs(this.maxLength))
      );
    }

    if (
      this.isPercentage &&
      this.controlField.value &&
      this.controlField.value.endsWith('.')
    ) {
      this.controlField.setValue(
        this.controlField.value.substring(0, this.controlField.value.length - 1)
      );
    }
    this.focused = false;
    if (this.isShowHintText) {
      if (!this.firstFocus) {
        this.controlField.addValidators(Validators.required)
        this.controlField.updateValueAndValidity();
      }
      this.showHintText.emit(false);
    }
    if (this.onOutFocus) {
      this.onOutFocus.emit();
    }
    if (this.isFocusOut) {
      this.onBlur.emit();
    }
  }

  onValueChange(event?: Event) {
    this.valueChange.emit(this.controlField.value);
    if (this.isShowHintText) {
      this.showHintText.emit(!this.controlField?.errors);
    }
  }

  onPasteValueField(event?: Event){
    this.valueChange.emit(this.controlField.value);
  }

  onClickIcon() {
    if (this.isShowIconMasked) {
      this.isMasked = !this.isMasked;
      this.icon = this.isMasked ? 'visibility_off' : 'visibility';
      this.setMaskExpression();
    }
  }

  setMaskExpression() {
    const cleanedValue = ('' + this.controlField?.value)
      .trim()
      .replace(/[^0-9]/g, '');
    const maskCharacters = ('' + this.controlField?.value).replace(
      /[0-9]/g,
      ''
    );
    switch (this.inputType) {
      case InputType.TYPE_PHONE:
        this.maskExpression =
          cleanedValue?.length >= 10 || maskCharacters
            ? '(XXX) XXX-XXXXXXXXXXXXXXXXXXXX'
            : 'X{10}';
        break;
      case InputType.TYPE_SSN:
        this.maskExpression =
          cleanedValue?.length >= 9 || maskCharacters ? 'XXX-XX-XXXX' : 'X{9}';
        break;
      case InputType.TYPE_EMAIL:
        this.maskExpression = '0'.repeat(255);
        this.customPatterns =
          this.isShowIconMasked && this.isMasked
            ? { '0': { pattern: new RegExp('(.*?)'), symbol: '*' } }
            : { '0': { pattern: new RegExp('(.*?)') } };
        break;
      case InputType.TYPE_PASS_WORD:
        this.maskExpression = '0'.repeat(255);
        this.customPatterns =
          this.isShowIconMasked && this.isMasked
            ? { '0': { pattern: new RegExp('(.*?)'), symbol: '*' } }
            : { '0': { pattern: new RegExp('(.*?)') } };
        break;
      default:
        break;
    }
  }
  setSelectedValue() {
    if (this.changeControlValue) {
      this.controlField.valueChanges.subscribe((value: any) => {
        this.changeControlValue.emit();
      });
    }
  }
  onKeydownEnter(event?: any) {
    this.onKeydown.emit({
      key: event.keyCode || event.charCode,
      value: this.controlField.value,
    });
  }

  triggerFocusAfterViewInit() {
    setTimeout(() => this.defaultInputField.nativeElement.focus(), 0);
  }
}
