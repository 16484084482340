<div class="retirement-detail" #memberDetail>
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    [settings]="settings"
    [stepperState]="stepperState"
    [completedStep]="completedStep"
  ></ptg-breadcrumb>

  <ptg-overview-header-entity></ptg-overview-header-entity>

  <div class="retirement-detail-container flex flex-col gap-6 px-6 py-8">
    <ptg-sub-header
      [memberId]="memberId"
      [menuItemTitle]="menuItemTitle"
      [menuItemSubTitle]="menuItemSubTitle"
    ></ptg-sub-header>

    <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

    <div
      class="detail__actions flex flex-wrap gap-3"
      *ngIf="currentStatus !== CalculationBenefitHistoryStatus.Approved"
    >
      <ptg-button
        *ngIf="currentStatus === CalculationBenefitHistoryStatus.Initiated && !isQDRO"
        buttonName="Compute"
        classInput="secondary"
        (clickButton)="onClickCompute()"
      >
      </ptg-button>

      <ptg-button
        *ngIf="currentStatus === CalculationBenefitHistoryStatus.Initiated"
        buttonName="Complete"
        classInput="primary"
        (clickButton)="onClickComplete()"
      >
      </ptg-button>

      <ptg-button
        *ngIf="currentStatus === CalculationBenefitHistoryStatus['Pending Approval']"
        buttonName="Re-open"
        classInput="secondary"
        (clickButton)="onClickReopen()"
      >
      </ptg-button>

      <ptg-button
        *ngIf="currentStatus === CalculationBenefitHistoryStatus['Pending Approval']"
        buttonName="Approve"
        classInput="primary"
        (clickButton)="onClickApprove()"
      >
      </ptg-button>
    </div>

    <div class="detail__exceptions">
      <div class="detail__benefit-document--header p-3">
        <span class="text-xl font-bold leading-7">Exceptions</span>
      </div>
      <ptg-grid
        [data]="exceptionList"
        [columns]="columnException"
        [totalRecords]="totalExceptionRecords"
        [isLoading]="isExceptionLoading"
        [pageSize]="pageExceptionSize"
        [pageSizeOptions]="pageExceptionSizeOptions"
        [pageNumber]="pageExceptionIndex"
        notFoundMessage="No Exceptions to Display"
        (sortChange)="onChangeSortException($event)"
        (pageChange)="onChangeExceptionPage($event)"
      >
      </ptg-grid>
    </div>

    <ptg-infor-banner
      *ngIf="isShowWarning"
      [(bannerType)]="bannerTypeCP"
      [messages]="warningList"
      [title]="titleBanner"
      (closeBanner)="closeAlert()"
    ></ptg-infor-banner>

    <div class="detail__information flex flex-wrap gap-4">
      <ng-container *ngIf="!isLoading; else loadingTemplate">
        <div [ngStyle]="{ flexBasis: maxWidth, maxWidth: maxWidth }" *ngFor="let item of benefitDetails">
          <ng-container
            *ngIf="
              (isLODDSurvivor || isLODDDeath) && item.sectionType === CalculationBenefitDetailType.Beneficiary;
              else defaultCard
            "
          >
            <ptg-view-beneficiary-card
              [cardId]="item?.cardId ?? ''"
              [memberId]="memberId"
              [memberEntityId]="getCalculationBenefitDetailsResponse?.memberEntityId ?? ''"
              [title]="item?.title ?? ''"
            ></ptg-view-beneficiary-card>
          </ng-container>

          <ng-template #defaultCard>
            <ng-container
              *ngIf="item.sectionType === CalculationBenefitDetailType.PayeeInformation; else defaultOtherCard"
            >
              <ptg-lodd-payee-information-table
                [title]="item?.title ?? ''"
                [properties]="item?.body?.content ?? []"
                [calculationType]="calculationType"
              ></ptg-lodd-payee-information-table>
            </ng-container>
            <ng-template #defaultOtherCard>
              <ptg-card-description
                [data]="item"
                [isCustomData]="isChicagoParks || isQDRO"
                (fieldClick)="onFieldClick($event)"
              ></ptg-card-description>
            </ng-template>
          </ng-template>
        </div>
      </ng-container>
    </div>

    <ng-template #loadingTemplate>
      <div class="flex justify-center w-full my-24">
        <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
      </div>
    </ng-template>

    <ptg-qdro-information
      *ngIf="isRetirement && isShowQDROBenefitInfoSection"
      [memberId]="memberId"
      [calculationBenefitId]="calculationBenefitId"
      [reloadEvent]="reloadQDROBenefitInfoTrigger"
      (showQDROBenefitInfoSectionEmitter)="getQDROBenefitInfoSectionShowStatus($event)"
    ></ptg-qdro-information>

    <div class="detail__benefit-document" *ngIf="isShowDocumentList || isLODD">
      <div class="detail__benefit-document--header flex justify-between p-3">
        <span class="text-xl font-bold leading-7">{{
          isLODDDeath
            ? 'Line of Duty Death Document'
            : isLODDSurvivor
              ? 'Line of Duty Death Survivor Document'
              : 'Retirement Benefit Documents'
        }}</span>
        <ptg-button
          buttonName="Upload Document"
          classInput="upload-button"
          class="upload-file"
          (clickButton)="onUploadDocuments()"
          *ngIf="currentStatus === CalculationBenefitHistoryStatus.Initiated && !isDocumentLoading"
        >
          <mat-icon aria-hidden="false" aria-label="add-icon">upload_file</mat-icon>
        </ptg-button>
      </div>
      <ptg-grid
        [data]="retirementBenefitDocuments"
        [columns]="columnsBenefitDocuments"
        [totalRecords]="totalBenefitDocuments"
        [isLoading]="isDocumentLoading"
        [pageSize]="pageDocumentSize"
        [pageNumber]="pageDocumentIndex"
        notFoundMessage="{{
          isLODDDeath
            ? 'No Line of Duty Death Document to Display'
            : isLODDSurvivor
              ? 'No Line of Duty Death Survivor Document to Display'
              : 'No Retirement Benefit Document to Display'
        }}"
        (sortChange)="onChangeSortDocuments($event)"
        (pageChange)="onChangeDocumentsPage($event)"
      >
        <ng-template cellContent columnName="fileName" let-row>
          <div class="flex file-document-container">
            <span (click)="onDownloadFile(row)" class="file-document-name" target="_blank"
              ><span class="truncate">{{ row?.fileName }}</span></span
            >
          </div>
        </ng-template>
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="onEditDocument(row)"
            *ngIf="currentStatus === CalculationBenefitHistoryStatus.Initiated"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button
            stopPropagation
            buttonName="Remove"
            classInput="remove-button"
            (clickButton)="onRemoveDocument(row)"
            *ngIf="currentStatus === CalculationBenefitHistoryStatus.Initiated"
          >
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>

    <div class="detail__audit-trails">
      <div class="detail__benefit-document--header p-3">
        <span class="text-xl font-bold leading-7">Audit Trails</span>
      </div>
      <ptg-grid
        [data]="calculationAuditTrails"
        [columns]="columnCalculationAuditTrail"
        [totalRecords]="totalCalculationAuditTrailRecords"
        [isLoading]="isCalculationAuditTrailLoading"
        [pageSize]="pageCalculationAuditTrailSize"
        [pageNumber]="pageCalculationAuditTrailIndex"
        notFoundMessage="No Audit Trails to Display"
        (sortChange)="onChangeSortAuditTrail($event)"
        (pageChange)="onChangeAuditTrailPage($event)"
      >
        <ng-template cellContent columnName="createdDate" let-row>
          {{ row.createdDate | date: 'MM/dd/yyyy hh:mm a' }}
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
