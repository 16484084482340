import { createAction, props } from '@ngrx/store';
import {
  BuybackMakeupRemittanceCalculatonResultResponse,
  GetRemittanceStatusRequest,
  RecordPaymentBody,
  RecordPaymentForMunicipalityBody,
  RefundCreditBody,
  RemittanceBodyRequest,
  RemittanceFeesPayment,
  RemittanceParticipantBodyRequest,
  RemittanceSubmission,
  RemittanceSubmissionParticipants,
  RemittanceUpdateResponse,
  SearchRemittanceParticipantBody,
  ValidateRemittanceResponse,
  ValidateRemittanceSubmissionResponse,
} from '../models/remittance-submission.model';

export const GetRemittanceSubmissionRequest = '[GetRemittanceSubmission/API] Send Request';
export const GetRemittanceSubmissionSuccess = '[GetRemittanceSubmission/API] Success';
export const GetRemittanceSubmissionFailure = '[GetRemittanceSubmission/API] Failure';
export const ClearGetRemittanceSubmissionState = '[ClearGetRemittanceSubmissionState] Clear';

export const EditRemittanceRequest = '[EditRemittance/API] Send Request';
export const EditRemittanceFailure = '[EditRemittance/API] Failure';
export const EditRemittanceSuccess = '[EditRemittance/API] Success';
export const ClearRemittanceSubmissionState = '[RemittanceSubmissionState] Clear';

export const ClearRemittanceParticipantState = '[RemittanceParticipantState] Clear';
export const EditRemittanceParticipantRequest = '[EditRemittanceParticipant/API] Send Request';
export const EditRemittanceParticipantFailure = '[EditRemittanceParticipant/API] Failure';
export const EditRemittanceParticipantSuccess = '[EditRemittanceParticipant/API] Success';

export const ValidateRemittanceParticipantRequest = '[ValidateRemittanceParticipant/API] Valid Request';
export const ValidateRemittanceParticipantFailure = '[ValidateRemittanceParticipant/API] Failure';
export const ValidateRemittanceParticipantSuccess = '[ValidateRemittanceParticipant/API] Success';
export const ClearValidateRemittanceParticipant = '[ClearValidateRemittanceParticipant] Clear';

export const RemoveRemittanceRequest = '[RemoveRemittance/API] Send Request';
export const RemoveRemittanceSuccess = '[RemoveRemittance/API] Success';
export const RemoveRemittanceFailure = '[RemoveRemittance/API] Failure';

export const ClearRemittanceFeesPaymentState = '[ClearRemittanceFeesPaymentState] Clear';
export const GetRemittanceFeesPaymentRequest = '[GetRemittanceFeesPayment/API] Send Request';
export const GetRemittanceFeesPaymentSuccess = '[GetRemittanceFeesPayment/API] Success';
export const GetRemittanceFeesPaymentFailure = '[GetRemittanceFeesPayment/API] Failure';

export const RecordPaymentRequest = '[RecordPayment/API] Send Request';
export const RecordPaymentSuccess = '[RecordPayment/API] Success';
export const RecordPaymentFailure = '[RecordPayment/API] Failure';
export const ClearRecordPaymentState = '[ClearRecordPaymentState] Clear';

export const SearchRemittanceParticipantRequest = '[SearchRemittanceParticipant/API] Send Request';
export const SearchRemittanceParticipantSuccess = '[SearchRemittanceParticipant/API] Success';
export const SearchRemittanceParticipantFailure = '[SearchRemittanceParticipant/API] Failure';
export const ClearSearchRemittanceParticipantState = '[ClearSearchRemittanceParticipantState] Clear';

export const GetRemittanceParticipantRequest = '[GetRemittanceParticipant/API] Send Request';
export const GetRemittanceParticipantSuccess = '[GetRemittanceParticipant/API] Success';
export const GetRemittanceParticipantFailure = '[GetRemittanceParticipant/API] Failure';

export const RecordPaymentForMunicipalityRequest = '[RecordPaymentForMunicipality/API] Send Request';
export const RecordPaymentForMunicipalitySuccess = '[RecordPaymentForMunicipality/API] Success';
export const RecordPaymentForMunicipalityFailure = '[RecordPaymentForMunicipality/API] Failure';
export const ClearRecordPaymentForMunicipalityState = '[ClearRecordPaymentForMunicipalityState] Clear';

export const RefundCreditRequest = '[RefundCredit/API] Send Request';
export const RefundCreditSuccess = '[RefundCredit/API] Success';
export const RefundCreditFailure = '[RefundCredit/API] Failure';
export const ClearRefundCreditState = '[ClearRefundCreditState] Clear';

export const ValidateRemittanceSubmissionRequestAction = '[ValidateRemittanceSubmission/API] Send Request';
export const ValidateRemittanceSubmissionSuccessAction = '[ValidateRemittanceSubmission/API] Success';
export const ValidateRemittanceSubmissionFailureAction = '[ValidateRemittanceSubmission/API] Failure';
export const ClearValidateRemittanceSubmissionStateAction = '[ValidateRemittanceSubmission] Clear';

export const clearRemittanceSubmissionState = createAction(ClearRemittanceSubmissionState);
export const clearRemittanceParticipantState = createAction(ClearRemittanceParticipantState);
export const clearRecordPaymentState = createAction(ClearRecordPaymentState);

export const getRemittanceSubmissionRequest = createAction(
  GetRemittanceSubmissionRequest,
  props<{ employerId: string; remittanceId: string; query?: any }>(),
);
export const getRemittanceSubmissionSuccess = createAction(
  GetRemittanceSubmissionSuccess,
  props<{ remittanceSubmission: RemittanceSubmission; remittanceId: string }>(),
);
export const getRemittanceSubmissionFailure = createAction(GetRemittanceSubmissionFailure, props<{ error?: any }>());
export const clearGetRemittanceSubmissionState = createAction(ClearGetRemittanceSubmissionState);
export const editRemittance = createAction(
  EditRemittanceRequest,
  props<{
    municipalityId: string;
    remittanceId: string;
    remittanceRequest: RemittanceBodyRequest;
    editType?: number;
  }>(),
);
export const editRemittanceSuccess = createAction(
  EditRemittanceSuccess,
  props<{ editType: any; remittanceUpdateResponse: RemittanceUpdateResponse }>(),
);
export const editRemittanceFailure = createAction(EditRemittanceFailure, props<{ editType: any; error?: any }>());

export const editRemittanceParticipant = createAction(
  EditRemittanceParticipantRequest,
  props<{
    remittanceId: string;
    remittanceParticipantId: string;
    remittanceParticipantRequest: RemittanceParticipantBodyRequest;
  }>(),
);
export const editRemittanceParticipantSuccess = createAction(
  EditRemittanceParticipantSuccess,
  props<{ remittanceParticipantId: string }>(),
);
export const editRemittanceParticipantFailure = createAction(
  EditRemittanceParticipantFailure,
  props<{ remittanceParticipantId: string }>(),
);
export const validateRemittanceParticipant = createAction(
  ValidateRemittanceParticipantRequest,
  props<{ remittanceId: string }>(),
);
export const validateRemittanceParticipantSuccess = createAction(
  ValidateRemittanceParticipantSuccess,
  props<{ validateRemittanceResponse: ValidateRemittanceResponse }>(),
);
export const validateRemittanceParticipantFailure = createAction(
  ValidateRemittanceParticipantFailure,
  props<{ errorMsg: string }>(),
);

export const clearValidateRemittanceParticipant = createAction(ClearValidateRemittanceParticipant);

export const removeRemittance = createAction(
  RemoveRemittanceRequest,
  props<{ municipalityId: string; remittanceId: string }>(),
);
export const removeRemittanceSuccess = createAction(RemoveRemittanceSuccess);
export const removeRemittanceFailure = createAction(RemoveRemittanceFailure, props<{ errorMsg: string }>());

export const clearRemittanceFeesPaymentState = createAction(ClearRemittanceFeesPaymentState);
export const getRemittanceFeesPaymentRequest = createAction(
  GetRemittanceFeesPaymentRequest,
  props<{ municipalityId: string; remittanceId: string; receivedDate: string }>(),
);
export const getRemittanceFeesPaymentSuccess = createAction(
  GetRemittanceFeesPaymentSuccess,
  props<{ remittanceFeesPayment: RemittanceFeesPayment }>(),
);
export const getRemittanceFeesPaymentFailure = createAction(GetRemittanceFeesPaymentFailure);
export const recordPaymentRequest = createAction(
  RecordPaymentRequest,
  props<{ municipalityId: string; remittanceId: string; recordPaymentBody: RecordPaymentBody, currentStep: number }>(),
);
export const recordPaymentSuccess = createAction(RecordPaymentSuccess);
export const recordPaymentFailure = createAction(RecordPaymentFailure, props<{ errorMsg: string }>());

export const searchRemittanceParticipantRequest = createAction(
  SearchRemittanceParticipantRequest,
  props<{ searchRemittanceParticipantBody: SearchRemittanceParticipantBody }>(),
);
export const searchRemittanceParticipantSuccess = createAction(
  SearchRemittanceParticipantSuccess,
  props<{ searchRemittanceParticipantResult?: any }>(),
);
export const searchRemittanceParticipantFailure = createAction(SearchRemittanceParticipantFailure);
export const clearSearchRemittanceParticipantState = createAction(ClearSearchRemittanceParticipantState);

export const getRemittanceParticipantRequest = createAction(
  GetRemittanceParticipantRequest,
  props<{ municipalityId: string; remittanceId: string; participantId: string }>(),
);
export const getRemittanceParticipantSuccess = createAction(
  GetRemittanceParticipantSuccess,
  props<{ remittanceSubmissionParticipant: RemittanceSubmissionParticipants }>(),
);
export const getRemittanceParticipantFailure = createAction(GetRemittanceParticipantFailure, props<{ error: any }>());

export const recordPaymentForMunicipalityRequest = createAction(
  RecordPaymentForMunicipalityRequest,
  props<{ municipalityId: string; recordPaymentForMunicipalityBody: RecordPaymentForMunicipalityBody }>(),
);
export const recordPaymentForMunicipalitySuccess = createAction(RecordPaymentForMunicipalitySuccess);
export const recordPaymentForMunicipalityFailure = createAction(
  RecordPaymentForMunicipalityFailure,
  props<{ errorMsg: string }>(),
);
export const clearRecordPaymentForMunicipalityState = createAction(ClearRecordPaymentForMunicipalityState);
export const refundCreditRequest = createAction(
  RefundCreditRequest,
  props<{ municipalityId: string; refundCreditBody: RefundCreditBody }>(),
);
export const refundCreditSuccess = createAction(RefundCreditSuccess);
export const refundCreditFailure = createAction(RefundCreditFailure, props<{ errorMsg: string }>());
export const clearRefundCreditState = createAction(ClearRefundCreditState);

export const validateRemittanceSubmission = createAction(
  ValidateRemittanceSubmissionRequestAction,
  props<{ municipalityId: string; remittanceId: string }>(),
);
export const validateRemittanceSubmissionSuccess = createAction(
  ValidateRemittanceSubmissionSuccessAction,
  props<{ response: ValidateRemittanceSubmissionResponse }>(),
);
export const validateRemittanceSubmissionFailure = createAction(
  ValidateRemittanceSubmissionFailureAction,
  props<{ error?: any }>(),
);
export const clearValidateRemittanceSubmissionState = createAction(ClearValidateRemittanceSubmissionStateAction);

// Get Buy Back Make Up Remittance Calculation Result
export const getBuybackMakeupRemittanceCalculationResultAction = createAction(
  '[GetBuybackMakeupRemittanceCalculationResult/API] Send Request',
  props<{
    participantId: string,
    buybackMakeupId: string,
    buybackMakeupYearRecordId: string,
    interestBeginDate: string,
    paymentReceiveDate: string,
    isCalculateByInterest?: boolean,
   }>()
);
export const getBuybackMakeupRemittanceCalculationResultSuccessAction = createAction(
  '[GetBuybackMakeupRemittanceCalculationResult/API] Success',
  props<{ response: BuybackMakeupRemittanceCalculatonResultResponse }>()
);
export const getBuybackMakeupRemittanceCalculationResultFailureAction = createAction(
  '[GetBuybackMakeupRemittanceCalculationResult/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetBuybackMakeupRemittanceCalculationResultStateAction = createAction(
  '[GetBuybackMakeupRemittanceCalculationResult] Clear'
);
// Get Annual Supplemental Remittance Calculation Result
export const getAnnualSupplementalRemittanceCalculationResultAction = createAction(
  '[GetAnnualSupplementalRemittanceCalculationResult/API] Send Request',
  props<{
    municipalityId: string,
    remittanceId: string,
    interestBeginDate: string,
    paymentReceivedDate: string,
   }>()
);
export const getAnnualSupplementalRemittanceCalculationResultSuccessAction = createAction(
  '[GetAnnualSupplementalRemittanceCalculationResult/API] Success',
  props<{ response: RemittanceFeesPayment }>()
);
export const getAnnualSupplementalRemittanceCalculationResultFailureAction = createAction(
  '[GetAnnualSupplementalRemittanceCalculationResult/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetAnnualSupplementalRemittanceCalculationResultStateAction = createAction(
  '[GetAnnualSupplementalRemittanceCalculationResult] Clear'
);

export const getRemittanceStatusAction = createAction(
  '[GetRemittanceStatus/API] Send Request',
  props<{ request: GetRemittanceStatusRequest }>()
);

export const getRemittanceStatusSuccessAction = createAction(
  '[GetRemittanceStatus/API] Success',
  props<{ response: RemittanceSubmission }>()
);

export const getRemittanceStatusFailureAction = createAction(
  '[GetRemittanceStatus/API] Failure',
  props<{ error?: any }>()
);
export const clearGetRemittanceStatusStateAction = createAction(
  '[GetRemittanceStatus] Clear'
);
