import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { RemittanceSubmissionActions } from '@ptg-employer/actions';
import { RemittanceFeesPaymentComponent } from '@ptg-employer/components/remittance-fees-payment/remittance-fees-payment.component';
import { RemittanceStatus, RemittanceType } from '@ptg-employer/constance/remittance-submission.const';
import * as fromEmployerReducer from '@ptg-employer/reducers';
import { selectValidateRemittanceSubmissionState } from '@ptg-employer/reducers';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';
import { BUTTON_LABEL_CLOSE, SORT_TYPE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { capitalizeFirstLetter, getLocalDate, isEmpty } from '@ptg-shared/utils/string.util';
import { Observable } from 'rxjs';
import { filter, first, map, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  BuybackMakeupRemittanceCalculatonResultResponse,
  CashJournalEntry,
  CheckExistCheckNumberQuery,
  GetRemittanceStatusRequest,
  MunicipalityCreditInformationResponse,
  RecordPaymentBody,
  RecordPaymentForMunicipalityBody,
  RefundCreditBody,
  RemittanceBodyRequest,
  RemittanceFeesPayment,
  RemittanceParticipantBodyRequest,
  RemittanceSubmission,
  RemittanceSubmissionParticipants,
  RemittanceUpdateResponse,
  SearchRemittanceParticipantBody,
  ValidateRemittanceResponse,
  ValidateRemittanceSubmissionResponse,
} from '../models/remittance-submission.model';
import { TransactionReceipt } from '@ptg-employer/models/remittance-2024-confirmation.model';
import { LayoutService } from '@ptg-shared/services/layout.service';

@Injectable({
  providedIn: 'root',
})
export class RemittanceSubmissionService {
  searchValue: string = '';
  constructor(
    private httpClient: HttpClient,
    private employerStore: Store<fromEmployerReducer.EmployerState>,
    private dialog: MatDialog,
    private layoutService: LayoutService,
  ) { }

  getRemittanceSubmission(employerId: string, remittanceId: string, query?: any): Observable<RemittanceSubmission> {
    let params = new HttpParams();
    if (!isEmpty(query?.isErrorAndWarning)) {
      params = params.append('IsErrorAndWarning', query?.isErrorAndWarning ?? 'false');
    }
    if (query?.pageSize && query?.pageIndex) {
      params = params.append('PageSize', query.pageSize);
      params = params.append('PageIndex', query.pageIndex);
    }

    if (query?.sortField) {
      params = params.append('SortNames', capitalizeFirstLetter(query.sortField));
      params = params.append('SortType', query.sortType ? query.sortType : SORT_TYPE.ASC);
    }
    params = params.append('localDate', getLocalDate());

    return this.httpClient.get<RemittanceSubmission>(
      `${environment.apiUrl}/municipality/${employerId}/remittance/${remittanceId}/`,
      { params },
    );
  }

  editRemittance(
    municipalityId: string,
    remittanceId: string,
    remittanceRequest: RemittanceBodyRequest,
  ): Observable<RemittanceUpdateResponse> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.put<RemittanceUpdateResponse>(
      `${environment.apiUrl}/municipality/${municipalityId}/remittances/${remittanceId}`,
      {...remittanceRequest,  localDate: getLocalDate()},
      { context },
    );
  }

  editRemittanceParticipant(
    remittanceId: string,
    remittanceParticipantId: string,
    remittanceParticipantRequest: RemittanceParticipantBodyRequest,
  ) {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.put(
      `${environment.apiUrl}/municipality/${remittanceId}/remittance-participant/${remittanceParticipantId}`,
      {...remittanceParticipantRequest, localDate: getLocalDate()},
      { context },
    );
  }

  validateRemittanceParticipant(remittanceId: string) {
    return this.httpClient.get<ValidateRemittanceResponse>(
      `${environment.apiUrl}/municipality/remittances/${remittanceId}/validate`,
    );
  }

  removeRemittance(municipalityId: string, remittanceId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/municipality/municipalities/${municipalityId}/remittances/${remittanceId}`,
    );
  }

  getRemittanceFeesPayment(
    municipalityId: string,
    remittanceId: string,
    receivedDate: string,
  ): Observable<RemittanceFeesPayment> {
    let params = new HttpParams();
    params = params.append('localDate', getLocalDate());
    return this.httpClient.get<RemittanceFeesPayment>(
      `${environment.apiUrl}/municipality/municipalities/${municipalityId}/remittances/${remittanceId}/fees/${receivedDate}`,
      { params }
    );
  }

  checkExistCheckNumber = (checkNumberQuery: CheckExistCheckNumberQuery) => {
    return this.httpClient.get(
      `${environment.apiUrl}/municipality/${checkNumberQuery.municipalityId}/remittances/check-number/${checkNumberQuery.checkNumber}/existing`,
    );
  };

  recordPayment = (
    municipalityId: string,
    remittanceId: string,
    recordPaymentBody: RecordPaymentBody,
    currentStep: number,
  ) => {
    if (currentStep === RemittanceStatus.ReadyToPost) {
      return this.httpClient.post(
        `${environment.apiUrl}/municipality/${municipalityId}/remittances/${remittanceId}/post`,
        {...recordPaymentBody,  localDate: getLocalDate()},
      );
    }
    return this.httpClient.post(
      `${environment.apiUrl}/municipality/${municipalityId}/remittances/${remittanceId}/record-payment`,
      {...recordPaymentBody,  localDate: getLocalDate()},
    );
  };

  searchRemittanceParticipant(searchRemittanceParticipantBody: SearchRemittanceParticipantBody) {
    this.searchValue = searchRemittanceParticipantBody?.searchValue;
    return this.httpClient.post(`${environment.apiUrl}/entities/members/search`, searchRemittanceParticipantBody);
  }

  getRemittanceParticipant(
    municipalityId: string,
    remittanceId: string,
    participantId: string,
  ): Observable<RemittanceSubmissionParticipants> {
    let params = new HttpParams();
    params = params.append('localDate', getLocalDate());
    return this.httpClient.get<RemittanceSubmissionParticipants>(
      `${environment.apiUrl}/municipality/municipalities/${municipalityId}/remittances/${remittanceId}/participants/${participantId}`,
      { params }
    );
  }

  getMunicipalityCreditInformation(municipalityId: string): Observable<MunicipalityCreditInformationResponse> {
    let params = new HttpParams();
    params = params.append('localDate', getLocalDate());
    return this.httpClient.get<MunicipalityCreditInformationResponse>(
      `${environment.apiUrl}/municipality/${municipalityId}/credit-information`,
      { params }
    );
  }

  getCashJournalEntry(): Observable<CashJournalEntry> {
    return this.httpClient.get<CashJournalEntry>(
      `${environment.apiUrl}/municipality/cash-journal-entry`,
    );
  }

  refundCredit = (municipalityId: string, refundCreditBody: RefundCreditBody) => {
    return this.httpClient.post(`${environment.apiUrl}/municipality/${municipalityId}/refund-credit`, refundCreditBody);
  };

  recordPaymentForMunicipality = (
    municipalityId: string,
    recordPaymentForMunicipalityBody: RecordPaymentForMunicipalityBody,
  ) => {
    return this.httpClient.post(
      `${environment.apiUrl}/municipality/${municipalityId}/record-payment`,
      recordPaymentForMunicipalityBody,
    );
  };

  validateRemittanceSubmission(
    municipalityId: string,
    remittanceId: string,
  ): Observable<ValidateRemittanceSubmissionResponse> {
    let params = new HttpParams();
    params = params.append('localDate', getLocalDate());
    return this.httpClient.get<ValidateRemittanceSubmissionResponse>(
      `${environment.apiUrl}/municipality/municipalities/${municipalityId}/remittance/${remittanceId}/validate`,
      { params }
    );
  }

  validateRemittance(
    callback?: () => void,
    validateData?: { municipalityId: string; remittanceId: string },
    showErrorFn?: () => void,
    getData?: () => void,
    changeErrorToggle?: () => void,
    recordPaymentDialog?: MatDialogRef<RemittanceFeesPaymentComponent>,
    reloadRecordPaymentDialog?: () => void,
  ) {
    this.employerStore
      .select(selectValidateRemittanceSubmissionState)
      .pipe(
        tap((validateRemittanceSubmissionState) => {
          if (this.layoutService) {
            this.layoutService.showLoading = !!validateRemittanceSubmissionState?.isLoading
          }
        }),
        map((validateRemittanceSubmissionState) => validateRemittanceSubmissionState?.payload),
        filter((validateRemittanceSubmissionState) => !!validateRemittanceSubmissionState),
        first(),
        takeUntil((this as any).unsubscribe$),
      )
      .subscribe((validateRemittanceSubmissionState) => {
        this.employerStore.dispatch(RemittanceSubmissionActions.clearValidateRemittanceSubmissionState());
        if (validateRemittanceSubmissionState?.isCannotRetrieveMunicipalityType && showErrorFn) {
          showErrorFn();
          return;
        }
        if (validateRemittanceSubmissionState?.isError) {
          const dialogRef = showErrorDialog.call(this, this.dialog);
          dialogRef.afterClosed().subscribe((result) => {
            if (recordPaymentDialog) {
              recordPaymentDialog.close();
            }
            if (getData) {
              getData();
            }
            if (changeErrorToggle) {
              changeErrorToggle();
            }
          });
          return;
        }
        if (validateRemittanceSubmissionState?.isWarning) {
          this.layoutService.showLoading = false;
          showWarningDialog.call(this, this.dialog, callback, getData, changeErrorToggle, recordPaymentDialog);
          return;
        }

        if (
          (this as any)?.remittanceFeesPayment?.amount !== 0 &&
          (this as any)?.data?.currentStep === RemittanceStatus.ReadyToPost &&
          recordPaymentDialog &&
          validateRemittanceSubmissionState?.isEcheckVoided
        ) {
          const dialogRef = showVoidedWarningDialog.call(this, this.dialog);
          dialogRef.afterClosed().subscribe((result) => {
            if (reloadRecordPaymentDialog) {
              reloadRecordPaymentDialog();
              recordPaymentDialog.close();
            }
          });
          return;
        }

        if (validateRemittanceSubmissionState && callback) {
          callback();
        }
      });
    this.employerStore.dispatch(
      RemittanceSubmissionActions.validateRemittanceSubmission({
        municipalityId: validateData?.municipalityId ?? (this as any)?.municipalityId,
        remittanceId: validateData?.remittanceId ?? (this as any)?.remittanceId,
      }),
    );
  }

  getBuybackMakeupRemittanceCalculationResult(
    participantId: string,
    buybackMakeupId: string,
    buybackMakeupYearRecordId: string,
    interestBeginDate: string,
    paymentReceiveDate: string,
    isCalculateByInterest?: boolean,
  ) {
    let params = new HttpParams();
    params = params.append('interestBeginDate', interestBeginDate);
    params = params.append('interestThruDate', paymentReceiveDate);
    if (isCalculateByInterest) {
      params = params.append('isCalculateByInterest', isCalculateByInterest);
    }

    return this.httpClient.get<BuybackMakeupRemittanceCalculatonResultResponse>(
      `${environment.apiUrl}/Members/${participantId}/buyback-makeup/${buybackMakeupId}/${buybackMakeupYearRecordId}/getBuybackMakeup-yearrecord`,
      { params },
    );
  }

  getAnnualSupplementalRemittanceCalculationResult(
    municipalityId: string,
    remittanceId: string,
    interestBeginDate: string,
    paymentReceivedDate: string,
  ) {
    let params = new HttpParams();
    params = params.append('interestBeginDate', interestBeginDate);

    return this.httpClient.get<RemittanceFeesPayment>(
      `${environment.apiUrl}/municipality/municipalities/${municipalityId}/remittances/${remittanceId}/fees/${paymentReceivedDate}`,
      { params },
    );
  }

  getRemittanceStatus(request: GetRemittanceStatusRequest): Observable<RemittanceSubmission> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.get<RemittanceSubmission>(
      `${environment.apiUrl}/municipality/${request.municipalityId}/remittance/${request.remittanceId}/status`,
      { context },
    );
  }

}

export function showErrorDialog(dialog: MatDialog) {
  const dialogRef = dialog.open(ConfirmPopupComponent, {
    panelClass: 'confirm-popup',
    data: {
      text: 'Some members are marked with error. Please edit information or restart the remittance process to remove member from the remittance.',
      type: ConfirmType.Warning,
      title: 'Errors to Resolve',
      cancelButtonTitle: 'Close',
      hideConfirmButton: true,
      iconConfig: {
        icon: 'error',
        style: { color: '#E02E2E', fontSize: '40px' }
      },
    }
  });
  return dialogRef;
}

export function showVoidedWarningDialog(dialog: MatDialog) {
  const dialogRef = dialog.open(ConfirmPopupComponent, {
    panelClass: 'confirm-popup',
    data: {
      text: 'Transaction of current Remittance has been voided. Please try to post the remittance again.',
      type: ConfirmType.Warning,
      title: 'Warning',
      cancelButtonTitle: 'Close',
      hideConfirmButton: true,
    }
  });
  return dialogRef;
}

export function showWarningDialog(
  dialog: MatDialog,
  callback: (() => void) | undefined,
  callbackForNoButton?: (() => void) | undefined,
  changeErrorToggle?: (() => void) | undefined,
  recordPaymentDialog?: MatDialogRef<RemittanceFeesPaymentComponent>,
) {
  const newDialog = dialog.open(ConfirmPopupComponent, {
    panelClass: 'confirm-popup',
    data: {
      text: 'Some members are marked with warning. Are you sure you want to continue?',
      type: ConfirmType.ConfirmSave,
      title: 'Warning',
      saveButtonTitle: 'Yes',
      cancelButtonTitle: 'No',
      hideSaveAsButton: true,
      hideConfirmButton: true,
      iconConfig: {
        icon: 'warning',
        style: { color: '#FD8A03', fontSize: '40px' },
      },
    },
  });
  newDialog.afterClosed().subscribe((isConfirm) => {
    if (isConfirm && callback) {
      callback();
    } else if (!isConfirm && callbackForNoButton && changeErrorToggle) {
      if (recordPaymentDialog) {
        recordPaymentDialog.close();
      }
      callbackForNoButton();
      changeErrorToggle();
    }
  });
}

export function showStatusWarningDialog(dialog: MatDialog, text: string, callback: () => void) {
  const confirmResult = dialog.open(ConfirmPopupComponent, {
    panelClass: 'confirm-popup',
    data: {
      title: BannerType.Warning,
      text,
      type: ConfirmType.Warning,
      cancelButtonTitle: BUTTON_LABEL_CLOSE,
      hideConfirmButton: true,
    },
  });
  confirmResult
    .afterClosed()
    .pipe(take(1))
    .subscribe(() => {
      callback();
    });
}
